import axios from 'axios'
import {
    AGGREGATED_HOUSEHOLD_FAIL,
    AGGREGATED_HOUSEHOLD_REQUEST,
    AGGREGATED_HOUSEHOLD_SUCCESS,
   

    MUNICIPAL_HOUSEHOLD_FAIL,
    MUNICIPAL_HOUSEHOLD_REQUEST,
    MUNICIPAL_HOUSEHOLD_SUCCESS,
    

    MUNICIPAL_FAMILY_FAIL,
    MUNICIPAL_FAMILY_REQUEST,
    MUNICIPAL_FAMILY_SUCCESS,
   

    BARANGAY_INFO_FAIL,
    BARANGAY_INFO_REQUEST,
    BARANGAY_INFO_SUCCESS,
   

    BARANGAY_INFO_ALL_FAIL,
    BARANGAY_INFO_ALL_REQUEST,
    BARANGAY_INFO_ALL_SUCCESS,

    TOTAL_REPORT_FAIL,
    TOTAL_REPORT_REQUEST,
    TOTAL_REPORT_SUCCESS,
   

    FLOOD_REPORT_FAIL,
    FLOOD_REPORT_REQUEST,
    FLOOD_REPORT_SUCCESS,

    FLOOD_REPORT_BARANGAY_FAIL,
    FLOOD_REPORT_BARANGAY_REQUEST,
    FLOOD_REPORT_BARANGAY_SUCCESS,

    FLOOD_REPORT_PER_MUNICIPALITY_FAIL,
    FLOOD_REPORT_PER_MUNICIPALITY_REQUEST,
    FLOOD_REPORT_PER_MUNICIPALITY_SUCCESS,


    RIL_REPORT_FAIL,
    RIL_REPORT_REQUEST,
    RIL_REPORT_SUCCESS,

    RIL_REPORT_PER_MUNICIPALITY_FAIL,
    RIL_REPORT_PER_MUNICIPALITY_REQUEST,
    RIL_REPORT_PER_MUNICIPALITY_SUCCESS,

   
    STORM_SURGE_REPORT_FAIL,
    STORM_SURGE_REPORT_REQUEST,
    STORM_SURGE_REPORT_SUCCESS,

    STORM_SURGE_REPORT_PER_MUNICIPALITY_REQUEST,
    STORM_SURGE_REPORT_PER_MUNICIPALITY_SUCCESS,
    STORM_SURGE_REPORT_PER_MUNICIPALITY_FAIL,



} from '../constant/reportsConstatant'

export const aggregatedHousehold = (keyword = '') => async (dispatch) => {
    try{
        dispatch({type:AGGREGATED_HOUSEHOLD_REQUEST})

        const {data} = await axios.get(`/api/aggregate${keyword}`)

        dispatch({
            type:AGGREGATED_HOUSEHOLD_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:AGGREGATED_HOUSEHOLD_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const householdPerMun = () => async (dispatch) => {
    try{
        dispatch({type:MUNICIPAL_HOUSEHOLD_REQUEST})

        const {data} = await axios.get('/api/households/permun/')

        dispatch({
            type:MUNICIPAL_HOUSEHOLD_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:MUNICIPAL_HOUSEHOLD_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const familydPerMun = () => async (dispatch) => {
    try{
        dispatch({type:MUNICIPAL_FAMILY_REQUEST})

        const {data} = await axios.get('/api/family/permun/')

        dispatch({
            type:MUNICIPAL_FAMILY_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:MUNICIPAL_FAMILY_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const listBrgyInfoAll = () => async (dispatch) => {
    try{
        dispatch({
            type: BARANGAY_INFO_ALL_REQUEST
        })
      
        const {data} = await axios.get('/api/barangay/info/')
        
        dispatch({
            type: BARANGAY_INFO_ALL_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: BARANGAY_INFO_ALL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const listBrgyInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: BARANGAY_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/barangay/${id}/info/`)
        
        dispatch({
            type: BARANGAY_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: BARANGAY_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getTotalReport = () => async (dispatch) => {
    try{
        dispatch({type:TOTAL_REPORT_REQUEST})

        const {data} = await axios.get('/api/reports/')

        dispatch({
            type:TOTAL_REPORT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:TOTAL_REPORT_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const getFloodReport = (id) => async (dispatch, getState) => {
    try{
        dispatch({type:FLOOD_REPORT_REQUEST})

        const {data} = await axios.get(`/api/reports/flood/${id}/`)

        dispatch({
            type:FLOOD_REPORT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOOD_REPORT_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const getFloodBarangayReport = (id) => async (dispatch, getState) => {
    try{
        dispatch({type:FLOOD_REPORT_BARANGAY_REQUEST})

        const {data} = await axios.get(`/api/reports/flood/${id}/brgy`)

        dispatch({
            type:FLOOD_REPORT_BARANGAY_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOOD_REPORT_BARANGAY_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const getFloodReportPerMunicipality = () => async (dispatch) => {
    try{
        dispatch({type:FLOOD_REPORT_PER_MUNICIPALITY_REQUEST})

        const {data} = await axios.get(`/api/reports/flood/`)

        dispatch({
            type:FLOOD_REPORT_PER_MUNICIPALITY_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOOD_REPORT_PER_MUNICIPALITY_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const getRilReport = (id) => async (dispatch, getState) => {
    try{
        dispatch({type:RIL_REPORT_REQUEST})

        const {data} = await axios.get(`/api/reports/ril/${id}/`)

        dispatch({
            type:RIL_REPORT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:RIL_REPORT_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const getRilReportPerMunicipality = () => async (dispatch) => {
    try{
        dispatch({type:RIL_REPORT_PER_MUNICIPALITY_REQUEST})

        const {data} = await axios.get(`/api/reports/ril/`)

        dispatch({
            type:RIL_REPORT_PER_MUNICIPALITY_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:RIL_REPORT_PER_MUNICIPALITY_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const getStormSurgeReport = (id) => async (dispatch, getState) => {
    try{
        dispatch({type:STORM_SURGE_REPORT_REQUEST})

        const {data} = await axios.get(`/api/reports/stormsurge/${id}/`)

        dispatch({
            type:STORM_SURGE_REPORT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:STORM_SURGE_REPORT_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const getStormSurgeReportPerMunicipality = () => async (dispatch) => {
    try{
        dispatch({type:STORM_SURGE_REPORT_PER_MUNICIPALITY_REQUEST})

        const {data} = await axios.get(`/api/reports/stormsurge/`)

        dispatch({
            type:STORM_SURGE_REPORT_PER_MUNICIPALITY_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:STORM_SURGE_REPORT_PER_MUNICIPALITY_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}