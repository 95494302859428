import React , {useState,useEffect,useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col} from 'react-bootstrap'

import { MapContainer, TileLayer, Marker, GeoJSON} from 'react-leaflet'
import MarkerClusterGroup  from 'react-leaflet-cluster'
import L,{Icon,divIcon} from 'leaflet'
import "leaflet/dist/leaflet.css";


import barangaymap from '../shapefile/Barangay.json'
import adn_flood from '../shapefile/FloodADN.json'
import municipal from '../shapefile/Municipal_ADN.json'
import { SENIOR_POINT_RESET,SENIOR_POINT_RESET_HF,SENIOR_POINT_RESET_MF,SENIOR_POINT_RESET_LF } from '../constant/pointConstants'

import { getpointSeniorVHF } from '../actions/pointActions'
import { getpointSeniorHF } from '../actions/pointActions'
import { getpointSeniorMF } from '../actions/pointActions'
import { getpointSeniorLF } from '../actions/pointActions'

function FloodMap() {

  const [hoveredFeature, setHoveredFeature] = useState(null);

  const [pointseniorVHF, setPointSeniorVHF] = useState([])
  const [pointseniorHF, setPointSeniorHF] = useState([])
  const [pointseniorMF, setPointSeniorMF] = useState([])
  const [pointseniorLF, setPointSeniorLF] = useState([])

    // State to manage the checked state of the checkbox
    const [isChecked, setChecked] = useState(false);
    const [isCheckedHF, setCheckedHF] = useState(false);
    const [isCheckedMF, setCheckedMF] = useState(false);
    const [isCheckedLF, setCheckedLF] = useState(false);

  const ptseniorVHF = useSelector(state => state.pointSeniorReducer)
  const {success:successVHF,pointSenior:pointSeniorVHF} = ptseniorVHF

  const ptseniorHF = useSelector(state => state.pointSeniorReducerHF)
  const {success:successHF,pointSenior:pointSeniorHF} = ptseniorHF

  const ptseniorMF = useSelector(state => state.pointSeniorReducerMF)
  const {success:successMF,pointSenior:pointSeniorMF} = ptseniorMF

  const ptseniorLF = useSelector(state => state.pointSeniorReducerLF)
  const {success:successLF,pointSenior:pointSeniorLF} = ptseniorLF

  const dispatch = useDispatch()

  useEffect(() => {
   
      try {
        if (isChecked) {
          setPointSeniorVHF(dispatch(getpointSeniorVHF('VHF')));
        } else {
          setPointSeniorVHF([]);
          dispatch({ type: SENIOR_POINT_RESET });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        if (isCheckedHF) {
          setPointSeniorHF(dispatch(getpointSeniorHF('HF')));
        } else {
          setPointSeniorHF([]);
          dispatch({ type: SENIOR_POINT_RESET_HF });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        if (isCheckedMF) {
          setPointSeniorMF(dispatch(getpointSeniorMF('MF')));
        } else {
          setPointSeniorMF([]);
          dispatch({ type: SENIOR_POINT_RESET_MF });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        if (isCheckedLF) {
          setPointSeniorLF(dispatch(getpointSeniorLF('LF')));
        } else {
          setPointSeniorLF([]);
          dispatch({ type: SENIOR_POINT_RESET_LF });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }


  
  }, [dispatch, isChecked,isCheckedHF,isCheckedMF,isCheckedLF]);



  useEffect(() => {
    if(successVHF){
      setPointSeniorVHF(pointSeniorVHF)   
    }
    if(successHF){
     
      setPointSeniorHF(pointSeniorHF)   
    }
    if(successMF){
      setPointSeniorMF(pointSeniorMF)   
    }
    if(successLF){
      setPointSeniorLF(pointSeniorLF)   
    }
  },[dispatch,successVHF,successHF,successMF,successLF,pointSeniorVHF,pointSeniorHF,pointSeniorMF,pointSeniorLF])   

  // const mapRef = useRef(null);

  // useEffect(() => {
  //   if (mapRef.current && pointseniorVHF.length > 0) {
  //     const bounds = L.latLngBounds(pointseniorVHF && pointseniorVHF.map((senior) => [senior.lat, senior.long]));
  //     mapRef.current.fitBounds(bounds, { padding: [50, 50] });
  //   }
  // }, [pointseniorVHF]);

  // console.log(pointsenior[0])




  // Function to handle checkbox state changes
  const handleCheckboxChange = () => {
    setChecked(!isChecked); // Toggle the checked state

  };

  const handleCheckboxChangeHF = () => {
   
    setCheckedHF(!isCheckedHF); // Toggle the checked state
    
  };

  const handleCheckboxChangeMF = () => {
    setCheckedMF(!isCheckedMF); // Toggle the checked state

  };

  const handleCheckboxChangeLF = () => {
    setCheckedLF(!isCheckedLF); // Toggle the checked state

  };



  const customIcon = new Icon({
    iconUrl: require("../img/icons8-green-circle-48.png"),
    iconSize: [10,10]
  })


    const setColor = ({ properties }) => {
        return { weight: 1 };
      };

      const customMarkerIcon = (name) =>
      divIcon({
        html: name,
        className: "icon"
      });

    const setIcon = ({ properties }, latlng) => {
      return L.marker(latlng, { icon: customMarkerIcon(properties.Name) });
    };

    const onEachFeature = (feature, layer) => {
      // You can customize the tooltip content here
      layer.bindTooltip(feature.properties.MunName);
      layer.on({
        mouseover: () => {
          // Set the feature as hovered
          setHoveredFeature(feature);
          layer.setStyle({
            fillColor: 'blue', // Set the fill color on hover
            fillOpacity: 0.2,
          });
        },
        mouseout: () => {
          // Reset the style on mouseout
          setHoveredFeature(null);
          layer.setStyle({
             fillColor: 'red', // Set the default fill color
             fillOpacity: 0,
          });
        },
      });
    };

    const geoJSONStyle = {
      // fillColor: 'red', // Set the default fill color
      weight: 2,
      opacity: 1,
       color: 'red',
      dashArray: '3',
     fillOpacity: 0,
    };


  return (
    <div>
      <div style={{ position: 'relative' }}>
      {/* ref={mapRef} */}
          <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={9} scrollWheelZoom={true}  >
                    
                    {/* <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                    /> */}

<TileLayer attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />

              
                {/* <LayersControl position="topright" collapsed={false}> */}
                

                {/* <LayersControl.BaseLayer name="OpenStreetMap">
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="ESRI"> */}
                    {/* <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                      attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                      maxZoom='20'
                    /> */}
                  {/* </LayersControl.BaseLayer> */}
    
                       <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35} disableClusteringAtZoom={18}>
                          {Array.isArray(pointseniorVHF) && pointseniorVHF.map((senior, index) => (
                            <Marker key={index} position={[senior.lat, senior.long]} icon={customIcon}></Marker>
                          ))}
                        </MarkerClusterGroup>

                        <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35} disableClusteringAtZoom={18}>
                          {Array.isArray(pointseniorHF) && pointseniorHF.map((senior, index) => (
                            <Marker key={index} position={[senior.lat, senior.long]} icon={customIcon}></Marker>
                          ))}
                        </MarkerClusterGroup>

                        <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35} disableClusteringAtZoom={18}>
                          {Array.isArray(pointseniorMF) && pointseniorMF.map((senior, index) => (
                            <Marker key={index} position={[senior.lat, senior.long]} icon={customIcon}></Marker>
                          ))}
                        </MarkerClusterGroup>

                        <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35} disableClusteringAtZoom={18}>
                          {Array.isArray(pointseniorLF) && pointseniorLF.map((senior, index) => (
                            <Marker key={index} position={[senior.lat, senior.long]} icon={customIcon}></Marker>
                          ))}
                        </MarkerClusterGroup>

                     

                  {adn_flood.features && adn_flood.features.map((feature, index) => (
  
                      <GeoJSON
                        key={index} 
                        data={feature}
                        color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                        weight={1}
                        opacity={.5}
                        fillOpacity={0.8}
                        dashArray={3}
                      >

                        </GeoJSON>
                    ))}
                       <GeoJSON data={municipal} color={'black'} weight={0.8} fillOpacity={0} dashArray={3} key={municipal.features}  onEachFeature={onEachFeature} /> 
                {/* </LayersControl> */}


                <div style={{position: 'absolute', top: 1, right: 1, zIndex: 10000,height:686,width:300 ,background: '#ffffff',border: 'solid', borderWidth: 'thin'}}>
                <div className="text-right" style={{position: 'absolute', top: 1, right: 1,height:60,width:300 ,background: '#ffffff',border: 'solid', borderWidth: 'thin'}}>
                  <Row style={{ marginTop: 4,marginBottom:20}}>
                    <Col md='6'>
                    <img src="/images/adn-logo.png" alt="adn-logo" style={{width: 50, marginRight: 30}} />
                    </Col>
                    <Col md='6'>
                    <img src="/images/pdrr.png" alt="adn-logo" style={{width: 70, marginRight: 50}} />
                    </Col>
                  </Row>
                  
                 
                </div>
                <div style={{position: 'absolute', top: 80, right: 1,height:506,width:300 ,background: '#ffffff',border: 'solid', borderWidth: 'thin'}}>
                      <h2>Legend</h2>
                      <Row style={{ marginTop: -10,marginBottom:20}}>
                          
                        <Col md='2'>
                        <ul className="nav nav-pills flex-column"> 
                        <div style={{ marginLeft: 10 }}>            
                          <li className="nav-item" style={{width: 50, backgroundColor: '#28286e', color: '#ffffff', textAlign: 'center'  }}>
                            VHF
                          </li>
                          <li className="nav-item" style={{width: 50, backgroundColor: '#3c46c8', color: '#ffffff', textAlign: 'center'  }}>
                            HF
                          </li>
                          <li className="nav-item" style={{width: 50, backgroundColor: '#783cc8' , color: '#ffffff', textAlign: 'center' }}>
                            MF
                          </li>
                          <li className="nav-item" style={{width: 50, backgroundColor: '#f5dcf5' , color: '#000', textAlign: 'center' }}>
                            LF
                          </li>
                          </div>   
                        </ul>
                        </Col>
                        <Col >
                        <ul className="nav nav-pills flex-column" style={{  color: '#000000' }}>   
                        <div style={{ marginLeft: 15}}>             
                          <li className="nav-item" >
                            Roads
                          </li>
                          <li className="nav-item" style={{  color: '#000000' }}>
                            Rivers
                          </li>
                          <li className="nav-item" style={{  color: '#000000' }}>
                            Barangay Boudary
                          </li>
                          <li className="nav-item" style={{  color: '#000000'}}>
                            Bridges
                          </li>
                          </div>
                        </ul>
                        </Col>
                      </Row>
                      
                     <div >
                      <h2>Hazards Susceptibility Level</h2>
                      <Row style={{ marginTop: -10,marginBottom:20}}>
                          
                        <Col md='2'>
                        <ul className="nav nav-pills flex-column"> 
                        <div style={{ marginLeft: 10 }}>            
                          <li className="nav-item" style={{width: 50, backgroundColor: '#28286e', color: '#ffffff', textAlign: 'center'  }}>
                            VHF
                          </li>
                          <li className="nav-item" style={{width: 50, backgroundColor: '#3c46c8', color: '#ffffff', textAlign: 'center'  }}>
                            HF
                          </li>
                          <li className="nav-item" style={{width: 50, backgroundColor: '#783cc8' , color: '#ffffff', textAlign: 'center' }}>
                            MF
                          </li>
                          <li className="nav-item" style={{width: 50, backgroundColor: '#f5dcf5' , color: '#000', textAlign: 'center' }}>
                            LF
                          </li>
                          </div>   
                        </ul>
                        </Col>
                        <Col >
                        <ul className="nav nav-pills flex-column" style={{  color: '#000000' }}>   
                        <div style={{ marginLeft: 15 }}>             
                          <li className="nav-item" >
                            Very High Flood Susceptibility Rating
                          </li>
                          <li className="nav-item" style={{  color: '#000000' }}>
                            High Flood Susceptibility Rating
                          </li>
                          <li className="nav-item" style={{  color: '#000000' }}>
                            Moderate Flood Susceptibility Rating
                          </li>
                          <li className="nav-item" style={{  color: '#000000'}}>
                            Low Flood Susceptibility Rating
                          </li>
                          </div>
                        </ul>
                        </Col>
                      </Row>
                      </div>
                </div>  
                <div style={{position: 'absolute', top: 550, right: 1,height:506,width:300 ,background: '#ffffff',border: 'solid', borderWidth: 'thin'}}>

                           <form className="smart-form" >
                                  <section style={{ marginTop: 1,marginBottom:1,width:150,marginLeft:10}}>
                                    <label className="label" style={{ textAlign: 'center'  }}>Senior Citizen</label>
                                    <label className="toggle">
                                      <input type="checkbox" name="checkbox-toggle" checked={isChecked} onChange={handleCheckboxChange} />
                                      <i data-swchon-text="ON" data-swchoff-text="OFF" />Very High</label>
                                    <label className="toggle">
                                      <input type="checkbox" name="checkbox-toggleHF" checked={isCheckedHF} onChange={handleCheckboxChangeHF} />
                                      <i data-swchon-text="ON" data-swchoff-text="OFF" />High</label>
                                    <label className="toggle">
                                      <input type="checkbox" name="checkbox-toggleMF" checked={isCheckedMF} onChange={handleCheckboxChangeMF} />
                                      <i data-swchon-text="ON" data-swchoff-text="OFF" />Moderate</label>
                                    <label className="toggle">
                                      <input type="checkbox" name="checkbox-toggleLF" checked={isCheckedLF} onChange={handleCheckboxChangeLF} />
                                     <i data-swchon-text="ON" data-swchoff-text="OFF" />Low</label>
                                  </section>
                            </form>
                </div>  
                </div>
          


           <div style={{position: 'absolute', top:10, left: 10, zIndex: 10000,height:200,width:140 ,background: '#ffffff',border: 'solid', borderWidth: 'thin'}}>
              <MapContainer
                style={{
                  width: "100%",
                  height: "100%"
                }}
                center={[9.030059999999999, 125.51789478275947]}
                zoom={8}
                zoomControl={false}
                scrollWheelZoom={false}
                dragging={false}
                touchZoom={false}
                doubleClickZoom={false}
                boxZoom={false}
                keyboard={false}
              >
                <TileLayer attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                <GeoJSON data={barangaymap} weight={3} fillOpacity={1} opacity={.4} color={"yellow"} dashArray={1} key={barangaymap.BrgyPSGC} pointToLayer={setIcon}/> 
                
                
                {/* {adn_flood.features && adn_flood.features.map((feature, index) => (
                
                <GeoJSON
                  key={index} 
                  data={feature}
                  color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                  weight={1}
                  opacity={.5}
                  fillOpacity={0.8}
                  dashArray={3}
                >

                  </GeoJSON>
              ))} */}
                </MapContainer>
           </div>

          </MapContainer>
      </div>
    </div>
  )
}

export default FloodMap
