import { 
    SENIOR_POINT_REQUEST,
    SENIOR_POINT_SUCCESS,
    SENIOR_POINT_FAIL,
    SENIOR_POINT_RESET
} from '../constant/pointConstants'

export const pointSeniorReducer = (state = { pointSenior: {} }, action) => {
    switch(action.type){
        case SENIOR_POINT_REQUEST:
            return {...state , loading:true }

        case SENIOR_POINT_SUCCESS:
            return { loading:false, success:true, pointSenior: action.payload }
          
        case SENIOR_POINT_FAIL:
            return { loading:false, error: action.payload }
        
        case SENIOR_POINT_RESET:
            return { pointSenior: {} } 
    
        default:
            return state
    }
}

export const pointSeniorReducerHF = (state = { pointSeniorHF: {} }, action) => {
    switch(action.type){
        case SENIOR_POINT_REQUEST:
            return {...state , loading:true }

        case SENIOR_POINT_SUCCESS:
            return { loading:false, success:true, pointSenior: action.payload }
          
        case SENIOR_POINT_FAIL:
            return { loading:false, error: action.payload }
        
        case SENIOR_POINT_RESET:
            return { pointSenior: {} } 
    
        default:
            return state
    }
}

export const pointSeniorReducerMF = (state = { pointSenior: {} }, action) => {
    switch(action.type){
        case SENIOR_POINT_REQUEST:
            return {...state , loading:true }

        case SENIOR_POINT_SUCCESS:
            return { loading:false, success:true, pointSenior: action.payload }
          
        case SENIOR_POINT_FAIL:
            return { loading:false, error: action.payload }
        
        case SENIOR_POINT_RESET:
            return { pointSenior: {} } 
    
        default:
            return state
    }
}

export const pointSeniorReducerLF = (state = { pointSenior: {} }, action) => {
    switch(action.type){
        case SENIOR_POINT_REQUEST:
            return {...state , loading:true }

        case SENIOR_POINT_SUCCESS:
            return { loading:false, success:true, pointSenior: action.payload }
          
        case SENIOR_POINT_FAIL:
            return { loading:false, error: action.payload }
        
        case SENIOR_POINT_RESET:
            return { pointSenior: {} } 
    
        default:
            return state
    }
}