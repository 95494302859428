export const BARANGAY_POLYGON_REQUEST = 'BARANGAY_POLYGON_REQUEST'
export const BARANGAY_POLYGON_SUCCESS = 'BARANGAY_POLYGON_SUCCESS'
export const BARANGAY_POLYGON_FAIL = 'BARANGAY_POLYGON_FAIL'
export const BARANGAY_POLYGON_RESET = 'BARANGAY_POLYGON_RESET'


export const ADN_FLOOD_REQUEST = 'ADN_FLOOD_REQUEST'
export const ADN_FLOOD_SUCCESS = 'ADN_FLOOD_SUCCESS'
export const ADN_FLOOD_FAIL = 'ADN_FLOOD_FAIL'
export const ADN_FLOOD_RESET = 'ADN_FLOOD_RESET'

export const ADN_RIL_REQUEST = 'ADN_RIL_REQUEST'
export const ADN_RIL_SUCCESS = 'ADN_RIL_SUCCESS'
export const ADN_RIL_FAIL = 'ADN_RIL_FAIL'
export const ADN_RIL_RESET = 'ADN_RIL_RESET'


export const ADN_STORMSURGE_REQUEST = 'ADN_STORMSURGE_REQUEST'
export const ADN_STORMSURGE_SUCCESS = 'ADN_STORMSURGE_SUCCESS'
export const ADN_STORMSURGE_FAIL = 'ADN_STORMSURGE_FAIL'
export const ADN_STORMSURGE_RESET = 'ADN_STORMSURGE_RESET'

export const ADN_MUN_FLOOD_REQUEST = 'ADN_MUN_FLOOD_REQUEST'
export const ADN_MUN_FLOOD_SUCCESS = 'ADN_MUN_FLOOD_SUCCESS'
export const ADN_MUN_FLOOD_FAIL = 'ADN_MUN_FLOOD_FAIL'
export const ADN_MUN_FLOOD_RESET = 'ADN_MUN_FLOOD_RESET'

export const ADN_MUN_RIL_REQUEST = 'ADN_MUN_RIL_REQUEST'
export const ADN_MUN_RIL_SUCCESS = 'ADN_MUN_RIL_SUCCESS'
export const ADN_MUN_RIL_FAIL = 'ADN_MUN_RIL_FAIL'
export const ADN_MUN_RIL_RESET = 'ADN_MUN_RIL_RESET'


export const ADN_MUN_STORMSURGE_REQUEST = 'ADN_MUN_STORMSURGE_REQUEST'
export const ADN_MUN_STORMSURGE_SUCCESS = 'ADN_MUN_STORMSURGE_SUCCESS'
export const ADN_MUN_STORMSURGE_FAIL = 'ADN_MUN_STORMSURGE_FAIL'
export const ADN_MUN_STORMSURGE_RESET = 'ADN_MUN_STORMSURGE_RESET'
