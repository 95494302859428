import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'

import {getRilReport} from '../actions/reportActions'
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function SocEcoRilBrgyPieGraph(props) {

    const dispatch = useDispatch()

    const [rilInfo,setRilInfo] = useState(null)

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const rilreports = useSelector(state => state.rilReportReducer)
    const {rilReport,success:successrilreports} = rilreports

    useEffect(()=> {

        dispatch(getRilReport(props.brgyId))

        

       },[props.brgyId,dispatch])

    useEffect(()=>{

      if(successrilreports){
        setRilInfo(rilReport)
     }
    },[successrilreports,rilReport])

    const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === props.brgyId)
    
    const totalHHRil = Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.household_number || 0), 0) : 0;
    const totalFamRil = Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.families || 0), 0) : 0;
    const totalPopRil = Array.isArray(rilInfo) ? rilInfo.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0;

    const householdRilInfo = {

      animationEnabled: true,
      exportFileName: "Household Exposed to RIL",
      exportEnabled: true,
      title:{
        text: "Household Exposed to RIL"
      },
      data: [],
    }
    const famRilInfo = {

      animationEnabled: true,
      exportFileName: "Families Exposed to RIL",
      exportEnabled: true,
      title:{
        text: "Families Exposed to RIL"
      },
      data: [],
    }

    const popRilInfo = {

      animationEnabled: true,
      exportFileName: "Population Exposed to RIL",
      exportEnabled: true,
      title:{
        text: "Population Exposed to RIL"
      },
      data: [],
    }

    if (barangay_info && rilInfo && rilInfo.length > 0) {
      householdRilInfo.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints: rilInfo.map((feature, index) => ({
          y: Number((feature.household_number / barangay_info.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.ril_id.toString(), // Ensure label is a string
          color: feature.ril_id === "VHL" ? "#963c1e" : feature.ril_id === "HL" ? "#f01e1e" : feature.ril_id === "ML" ? "#009600" : "#ffff00",
          indexLabelFontColor: feature.ril_id === "VHL" ? "#ffff" : feature.ril_id === "HL" ? "#ffff" : feature.ril_id === "ML" ? "#ffff" : "#00000",
          
        })).concat({
          y: Number(((barangay_info.households - totalHHRil) / barangay_info.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }];

      famRilInfo.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints:  (rilInfo || []).map((feature, index) => ({
          y: Number((feature.families/barangay_info.families) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.ril_id.toString(), // Ensure label is a string
          color: feature.ril_id === "VHL" ? "#963c1e" : feature.ril_id === "HL" ? "#f01e1e" : feature.ril_id === "ML" ? "#009600" : "#ffff00",
          indexLabelFontColor: feature.ril_id === "VHL" ? "#ffff" : feature.ril_id === "HL" ? "#ffff" : feature.ril_id === "ML" ? "#ffff" : "#00000",
        })).concat({
          y: Number(((barangay_info.families - totalFamRil) / barangay_info.families)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }]


      popRilInfo.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints:  (rilInfo || []).map((feature, index) => ({
          y: Number((feature.person/barangay_info.person) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.ril_id.toString(), // Ensure label is a string
          color: feature.ril_id === "VHL" ? "#963c1e" : feature.ril_id === "HL" ? "#f01e1e" : feature.ril_id === "ML" ? "#009600" : "#ffff00",
          indexLabelFontColor: feature.ril_id === "VHL" ? "#ffff" : feature.ril_id === "HL" ? "#ffff" : feature.ril_id === "ML" ? "#ffff" : "#00000",             
        })).concat({
          y: Number(((barangay_info.person - totalPopRil) / barangay_info.person)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }]

    }

    return (
        
            <div className='row'>
                  <div className='col-md-4'>
                    <div className="well padding-10"> 
                      <CanvasJSChart options = {householdRilInfo}
                        
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                  <div className="well padding-10"> 
                      <CanvasJSChart options = {famRilInfo}
                       
                      />
                    </div>                 
                  </div>
                  <div className='col-md-4'>
                  <div className="well padding-10"> 
                      <CanvasJSChart options = {popRilInfo}

                      />
                    </div>                
                  </div>

            </div>
        
    )
}
