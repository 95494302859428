import {
    BARANGAY_POLYGON_FAIL,
    BARANGAY_POLYGON_REQUEST,
    BARANGAY_POLYGON_SUCCESS,
    BARANGAY_POLYGON_RESET,

    ADN_FLOOD_FAIL,
    ADN_FLOOD_REQUEST,
    ADN_FLOOD_SUCCESS,
    ADN_FLOOD_RESET,

    ADN_RIL_FAIL,
    ADN_RIL_REQUEST,
    ADN_RIL_SUCCESS,
    ADN_RIL_RESET,

    ADN_STORMSURGE_FAIL,
    ADN_STORMSURGE_REQUEST,
    ADN_STORMSURGE_SUCCESS,
    ADN_STORMSURGE_RESET,

    ADN_MUN_FLOOD_FAIL,
    ADN_MUN_FLOOD_REQUEST,
    ADN_MUN_FLOOD_SUCCESS,
    ADN_MUN_FLOOD_RESET,

    ADN_MUN_RIL_FAIL,
    ADN_MUN_RIL_REQUEST,
    ADN_MUN_RIL_SUCCESS,
    ADN_MUN_RIL_RESET,

    ADN_MUN_STORMSURGE_FAIL,
    ADN_MUN_STORMSURGE_REQUEST,
    ADN_MUN_STORMSURGE_SUCCESS,
    ADN_MUN_STORMSURGE_RESET,

} from '../constant/polygonConstants'

export const barangayPolygonReducers = (state = {barangaypolygons:[]},action) => {
    switch(action.type){
        case BARANGAY_POLYGON_REQUEST:
            return {loading:true, barangaypolygons:[]}
        case BARANGAY_POLYGON_SUCCESS:
            return {loading:false, barangaypolygons:action.payload,success:true}
        case BARANGAY_POLYGON_FAIL:
            return {loading:false, error:action.payload}
        case BARANGAY_POLYGON_RESET:
                return { barangaypolygons: {} } 
        default: 
            return state
    }
}

export const adnFloodReducers = (state = {adnflood:[]},action) => {
    switch(action.type){
        case ADN_FLOOD_REQUEST:
            return {loading:true, adnflood:[]}
        case ADN_FLOOD_SUCCESS:
            return {loading:false, adnflood:action.payload,success:true}
        case ADN_FLOOD_FAIL:
            return {loading:false, error:action.payload}
        case ADN_FLOOD_RESET:
                return { adnflood: [] } 
        default: 
            return state
    }
}

export const adnMunFloodReducers = (state = {adnmunflood:[]},action) => {
    switch(action.type){
        case ADN_MUN_FLOOD_REQUEST:
            return {loading:true, adnmunflood:[]}
        case ADN_MUN_FLOOD_SUCCESS:
            return {loading:false, adnmunflood:action.payload,success:true}
        case ADN_MUN_FLOOD_FAIL:
            return {loading:false, error:action.payload}
        case ADN_MUN_FLOOD_RESET:
                return { adnmunflood: [] } 
        default: 
            return state
    }
}

export const adnRilReducers = (state = {adnril:[]},action) => {
    switch(action.type){
        case ADN_RIL_REQUEST:
            return {loading:true, adnril:[]}
        case ADN_RIL_SUCCESS:
            return {loading:false, adnril:action.payload,success:true}
        case ADN_RIL_FAIL:
            return {loading:false, error:action.payload}
        case ADN_RIL_RESET:
                return { adnril: [] } 
        default: 
            return state
    }
}

export const adnMunRilReducers = (state = {adnmunril:[]},action) => {
    switch(action.type){
        case ADN_MUN_RIL_REQUEST:
            return {loading:true, adnmunril:[]}
        case ADN_MUN_RIL_SUCCESS:
            return {loading:false, adnmunril:action.payload,success:true}
        case ADN_MUN_RIL_FAIL:
            return {loading:false, error:action.payload}
        case ADN_MUN_RIL_RESET:
                return { adnmunril: [] } 
        default: 
            return state
    }
}

export const adnStormSurgeReducers = (state = {adnstormsurge:[]},action) => {
    switch(action.type){
        case ADN_STORMSURGE_REQUEST:
            return {loading:true, adnstormsurge:[]}
        case ADN_STORMSURGE_SUCCESS:
            return {loading:false, adnstormsurge:action.payload,success:true}
        case ADN_STORMSURGE_FAIL:
            return {loading:false, error:action.payload}
        case ADN_STORMSURGE_RESET:
                return { adnstormsurge: [] } 
        default: 
            return state
    }
}

export const adnMunStormSurgeReducers = (state = {adnmunstormsurge:[]},action) => {
    switch(action.type){
        case ADN_MUN_STORMSURGE_REQUEST:
            return {loading:true, adnmunstormsurge:[]}
        case ADN_MUN_STORMSURGE_SUCCESS:
            return {loading:false, adnmunstormsurge:action.payload,success:true}
        case ADN_MUN_STORMSURGE_FAIL:
            return {loading:false, error:action.payload}
        case ADN_MUN_STORMSURGE_RESET:
                return { adnmunstormsurge: [] } 
        default: 
            return state
    }
}