import axios from 'axios'
import {

    PUROK_AGGREGATE_FAIL,
    PUROK_AGGREGATE_REQUEST,
    PUROK_AGGREGATE_SUCCESS,

    FLOOD_WALL_ROOF_FAIL,
    FLOOD_WALL_ROOF_REQUEST,
    FLOOD_WALL_ROOF_SUCCESS,
        } from '../constant/purokConstants'




export const puroklistBarangay = (id) => async (dispatch, getState) => {
    try{
        dispatch({type:PUROK_AGGREGATE_REQUEST})

        const {data} = await axios.get(`/api/purok/${id}/info/`)

        dispatch({
            type:PUROK_AGGREGATE_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:PUROK_AGGREGATE_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const floodWallRoofMaterial = (id) => async (dispatch, getState) => {
    try{
        dispatch({type:FLOOD_WALL_ROOF_REQUEST})

        const {data} = await axios.get(`/api/purok/${id}/material/`)

        dispatch({
            type:FLOOD_WALL_ROOF_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:FLOOD_WALL_ROOF_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}