import React from 'react'
import { Row, Col, Card,CardBody,Container,ListGroup,Table} from 'react-bootstrap'

import { MapContainer, TileLayer, Marker, Popup , GeoJSON, LayersControl, Polygon, LayerGroup, Tooltip} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L,{Icon,divIcon} from 'leaflet'

import ril from '../shapefile/RIL.json'
import fault_line from '../shapefile/fault_line.json'
import rivers from '../shapefile/river.json'

function RilMap() {
    
    const setColor = ({ properties }) => {
        return { weight: 1 };
      };

      const customMarkerIcon = (name) =>
      divIcon({
        html: name,
        className: "icon"
      });

    const setIcon = ({ properties }, latlng) => {
      return L.marker(latlng, { icon: customMarkerIcon(properties.Name) });
    };

  return (
    <div>
      <div style={{ position: 'relative' }}>
          <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={9} scrollWheelZoom={true} >
                    
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                    />

                    
              
                <LayersControl position="topright" collapsed={false}>
                

                <LayersControl.BaseLayer name="OpenStreetMap">
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="ESRI">
                    <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                      attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                      maxZoom='20'
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="stadia">
                    <TileLayer
                      url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                      attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      maxZoom='20'
                    />
                  </LayersControl.BaseLayer>
                  <GeoJSON data={rivers} opacity={3} weight={1} color={'Blue'} />
                  {/* <GeoJSON 
                        data={adn_flood}
                        weight={2}
                        opacity={1}
                        fillOpacity={0.8}
                        dashArray={3} /> */}
                  {ril.features && ril.features.map((feature, index) => (
                    // console.log('fffssad',feature.properties.Susc_Ratin)
                      <GeoJSON
                        key={index} 
                        data={feature}
                        color={feature.properties.LndslideSu === "HL" ? "#f01e1e" : feature.properties.LndslideSu === "LL" ? "#ffff00" : feature.properties.LndslideSu === "ML" ? "#009600" : "#963c1e"} 
                        weight={1}
                        opacity={.5}
                        fillOpacity={0.5}
                        dashArray={3}
                      >

                        </GeoJSON>
                    ))}
                    
                    <GeoJSON data={fault_line} opacity={3} weight={1} color={'white'} dashArray={3}  />
                </LayersControl>
                <div style={{ position: 'absolute', bottom: 400, right: 10, zIndex: 1000,height:100,width:200 }}>
                    
                      <Row>
                        <Col >
                        <ul className="nav nav-pills flex-column">                
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            Very High
                          </li>
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            High
                          </li>
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            Moderate
                          </li>
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            Low
                          </li>
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            Fault Line
                          </li>
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            River
                          </li>
                        </ul>
                        </Col>
                        <Col >
                        <ul className="nav nav-pills flex-column">                
                          <li className="nav-item" style={{ backgroundColor: '#963c1e', color: '#ffffff', textAlign: 'center'  }}>
                            VHL
                          </li>
                          <li className="nav-item" style={{ backgroundColor: '#f01e1e', color: '#ffffff', textAlign: 'center'  }}>
                            HL
                          </li>
                          <li className="nav-item" style={{ backgroundColor: '#009600' , color: '#ffffff', textAlign: 'center' }}>
                            ML
                          </li>
                          <li className="nav-item" style={{ backgroundColor: '#ffff00' , color: '#000', textAlign: 'center' }}>
                            LL
                          </li>
                          <li className="nav-item" style={{ backgroundColor: '#ffff' , color: '#000', textAlign: 'center' }}>
                            FAULT LINE
                          </li>
                          <li className="nav-item" style={{ backgroundColor: 'Blue' , color: '#000', textAlign: 'center' }}>
                            RIVER
                          </li>
                        </ul>
                        </Col>
                      </Row>
                </div>
          </MapContainer>
      </div>
    </div>
  )
}

export default RilMap
