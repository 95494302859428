import React , {useState,useEffect,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap';

import { listBarangay } from '../actions/barangayActions'
import { listMunicipal,aggregateMunicipal } from '../actions/municipalActions'
import { listBrgyInfo,listBrgyInfoAll } from '../actions/reportActions'

function MunicipalDetails({ match }) {

    const municipal_id = match.params.municipal_id;

    const dispatch = useDispatch()

    const [brgyinformation, setBrgyInformation] = useState([])

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const municipalist = useSelector(state => state.municipalListReducers)
    const {municipals} = municipalist

    const municipalagg = useSelector(state => state.municipalAggReducers)
    const {municipalsagg} = municipalagg

    const barangaylist = useSelector(state => state.barangayListReducers)
    const {barangays} = barangaylist

    useEffect(() => {
        dispatch(listMunicipal())
        dispatch(listBarangay())
        dispatch(listBrgyInfoAll())
      },[dispatch])   
      const numberofbrgy = barangays.filter((item) => item.psgcmun === municipal_id).length;

      const selectedMunicipal = municipals.find((item) => item.psgccode === municipal_id);
      const municipalname = selectedMunicipal ? selectedMunicipal.munname : null;

   

  return (
    <div>
       {/* MAIN CONTENT */}
       <div id="content">
        <div className="row">
            <div className="col-sm-12">
            <div className="well well-light">
                <a>
                <h1><span className="menu-item-parent">{municipalname} </span><small>{numberofbrgy} Barangay </small></h1>
                </a>
                <div className="row">
                {Array.isArray(barangays) && barangays
                          .filter((brgy) => brgy.psgcmun === municipal_id)
                          .map((filteredbarangay, index) => (
                 
                    <LinkContainer key={filteredbarangay.id} className='btn' to={`/barangayDetails/${filteredbarangay.psgccode}`}>
                    <div className="col-xs-12 col-sm-4 col-md-2">
                    <div className="panel panel-orange">
                    <div className="panel-heading">
                        <h3 className="panel-title">
                        {filteredbarangay.brgyname}</h3>
                    </div>
                    <div key={index} className="panel-body no-padding text-align-center">
                        {/* <div className="the-price">
                        <h1>
                            $10<span className="subscript">/mo</span></h1>
                        <small>1 month FREE trial</small>
                        </div> */}
                        
                        
                         {Array.isArray(brgyinfoall) && brgyinfoall
                          .filter((agg) => agg.psgccode === filteredbarangay.psgccode)
                          .map((filteredbarangayagg, index) => (
                            <table className="table">
                            <tbody>
                            <tr>
                            <td>
                            Household - {Number(filteredbarangayagg.households).toLocaleString()}
                            </td>
                            </tr>
                            <tr className="active">
                            <td>
                            Families - {Number(filteredbarangayagg.families).toLocaleString()}
                            </td>
                            </tr>
                            <tr>
                            <td>
                            Individual - {Number(filteredbarangayagg.person).toLocaleString()}
                            </td>
                            </tr>
                            <tr className="active">
                            <td>
                            PWD - {Number(filteredbarangayagg.pwd).toLocaleString()}
                            </td>
                            </tr>
                            <tr>
                            <td>
                            Malnourished - {Number(filteredbarangayagg.malnurished).toLocaleString()}
                            </td>
                            </tr>
                            <tr className="active">
                            <td>
                            IP - {Number(filteredbarangayagg.ip).toLocaleString()}
                            </td>
                            </tr>
                            </tbody></table>
                          ))}  
                            
                           
                        
                    </div>
                    <div className="panel-footer no-padding">
                        {/* <a href="javascript:void(0);" className="btn bg-color-orange txt-color-white btn-block" role="button">Sign Up</a> */}
                    </div>
                    </div>
                    </div>
                     </LinkContainer>
                ))}
                
                </div>			
            </div>
            </div>
        </div>
        </div>
      {/* END MAIN CONTENT */}
    </div>
  )
}

export default MunicipalDetails
