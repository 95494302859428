import axios from "axios";
import { 
    USER_LOGIN_SUCCESS,
    USER_LOGIN_REQUEST,
    USER_LOGIN_FAIL,

    USER_LOGOUT,
    
    USER_REGISTER_SUCCESS,
    USER_REGISTER_REQUEST,
    USER_REGISTER_FAIL,

    USER_DETAILS_SUCCESS,
    USER_DETAILS_REQUEST,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,
    
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_FAIL,

    USER_LOCATION_SUCCESS,
    USER_LOCATION_REQUEST,
    USER_LOCATION_FAIL,
    
} from "../constant/userConstants";

import { ADN_MUN_FLOOD_RESET,ADN_MUN_RIL_RESET,ADN_MUN_STORMSURGE_RESET} from '../constant/polygonConstants'

const getCookie = (name) => {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : null;
  };
  

export const login = (username, password) => async (dispatch) =>{
    try{
        dispatch({
            type:USER_LOGIN_REQUEST
        })

     const   config = {
            headers:{
                'Content-type':'application/json'
            }
        }

        const {data} = await axios.post('/api/users/login/',{'username':username,'password':password},config )
        
        dispatch({
            type:USER_LOGIN_SUCCESS,
            payload:data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

        

    }catch(error){
        dispatch({
            type:USER_LOGIN_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}

export const logout = () => (dispatch) => {

    localStorage.removeItem('userInfo')
    dispatch({ type:USER_LOGOUT })
    dispatch({type:USER_DETAILS_RESET})
    dispatch({type:ADN_MUN_FLOOD_RESET})
    dispatch({type:ADN_MUN_RIL_RESET})
    dispatch({type:ADN_MUN_STORMSURGE_RESET})
}


export const register = (email,name,username, password) => async (dispatch) =>{
    try{
        dispatch({
            type:USER_REGISTER_REQUEST
        })

     const   config = {
            headers:{
                'Content-type':'application/json'
                
            }
        }

        const {data} = await axios.post(
            '/api/users/register/',
            {'name':name,'email':email,'username':username,'password':password},
            config
            )
        
        dispatch({
            type:USER_REGISTER_SUCCESS,
            payload:data
        })

        dispatch({
            type:USER_LOGIN_SUCCESS,
            payload:data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

        

    }catch(error){
        dispatch({
            type:USER_REGISTER_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}


export const getUserDetails = (id) => async (dispatch,getState) =>{
    try{
        dispatch({
            type:USER_DETAILS_REQUEST
        })

        const csrftoken = getCookie('csrftoken');
        
        const {
            userLogin:{userInfo}
        } = getState()

     const   config = {
            headers:{
                'Content-type':'application/json',
                'Authorization' : `Bearer ${userInfo.token}`,
                'X-CSRFToken': csrftoken // Add CSRF token to headers
            }
        }
        
        const {data} = await axios.get(`/api/users/${id}/`,config)
        
        dispatch({
            type:USER_DETAILS_SUCCESS,
            payload:data
        })


    }catch(error){
        dispatch({
            type:USER_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}


export const updateUserProfile = (user) => async (dispatch,getState) =>{
    try{
        dispatch({
            type:USER_UPDATE_PROFILE_REQUEST
        })


        const csrftoken = getCookie('csrftoken');

        const {
            userLogin:{userInfo}
        } = getState()

     const   config = {
            headers:{
                'Content-type':'application/json',
                'Authorization' : `Bearer ${userInfo.token}`,
                'X-CSRFToken': csrftoken // Add CSRF token to headers
            }
        }

        const {data} = await axios.put(
            `/api/users/profile/update/`,
            user,
            config
            )
        
        dispatch({
            type:USER_UPDATE_PROFILE_SUCCESS,
            payload:data
        })

        dispatch({
            type:USER_LOGIN_SUCCESS,
            payload:data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))


    }catch(error){
        dispatch({
            type:USER_UPDATE_PROFILE_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}



export const getUserLocation = (id) => async (dispatch,getState) =>{
    try{
        dispatch({
            type:USER_LOCATION_REQUEST
        })

        const csrftoken = getCookie('csrftoken');


        const {
            userLogin:{userInfo}
        } = getState()

     const   config = {
            headers:{
                'Content-type':'application/json',
                'Authorization' : `Bearer ${userInfo.access}`,
                'X-CSRFToken': csrftoken // Add CSRF token to headers
            }
        }

        const {data} = await axios.get(`/api/users/location/${id}`, config)
        
        dispatch({
            type:USER_LOCATION_SUCCESS,
            payload:data
        })


    }catch(error){
        dispatch({
            type:USER_LOCATION_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}