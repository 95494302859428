export const FLOOD_INFO_REQUEST = 'FLOOD_INFO_REQUEST'
export const FLOOD_INFO_SUCCESS = 'FLOOD_INFO_SUCCESS'
export const FLOOD_INFO_FAIL = 'FLOOD_INFO_FAIL'
export const FLOOD_INFO_RESET = 'FLOOD_INFO_RESET'

export const FLOOD_REQUEST = 'FLOOD_REQUEST'
export const FLOOD_SUCCESS = 'FLOOD_SUCCESS'
export const FLOOD_FAIL = 'FLOOD_FAIL'
export const FLOOD_RESET = 'FLOOD_RESET'

export const FLOOD_MUNICIPALITY_INFO_REQUEST = 'FLOOD_MUNICIPALITY_INFO_REQUEST'
export const FLOOD_MUNICIPALITY_INFO_SUCCESS = 'FLOOD_MUNICIPALITY_INFO_SUCCESS'
export const FLOOD_MUNICIPALITY_INFO_FAIL = 'FLOOD_MUNICIPALITY_INFO_FAIL'
export const FLOOD_MUNICIPALITY_INFO_RESET = 'FLOOD_MUNICIPALITY_INFO_RESET'

export const FLOOD_PUROK_INFO_REQUEST = 'FLOOD_PUROK_INFO_REQUEST'
export const FLOOD_PUROK_INFO_SUCCESS = 'FLOOD_PUROK_INFO_SUCCESS'
export const FLOOD_PUROK_INFO_FAIL = 'FLOOD_PUROK_INFO_FAIL'
export const FLOOD_PUROK_INFO_RESET = 'FLOOD_PUROK_INFO_RESET'

