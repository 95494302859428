import React from 'react'
import { Row, Col, Card,CardBody,Container,ListGroup,Table} from 'react-bootstrap'

import { MapContainer, TileLayer, Marker, Popup , GeoJSON, LayersControl, Polygon, LayerGroup, Tooltip} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L,{Icon,divIcon} from 'leaflet'


import surge from '../shapefile/StormSurge.json'


function StormSurgeMap() {
console.log(surge.features)
    const setColor = ({ properties }) => {
        return { weight: 1 };
      };

      const customMarkerIcon = (name) =>
      divIcon({
        html: name,
        className: "icon"
      });

    const setIcon = ({ properties }, latlng) => {
      return L.marker(latlng, { icon: customMarkerIcon(properties.Name) });
    };


  return (
    <div>
       <div style={{ position: 'relative' }}>
          <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={9} scrollWheelZoom={true} >
                    
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                    />

                    
              
                <LayersControl position="topright" collapsed={false}>
                

                <LayersControl.BaseLayer name="OpenStreetMap">
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="ESRI">
                    <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                      attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                      maxZoom='20'
                    />
                  </LayersControl.BaseLayer>
                  {surge.features&& surge.features.map((feature, index) => (
                    // console.log('fffssad',feature.properties.Susc_Ratin)
                      <GeoJSON
                        key={index} 
                        data={feature}
                        color={feature.properties.HAZ === 3 ? "#fe0000" : feature.properties.HAZ === 2 ? "#ffaa01" : "#ffff00"} 
                        weight={1}
                        opacity={.5}
                        fillOpacity={0.5}
                        dashArray={3}
                      >

                        </GeoJSON>
                    ))}
                    
                    
                </LayersControl>
                <div style={{ position: 'absolute', bottom: 400, right: 10, zIndex: 1000,height:100,width:200 }}>
                    
                      <Row>
                        <Col >
                        <ul className="nav nav-pills flex-column">                
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            Above head deep High
                          </li>
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            Chest deep High
                          </li>
                          <li className="nav-item" style={{  color: '#ffffff', textAlign: 'center'  }}>
                            Knee deep High
                          </li>
                        </ul>
                        </Col>
                        <Col >
                        <ul className="nav nav-pills flex-column">                
                          <li className="nav-item" style={{ backgroundColor: '#fe0000', color: '#ffffff', textAlign: 'center'  }}>
                            Above Head
                          </li>
                          <li className="nav-item" style={{ backgroundColor: '#ffaa01' , color: '#ffffff', textAlign: 'center' }}>
                            Chest
                          </li>
                          <li className="nav-item" style={{ backgroundColor: '#ffff00' , color: '#000', textAlign: 'center' }}>
                            Knee
                          </li>
                        </ul>
                        </Col>
                      </Row>
                </div>
          </MapContainer>
      </div>
    </div>
  )
}

export default StormSurgeMap
