import React from 'react'
import { FaPeopleRoof } from "react-icons/fa6";
import { BsTelephonePlus } from "react-icons/bs";
import { LinkContainer } from 'react-router-bootstrap'
import { Container } from 'react-bootstrap';
import { MdFlood } from "react-icons/md"
import { GiSandstorm } from "react-icons/gi";
import { MdLandslide } from "react-icons/md";

function SideNavSmartAdmin() {
  return (
    <div >
      <aside id="left-panel" >
        {/* User info */}
        <div className="login-info">
          <span> {/* User image size is adjusted inside CSS, it should stay as it */} 
            <a href="#" id="show-shortcut" data-action="toggleShortcut">
              <img src="/static/images/pdrr.png" alt="me" className="online" /> 
              <span>
                PDRRMO-ADN
              </span>
              <i className="fa fa-angle-down" />
            </a> 
          </span>
        </div>
        {/* end user info */}
        {/* NAVIGATION : This navigation is also responsive*/}
        <nav>
          {/* 
        NOTE: Notice the gaps after each icon usage <i></i>..
        Please note that these links work a bit different than
        traditional href="" links. See documentation for details.
        */}
          <ul>
            
            <li>
            <LinkContainer to='/'>
              <a>
              <i className="fa fa-lg fa-fw fa-dashboard" ></i> <span className="menu-item-parent">Dashboard</span></a>
            </LinkContainer>
            {/* <LinkContainer to='/municipal/'>
              <a>
              <i className="fa fa-lg fa-fw fa-dashboard" ></i> <span className="menu-item-parent">Municipal</span></a>
            </LinkContainer> */}
            </li>
            {/* <li>
              <a href="#"><i className="fa fa-lg fa-fw fa-globe" /> <span className="menu-item-parent">Maps</span></a>
              
              <ul>
                <li>
                  <a href="#">Goverment Building</a>  
                </li>
                <li>
                  <a href="#">Hazards</a>
                  <ul>
                    <li>
                      <LinkContainer to={'/map/flood'}>
                        <a><MdFlood />Floods</a>
                      </LinkContainer>          
                    </li>
                    <li>
                      <LinkContainer to={'/map/ril'}>
                       <a><MdLandslide />RIL</a>
                      </LinkContainer> 
                    </li>
                    <li>
                      <LinkContainer to={'/map/stormsurge'}>
                        <a><GiSandstorm />Storm Surge</a>
                      </LinkContainer>
                    </li>
                  </ul>
                </li>

              </ul>
            </li> */}
            {/* <li>
              <a href="#"><i className="fa fa-lg fa-fw fa-bar-chart-o" /> <span className="menu-item-parent">Graphs</span></a>
              <ul>
                <li>
                  <LinkContainer to={'/household'}>
                      <a>Household</a>
                  </LinkContainer>
                  
                  
                </li>
                <li>
                  <LinkContainer to={'/family'}>
                       <a>Family</a>
                  </LinkContainer>
                  
                </li>
                <li>
                <LinkContainer to={'/graph/malnourished'}>
                  <a>Malnourished</a>
                  </LinkContainer>
                </li>
                <li>
                  <a href="#">Hazards</a>
                  <ul>
                    <li>
                      <LinkContainer to={'/flood'}>
                        <a><MdFlood/> Floods</a>
                        </LinkContainer>
                      
                    </li>
                    <li>
                    <LinkContainer to={'/graph/ril'}>
                      <a ><MdLandslide />RIL</a>
                      </LinkContainer>
                    </li>
                    <li>
                    <LinkContainer to={'/graph/stormsurge'}>
                      <a ><GiSandstorm />Storm Surge</a>
                      </LinkContainer>
                    </li>
                  </ul>
                </li>

              </ul>
            </li> */}
            {/* <li>
              <a href="#"><i className="fa fa-lg fa-fw fa-table" /> <span className="menu-item-parent">Tables</span></a>
              <ul>
              <li>
                <LinkContainer to={'/aggregate'}>
                <a>Aggregate</a>
                </LinkContainer>
                </li>
                <li>
                  <a>Household</a>
                </li>
                <li>
                  <a>Family</a>
                </li>
                <li>
                  <a>Malnourished</a>
                </li>
                <li>
                  <a href="#">Hazards</a>
                  <ul>
                    <li>
                      <a><MdFlood/> Floods</a>
                    </li>
                    <li>
                      <a><i className="glyphicon glyphicon-plane" />LandSlide</a>
                    </li>
                    <li>
                      <a><i className="fa fa-flag" />Storm Surge</a>
                    </li>
                  </ul>
                </li>

              </ul>
            </li> */}
            {/* <li>
              <a href="#"><i className="fa fa-lg fa-fw fa-home" /> <span className="menu-item-parent">Household</span></a>
              <ul>
                <li>
                  <a>View</a>
                </li>
                <li>
                  <a>Add</a>
                </li>
                <li>
                  <a>Edit</a>
                </li>
                <li>
                  <a>Delete</a>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <a href="#"><i className="fa fa-lg fa-fw fa-group" /> <span className="menu-item-parent">Family</span></a>
              <ul>
                <li>
                  <a>View</a>
                </li>
                <li>
                  <a>Add</a>
                </li>
                
                <li>
                  <a>Edit</a>
                </li>
                <li>
                  <a>Delete</a>
                </li>
              </ul>
            </li>	 */}
      
            {/* <li>
              <a href="#"><i className="fa fa-lg fa-fw  fa-file-text-o"></i> <span className="menu-item-parent">Reports</span></a>
              <ul>
                <li>
                  <a><i className="fa fa-lg fa-fw fa-calendar" /> <span className="menu-item-parent">Flood</span></a>
                </li>
                <li>
                  <a><i className="fa fa-lg fa-fw fa-map-marker" /> <span className="menu-item-parent">Eathquake</span><span className="badge bg-color-greenLight pull-right inbox-badge">9</span></a>
                </li>
                <li>
                  <a><i className="fa fa-lg fa-fw fa-map-marker" /> <span className="menu-item-parent">Storm Surge</span><span className="badge bg-color-greenLight pull-right inbox-badge">9</span></a>
                </li>
              </ul>
            </li>	 */}

             {/* <li>
              <a href="#"><i className="fa fa-lg fa-fw  fa-file-text-o"></i> <span className="menu-item-parent">Printables</span></a>
              <ul>
                <li>
                  <LinkContainer to={'/printables/flood'}>                   
                  <a><i className="fa fa-lg fa-fw fa-calendar" /> <span className="menu-item-parent">Floods</span></a>
                  </LinkContainer>    
                </li>
                <li>
                  <a><i className="fa fa-lg fa-fw fa-map-marker" /> <span className="menu-item-parent">Eathquake</span><span className="badge bg-color-greenLight pull-right inbox-badge">9</span></a>
                </li>
                <li>
                  <a><i className="fa fa-lg fa-fw fa-map-marker" /> <span className="menu-item-parent">Storm Surge</span><span className="badge bg-color-greenLight pull-right inbox-badge">9</span></a>
                </li>
              </ul>
            </li>	 */}
           
          </ul>
        </nav>
        <span className="minifyme" data-action="minifyMenu"> 
          <i className="fa fa-arrow-circle-left hit" /> 
        </span>
      
      </aside>
      
      
    </div>
  )
}

export default SideNavSmartAdmin
