import {
    RIL_INFO_FAIL,
    RIL_INFO_REQUEST,
    RIL_INFO_RESET,
    RIL_INFO_SUCCESS,
    RIL_FAIL,
    RIL_REQUEST,
    RIL_RESET,
    RIL_SUCCESS,
    RIL_MUNICIPALITY_INFO_FAIL,
    RIL_MUNICIPALITY_INFO_REQUEST,
    RIL_MUNICIPALITY_INFO_RESET,
    RIL_MUNICIPALITY_INFO_SUCCESS,

} from '../constant/rilConstant'

export const rilInfoReducer = (state = { rilinfo: {} }, action) => {
    switch(action.type){
        case RIL_INFO_REQUEST:
            return {...state , loading:true }

        case RIL_INFO_SUCCESS:
            return { loading:false, success:true, rilinfo: action.payload }
          
        case RIL_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case RIL_INFO_RESET:
            return { rilinfo: {} } 
    
        default:
            return state
    }
}


export const rilReducer = (state = { ril: {} }, action) => {
    switch(action.type){
        case RIL_REQUEST:
            return {...state , loading:true }

        case RIL_SUCCESS:
            return { loading:false, success:true, ril: action.payload }
          
        case RIL_FAIL:
            return { loading:false, error: action.payload }
        
        case RIL_RESET:
            return { ril: {} } 
    
        default:
            return state
    }
}


export const rilMunicipalityInfoReducer = (state = { rilMunucipalityinfo: {} }, action) => {
    switch(action.type){
        case RIL_MUNICIPALITY_INFO_REQUEST:
            return {...state , loading:true }

        case RIL_MUNICIPALITY_INFO_SUCCESS:
            return { loading:false, success:true, rilMunucipalityinfo: action.payload }
          
        case RIL_MUNICIPALITY_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case RIL_MUNICIPALITY_INFO_RESET:
            return { rilMunucipalityinfo: {} } 
    
        default:
            return state
    }
}