import {HOUSEHOLD_LIST_FAIL,HOUSEHOLD_LIST_REQUEST,HOUSEHOLD_LIST_SUCCESS,
        BARANGAY_HOUSEHOLDS_FAIL,BARANGAY_HOUSEHOLDS_REQUEST,BARANGAY_HOUSEHOLDS_SUCCESS,BARANGAY_HOUSEHOLDS_RESET,
        BARANGAY_HOUSEHOLDS_INFO_FAIL,BARANGAY_HOUSEHOLDS_INFO_REQUEST,BARANGAY_HOUSEHOLDS_INFO_SUCCESS,BARANGAY_HOUSEHOLDS_INFO_RESET
        } from '../constant/householdConstants'

export const householdListReducers = (state = {gethouseholds:[]},action) => {
    switch(action.type){
        case HOUSEHOLD_LIST_REQUEST:
            return {loading:true, gethouseholds:[]}
        case HOUSEHOLD_LIST_SUCCESS:
            return {loading:false, success:true,gethouseholds:action.payload}
        case HOUSEHOLD_LIST_FAIL:
            return {loading:false, error:action.payload}
        default: 
            return state
    }
}

export const barangayHouseholdsReducer = (state = { barangayhouseholds: {} }, action) => {
    switch(action.type){
        case BARANGAY_HOUSEHOLDS_REQUEST:
            return {...state , loading:true }

        case BARANGAY_HOUSEHOLDS_SUCCESS:
            return { loading:false, success:true, barangayhouseholds: action.payload }
          
        case BARANGAY_HOUSEHOLDS_FAIL:
            return { loading:false, error: action.payload }
        
        case BARANGAY_HOUSEHOLDS_RESET:
            return { barangayhouseholds: {} } 
    
        default:
            return state
    }
}


export const householdsInfoReducer = (state = { householdinfo: {} }, action) => {
    switch(action.type){
        case BARANGAY_HOUSEHOLDS_REQUEST:
            return {...state , loading:true }

        case BARANGAY_HOUSEHOLDS_SUCCESS:
            return { loading:false, success:true, householdinfo: action.payload }
          
        case BARANGAY_HOUSEHOLDS_FAIL:
            return { loading:false, error: action.payload }
        
        case BARANGAY_HOUSEHOLDS_RESET:
            return { householdinfo: {} } 
    
        default:
            return state
    }
}