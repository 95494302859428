import { createSlice, configureStore} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { householdListReducers,barangayHouseholdsReducer,householdsInfoReducer } from './reducer/householdReducers'
import { municipalListReducers,municipalAggReducers } from './reducer/municipalReducers'
import { barangayListReducers,barangayPurokListReducers } from './reducer/barangayReducers'
import { 
  barangayPolygonReducers,
  adnFloodReducers,
  adnRilReducers,
  adnStormSurgeReducers,
  adnMunFloodReducers,
  adnMunRilReducers,
  adnMunStormSurgeReducers
 } from './reducer/polygonReducers'
import { 
  aggregatedHouseholdReducers,
  householdPerMunReducers,
  familyPerMunReducers,
  brgyInfoReducer,
  brgyInfoAllReducer,
  totalReportReducer,
  floodReportReducer,
  floodReportBarangayReducer,
  rilReportReducer,
  stormsurgeReportReducer,
  floodReportPerMunicipalityReducer,
  rilReportPerMunicipalityReducer,
  stormsurgeReportPerMunicipalityReducer
} from './reducer/reportReducers'
import { floodInfoReducer,floodReducer,floodMunicipalityInfoReducer, floodPurokInfoReducer } from './reducer/floodReducers'
import { rilInfoReducer,rilMunicipalityInfoReducer } from './reducer/rilReducers'
import { stormsurgeInfoReducer,stormSurgeMunicipalityInfosReducer } from './reducer/stormsurgeReducers'
import {
   pointSeniorReducer,
   pointSeniorReducerHF,
   pointSeniorReducerMF,
   pointSeniorReducerLF 

} from './reducer/pointReducer'

import { userLoginReducer,userRegisterReducer,userDetailsReducer,userUpdateProfileReducer,userLocationReducer } from "./reducer/userReducers";

import {floodWallRoofReducers} from "./reducer/purokReducers"
import {roofReducers,wallReducers} from "./reducer/libraryReducers"

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0
  },
  reducers: {
    incremented: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decremented: state => {
      state.value -= 1
    }
  }
})


export const { incremented, decremented } = counterSlice.actions

// const userInfoFromStorage = localStorage.getItem('userInfo') ?
//     JSON.parse(localStorage.getItem('userInfo')) : null


// const initialState = {

//   userLogin: { userInfo: userInfoFromStorage }
// }

// Fetch user info from local storage
const userInfoFromStorage = localStorage.getItem('userInfo');

// Parse user info if it exists, otherwise set to null
const userInfo = userInfoFromStorage ? JSON.parse(userInfoFromStorage) : null;

// Set initial state
const initialState = {
  userLogin: { userInfo: userInfo }
};

const store = configureStore({
  reducer: {
    householdListReducers,
    municipalListReducers,
    barangayListReducers,
    barangayHouseholdsReducer,
    barangayPolygonReducers,
    householdsInfoReducer,
    aggregatedHouseholdReducers,
    householdPerMunReducers,
    familyPerMunReducers,
    brgyInfoReducer,
    totalReportReducer,
    adnFloodReducers,
    adnRilReducers,
    adnStormSurgeReducers,
    floodInfoReducer,
    floodReportReducer,
    floodReportBarangayReducer,
    floodReducer,
    rilReportReducer,
    rilReportReducer,
    rilInfoReducer,
    stormsurgeReportReducer,
    stormsurgeInfoReducer,
    adnMunFloodReducers,
    adnMunRilReducers,
    adnMunStormSurgeReducers,
    pointSeniorReducer,
    pointSeniorReducerHF,
    pointSeniorReducerMF,
    pointSeniorReducerLF,
    floodReportPerMunicipalityReducer,
    rilReportPerMunicipalityReducer,
    stormsurgeReportPerMunicipalityReducer,
    municipalAggReducers,
    brgyInfoAllReducer,
    barangayPurokListReducers,
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    userDetails: userDetailsReducer, 
    userUpdateProfile: userUpdateProfileReducer,
    userLocation: userLocationReducer,
    floodMunInfo:floodMunicipalityInfoReducer,
    floodPurokInfo:floodPurokInfoReducer,
    rilMunInfo:rilMunicipalityInfoReducer,
    stormSurgeMunicipalityInfosReducer,
    floodWallRoofReducers,
    roofReducers,
    wallReducers,
  },
  preloadedState: initialState,
  middleware: [thunk,logger],
  devTools: process.env.NODE_ENV !== 'production', 
})







export default store