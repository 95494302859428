import {BARANGAY_LIST_FAIL,BARANGAY_LIST_REQUEST,BARANGAY_LIST_SUCCESS,
    BARANGAY_PUROK_LIST_FAIL,BARANGAY_PUROK_LIST_REQUEST,BARANGAY_PUROK_LIST_SUCCESS,BARANGAY_PUROK_LIST_RESET} from '../constant/barangayConstants'

export const barangayListReducers = (state = {barangays:[]},action) => {
    switch(action.type){
        case BARANGAY_LIST_REQUEST:
            return {loading:true, barangays:[]}
        case BARANGAY_LIST_SUCCESS:
            return {loading:false, barangays:action.payload}
        case BARANGAY_LIST_FAIL:
            return {loading:false, error:action.payload}
        default: 
            return state
    }
}


export const barangayPurokListReducers = (state = { purok: {} }, action) => {
    switch(action.type){
        case BARANGAY_PUROK_LIST_REQUEST:
            return {...state , loading:true }
        case BARANGAY_PUROK_LIST_SUCCESS:
            return { loading:false, success:true, purok: action.payload }
        case BARANGAY_PUROK_LIST_FAIL:
            return { loading:false, error: action.payload }
        case BARANGAY_PUROK_LIST_RESET:
                return { purok: {} } 
        default: 
            return state
    }
}

