import React, { useEffect, useRef }  from 'react'
import { NavDropdown } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'
import {useDispatch,useSelector} from 'react-redux'
import { logout,getUserDetails } from '../actions/userActions';



function HeaderSmartAdmin() {

  const userLogin = useSelector(state => state.userLogin)
  const dispatch = useDispatch()
  const { userInfo } = userLogin

  
  const logoutHandler = () => {
    dispatch(logout())
  }

  const headerStyle = {
    backgroundImage: 'url("/static/smart-admin/img/glass.jpg")', // Replace with the actual path to your image
  };
  
 
  return (
    <div>
      {/* HEADER */}
      <header id="header" style={headerStyle}>
        <div id="logo-group" style={{ position: 'relative' }}>
          {/* PLACE YOUR LOGO HERE */}
          <LinkContainer to='/'>
            <a>
          <span id="logo" > <img id='logo-adn' src="/static/images/drrmdis.png" alt="DRRMDIS" style={{ width: 'auto', height: '110px', marginTop:'-20px' }} /> </span>
          </a>
          </LinkContainer>
          {/* <span id="logo"> <img src="images/drrmdis.png" alt="DRRMDIS" style={{ width: 'auto', height: '30px' }} /> </span> */}
          {/* END LOGO PLACEHOLDER */}         
        </div>    
        {/* pulled right: nav area */}
        <div className="pull-right">
          {/* collapse menu button */}
          <div id="hide-menu" className="btn-header pull-right">
            <span> <a href="#" data-action="toggleMenu" title="Collapse Menu"><i className="fa fa-reorder" /></a> </span>
          </div>
       
          {userInfo ? (


            <div id="user" className="btn-header transparent pull-left">
               <NavDropdown title={<i className="fa fa-user"></i> } id='username'>
                      <LinkContainer to='/profile'>
                        <NavDropdown.Item  >{userInfo.name}</NavDropdown.Item>
                      </LinkContainer>
                          <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                </NavDropdown>

             </div >
            
          
          ):(
            <LinkContainer to='/login'>
            <div id="login" className="btn-header transparent pull-left">
                <span> <a  title="Login"  ><i className="fa fa-sign-in" /></a> </span>  
             </div>
             </LinkContainer>
          )}

          
          {/* end logout button */}
      
          {/* fullscreen button */}
          <div id="fullscreen" className="btn-header transparent pull-right">
            <span> <a href="#" data-action="launchFullscreen" title="Full Screen"><i className="fa fa-arrows-alt" /></a> </span>
          </div>
          {/* end fullscreen button */}
         
          
        </div>
        {/* end pulled right: nav area */}
      </header>

      {/* END HEADER */}

    </div>
  )
}

export default HeaderSmartAdmin
