export const AGGREGATED_HOUSEHOLD_REQUEST = 'AGGREGATED_HOUSEHOLD_REQUEST'
export const AGGREGATED_HOUSEHOLD_SUCCESS = 'AGGREGATED_HOUSEHOLD_SUCCESS'
export const AGGREGATED_HOUSEHOLD_FAIL = 'AGGREGATED_HOUSEHOLD_FAIL'
export const AGGREGATED_HOUSEHOLD_RESET = 'AGGREGATED_HOUSEHOLD_RESET'

export const MUNICIPAL_HOUSEHOLD_REQUEST = 'MUNICIPAL_HOUSEHOLD_REQUEST'
export const MUNICIPAL_HOUSEHOLD_SUCCESS = 'MUNICIPAL_HOUSEHOLD_SUCCESS'
export const MUNICIPAL_HOUSEHOLD_FAIL = 'MUNICIPAL_HOUSEHOLD_FAIL'
export const MUNICIPAL_HOUSEHOLD_RESET = 'MUNICIPAL_HOUSEHOLD_RESET'

export const MUNICIPAL_FAMILY_REQUEST = 'MUNICIPAL_FAMILY_REQUEST'
export const MUNICIPAL_FAMILY_SUCCESS = 'MUNICIPAL_FAMILY_SUCCESS'
export const MUNICIPAL_FAMILY_FAIL = 'MUNICIPAL_FAMILY_FAIL'
export const MUNICIPAL_FAMILY_RESET = 'MUNICIPAL_FAMILY_RESET'

export const BARANGAY_INFO_REQUEST = 'BARANGAY_INFO_REQUEST'
export const BARANGAY_INFO_SUCCESS = 'BARANGAY_INFO_SUCCESS'
export const BARANGAY_INFO_FAIL = 'BARANGAY_INFO_FAIL'
export const BARANGAY_INFO_RESET = 'BARANGAY_INFO_RESET'


export const BARANGAY_INFO_ALL_REQUEST = 'BARANGAY_INFO_ALL_REQUEST'
export const BARANGAY_INFO_ALL_SUCCESS = 'BARANGAY_INFO_ALL_SUCCESS'
export const BARANGAY_INFO_ALL_FAIL = 'BARANGAY_INFO_ALL_FAIL'
export const BARANGAY_INFO_ALL_RESET = 'BARANGAY_INFO_ALL_RESET'

export const TOTAL_REPORT_REQUEST = 'TOTAL_REPORT_REQUEST'
export const TOTAL_REPORT_SUCCESS = 'TOTAL_REPORT_SUCCESS'
export const TOTAL_REPORT_FAIL = 'TOTAL_REPORT_FAIL'
export const TOTAL_REPORT_RESET = 'TOTAL_REPORT_RESET'

export const FLOOD_REPORT_REQUEST = 'FLOOD_REPORT_REQUEST'
export const FLOOD_REPORT_SUCCESS = 'FLOOD_REPORT_SUCCESS'
export const FLOOD_REPORT_FAIL = 'FLOOD_REPORT_FAIL'
export const FLOOD_REPORT_RESET = 'FLOOD_REPORT_RESET'

export const FLOOD_REPORT_BARANGAY_REQUEST = 'FLOOD_REPORT_BARANGAY_REQUEST'
export const FLOOD_REPORT_BARANGAY_SUCCESS = 'FLOOD_REPORT_BARANGAY_SUCCESS'
export const FLOOD_REPORT_BARANGAY_FAIL = 'FLOOD_REPORT_BARANGAY_FAIL'
export const FLOOD_REPORT_BARANGAY_RESET = 'FLOOD_REPORT_BARANGAY_RESET'

export const FLOOD_REPORT_PER_MUNICIPALITY_REQUEST = 'FLOOD_REPORT_PER_MUNICIPALITY_REQUEST'
export const FLOOD_REPORT_PER_MUNICIPALITY_SUCCESS = 'FLOOD_REPORT_PER_MUNICIPALITY_SUCCESS'
export const FLOOD_REPORT_PER_MUNICIPALITY_FAIL = 'FLOOD_REPORT_PER_MUNICIPALITY_FAIL'
export const FLOOD_REPORT_PER_MUNICIPALITY_RESET = 'FLOOD_REPORT_PER_MUNICIPALITY_RESET'


export const RIL_REPORT_REQUEST = 'RIL_REPORT_REQUEST'
export const RIL_REPORT_SUCCESS = 'RIL_REPORT_SUCCESS'
export const RIL_REPORT_FAIL = 'RIL_REPORT_FAIL'
export const RIL_REPORT_RESET = 'RIL_REPORT_RESET'

export const RIL_REPORT_PER_MUNICIPALITY_REQUEST = 'RIL_REPORT_PER_MUNICIPALITY_REQUEST'
export const RIL_REPORT_PER_MUNICIPALITY_SUCCESS = 'RIL_REPORT_PER_MUNICIPALITY_SUCCESS'
export const RIL_REPORT_PER_MUNICIPALITY_FAIL = 'RIL_REPORT_PER_MUNICIPALITY_FAIL'
export const RIL_REPORT_PER_MUNICIPALITY_RESET = 'RIL_REPORT_PER_MUNICIPALITY_RESET'

export const STORM_SURGE_REPORT_REQUEST = 'STORM_SURGE_REPORT_REQUEST'
export const STORM_SURGE_REPORT_SUCCESS = 'STORM_SURGE_REPORT_SUCCESS'
export const STORM_SURGE_REPORT_FAIL = 'STORM_SURGE_REPORT_FAIL'
export const STORM_SURGE_REPORT_RESET = 'STORM_SURGE_REPORT_RESET'

export const STORM_SURGE_REPORT_PER_MUNICIPALITY_REQUEST = 'STORM_SURGE_REPORT_PER_MUNICIPALITY_REQUEST'
export const STORM_SURGE_REPORT_PER_MUNICIPALITY_SUCCESS = 'STORM_SURGE_REPORT_PER_MUNICIPALITY_SUCCESS'
export const STORM_SURGE_REPORT_PER_MUNICIPALITY_FAIL = 'STORM_SURGE_REPORT_PER_MUNICIPALITY_FAIL'
export const STORM_SURGE_REPORT_PER_MUNICIPALITY_RESET = 'STORM_SURGE_REPORT_PER_MUNICIPALITY_RESET'



