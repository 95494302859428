import React , {useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CanvasJSReact from '@canvasjs/react-charts';
import { familydPerMun } from '../actions/reportActions'

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function FamilyPerMunPieGraph() {

  const dispatch = useDispatch()

  const familyNum = useSelector(state => state.familyPerMunReducers)
  const {familymun} = familyNum

  useEffect(() => {
    dispatch(familydPerMun())
  },[dispatch])  
    
    
    
    const total = familymun.reduce((sum, entry) => sum + entry.families, 0);
    const optionsPie = {
    animationEnabled: true,
     title: {
        text: "FAMILY PER MUNICIPAL"
     },
    subtitles: [{
        text: total,
        verticalAlign: "center",
        fontSize: 20,
        dockInsidePlotArea: true
    }],
    data: [{
        type: "doughnut",
        showInLegend: true,
        indexLabel: "{name}: {y}",
        dataPoints:familymun.map((family) => (
            { name: family.munname,y: family.families}))            
    }]
    } 

  return (
    <div>
      <CanvasJSChart options={optionsPie}  />
    </div>
  )
}

export default FamilyPerMunPieGraph
