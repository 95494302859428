import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'
import Loader from '../components/Loader'

import {getFloodReportPerMunicipality} from '../actions/reportActions'

import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function SocEcoFloodMunPieGraph(props) {

    const dispatch = useDispatch()
    const [floodInfo,setFloodInfo] = useState(null)

    useEffect(()=>{
        if(props.municipalId){
           
            dispatch(getFloodReportPerMunicipality())
        }
        
    },[props.municipalId])
    
    const municipalagg = useSelector(state => state.municipalAggReducers)
    const {municipalsagg,success:successmunAgg,loading:loadingMunAgg} = municipalagg

    const floodReportR = useSelector(state => state.floodReportPerMunicipalityReducer)
    const {floodReportPerMunicipality,success:successfloodReportPerMunicipality} = floodReportR


    useEffect(()=>{
      if(successfloodReportPerMunicipality){
        setFloodInfo(floodReportPerMunicipality)
       }
    },[successfloodReportPerMunicipality,floodReportPerMunicipality])

    
    const municipalsaggs = municipalsagg.find((feature) => feature.psgccode === props.municipalId)

    const floodMunReport = Array.isArray(floodInfo) && floodInfo.filter((feature) => feature.psgccode === props.municipalId)


    const totalHHFloodAll = Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.households || 0), 0) : 0;
    const totalFamFloodAll = Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.families || 0), 0) : 0;
    const totalPopFloodAll = Array.isArray(floodMunReport) ? floodMunReport.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0;


    const householdFloodInfoAll = {

        animationEnabled: true,
        exportFileName: "Household Exposed to Flood",
        exportEnabled: true,
        title:{
          text: "Household Exposed to Flood"
        },
        data: [],
    }

    const famFloodInfoAll = {

      animationEnabled: true,
      exportFileName: "Families Exposed to Flood",
      exportEnabled: true,
      title:{
        text: "Families Exposed to Flood"
      },
      data: [],
    }

    const popFloodInfoAll = {

      animationEnabled: true,
      exportFileName: "Population Exposed to Flood",
      exportEnabled: true,
      title:{
        text: "Population Exposed to Flood"
      },
      data: [],
    }

    if (municipalsaggs && floodMunReport && floodMunReport.length > 0) {
    householdFloodInfoAll.data = [{
      type: "pie",
      showInLegend: true,
      legendText: "{label}",
      toolTipContent: "{label}: <strong>{y}%</strong>",
      indexLabel: "{y}%",
      indexLabelPlacement: "inside",
      indexLabelFontSize: 12,
      dataPoints: floodMunReport.map((feature, index) => ({
        y: Number((feature.households / municipalsaggs.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
        label: feature.flood_id.toString(), // Ensure label is a string
        color: feature.flood_id === 'VHF' ? "#28286e" : feature.flood_id === 'HF' ? "#3c46c8" : feature.flood_id === 'MF' ? "#783cc8" : "#f5dcf5",
        indexLabelFontColor: feature.flood_id === 'VHF' ? "#ffff" : feature.flood_id === 'HF' ? "#ffff" : feature.flood_id === 'MF' ? "#ffff" : "#00000",
      })).concat({
        y: Number(((municipalsaggs.households - totalHHFloodAll) / municipalsaggs.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
        label: "Safe",
        color: "#4CBB17",
        indexLabelFontColor: "#ffff",
      }),
    }];

    famFloodInfoAll.data = [{
      type: "pie",
      showInLegend: true,
      legendText: "{label}",
      toolTipContent: "{label}: <strong>{y}%</strong>",
      indexLabel: "{y}%",
      indexLabelPlacement: "inside",
      indexLabelFontSize: 12,
      dataPoints:  (floodMunReport || []).map((feature, index) => ({
        y: Number((feature.families/municipalsaggs.families) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
        label: feature.flood_id.toString(), // Ensure label is a string
        color: feature.flood_id === 'VHF' ? "#28286e" : feature.flood_id === 'HF' ? "#3c46c8" : feature.flood_id === 'MF' ? "#783cc8" : "#f5dcf5",
        indexLabelFontColor: feature.flood_id === 'VHF' ? "#ffff" : feature.flood_id === 'HF' ? "#ffff" : feature.flood_id === 'MF' ? "#ffff" : "#00000",
      })).concat({
        y: Number(((municipalsaggs.families - totalFamFloodAll) / municipalsaggs.families)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
        label: "Safe",
        color: "#4CBB17",
        indexLabelFontColor: "#ffff",
      }),
    }];


    popFloodInfoAll.data = [{
      type: "pie",
      showInLegend: true,
      legendText: "{label}",
      toolTipContent: "{label}: <strong>{y}%</strong>",
      indexLabel: "{y}%",
      indexLabelPlacement: "inside",
      indexLabelFontSize: 12,
      dataPoints:  (floodMunReport || []).map((feature, index) => ({
        y: Number((feature.person/municipalsaggs.person) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
        label: feature.flood_id.toString(), // Ensure label is a string
        color: feature.flood_id === 'VHF' ? "#28286e" : feature.flood_id === 'HF' ? "#3c46c8" : feature.flood_id === 'MF' ? "#783cc8" : "#f5dcf5",
        indexLabelFontColor: feature.flood_id === 'VHF' ? "#ffff" : feature.flood_id === 'HF' ? "#ffff" : feature.flood_id === 'MF' ? "#ffff" : "#00000",
      })).concat({
        y: Number(((municipalsaggs.person - totalPopFloodAll) / municipalsaggs.person)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
        label: "Safe",
        color: "#4CBB17",
        indexLabelFontColor: "#ffff",
      }),
      }]

    }



    return (
      <>
        {!floodMunReport ? <Loader/>
            :
              <div className='row'>
                      <div className='col-md-4'>
                        <div className="well padding-10"> 
                          <CanvasJSChart options = {householdFloodInfoAll}/>
                        </div>
                      </div>
                      <div className='col-md-4'>
                      <div className="well padding-10"> 
                          <CanvasJSChart options = {famFloodInfoAll}/>
                        </div>                 
                      </div>
                      <div className='col-md-4'>
                      <div className="well padding-10"> 
                          <CanvasJSChart options = {popFloodInfoAll}/>
                        </div>                
                      </div>  
              </div>
          }
      </>
    )
}

