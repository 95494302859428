import {
    AGGREGATED_HOUSEHOLD_FAIL,
    AGGREGATED_HOUSEHOLD_REQUEST,
    AGGREGATED_HOUSEHOLD_SUCCESS,
    AGGREGATED_HOUSEHOLD_RESET,

    MUNICIPAL_HOUSEHOLD_FAIL,
    MUNICIPAL_HOUSEHOLD_REQUEST,
    MUNICIPAL_HOUSEHOLD_SUCCESS,
    MUNICIPAL_HOUSEHOLD_RESET,

    MUNICIPAL_FAMILY_FAIL,
    MUNICIPAL_FAMILY_REQUEST,
    MUNICIPAL_FAMILY_SUCCESS,
    MUNICIPAL_FAMILY_RESET,

    BARANGAY_INFO_FAIL,
    BARANGAY_INFO_REQUEST,
    BARANGAY_INFO_SUCCESS,
    BARANGAY_INFO_RESET,

    BARANGAY_INFO_ALL_FAIL,
    BARANGAY_INFO_ALL_REQUEST,
    BARANGAY_INFO_ALL_SUCCESS,
    BARANGAY_INFO_ALL_RESET,

    TOTAL_REPORT_FAIL,
    TOTAL_REPORT_REQUEST,
    TOTAL_REPORT_SUCCESS,
    TOTAL_REPORT_RESET,

    FLOOD_REPORT_FAIL,
    FLOOD_REPORT_REQUEST,
    FLOOD_REPORT_SUCCESS,
    FLOOD_REPORT_RESET,

    FLOOD_REPORT_BARANGAY_FAIL,
    FLOOD_REPORT_BARANGAY_REQUEST,
    FLOOD_REPORT_BARANGAY_SUCCESS,
    FLOOD_REPORT_BARANGAY_RESET,

    FLOOD_REPORT_PER_MUNICIPALITY_FAIL,
    FLOOD_REPORT_PER_MUNICIPALITY_REQUEST,
    FLOOD_REPORT_PER_MUNICIPALITY_SUCCESS,
    FLOOD_REPORT_PER_MUNICIPALITY_RESET,


    RIL_REPORT_FAIL,
    RIL_REPORT_REQUEST,
    RIL_REPORT_SUCCESS,
    RIL_REPORT_RESET,

    RIL_REPORT_PER_MUNICIPALITY_FAIL,
    RIL_REPORT_PER_MUNICIPALITY_REQUEST,
    RIL_REPORT_PER_MUNICIPALITY_SUCCESS,
    RIL_REPORT_PER_MUNICIPALITY_RESET,

    STORM_SURGE_REPORT_FAIL,
    STORM_SURGE_REPORT_REQUEST,
    STORM_SURGE_REPORT_SUCCESS,
    STORM_SURGE_REPORT_RESET,

    STORM_SURGE_REPORT_PER_MUNICIPALITY_REQUEST,
    STORM_SURGE_REPORT_PER_MUNICIPALITY_SUCCESS,
    STORM_SURGE_REPORT_PER_MUNICIPALITY_FAIL,
    STORM_SURGE_REPORT_PER_MUNICIPALITY_RESET,


} from '../constant/reportsConstatant'

export const aggregatedHouseholdReducers = (state = {aggregated:[]},action) => {
    switch(action.type){
        case AGGREGATED_HOUSEHOLD_REQUEST:
            return {loading:true, aggregated:[]}
        case AGGREGATED_HOUSEHOLD_SUCCESS:
            return {loading:false, aggregated:action.payload.aggregate, page: action.payload.page, pages: action.payload.pages }
        case AGGREGATED_HOUSEHOLD_FAIL:
            return {loading:false, error:action.payload}
        default: 
            return state
    }
}


export const householdPerMunReducers = (state = {hhmun:[]},action) => {
    switch(action.type){
        case MUNICIPAL_HOUSEHOLD_REQUEST:
            return {loading:true, hhmun:[]}
        case MUNICIPAL_HOUSEHOLD_SUCCESS:
            return {loading:false, hhmun:action.payload, success:true}
        case MUNICIPAL_HOUSEHOLD_FAIL:
            return {loading:false, error:action.payload}
        default: 
            return state
    }
}

export const familyPerMunReducers = (state = {familymun:[]},action) => {
    switch(action.type){   
        case MUNICIPAL_FAMILY_REQUEST:
            return {loading:true, familymun:[]}
        case MUNICIPAL_FAMILY_SUCCESS:
            return {loading:false, familymun:action.payload, success:true}
        case MUNICIPAL_FAMILY_FAIL:
            return {loading:false, error:action.payload}
        default: 
            return state
    }
}


export const brgyInfoReducer = (state = { brgyinfo: {} }, action) => {
    switch(action.type){
        case BARANGAY_INFO_REQUEST:
            return {...state , loading:true }

        case BARANGAY_INFO_SUCCESS:
            return { loading:false, success:true, brgyinfo: action.payload }
          
        case BARANGAY_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case BARANGAY_INFO_RESET:
            return { brgyinfo: {} } 
    
        default:
            return state
    }
}

export const brgyInfoAllReducer = (state = { brgyinfoall: {} }, action) => {
    switch(action.type){
        case BARANGAY_INFO_ALL_REQUEST:
            return {...state , loading:true }

        case BARANGAY_INFO_ALL_SUCCESS:
            return { loading:false, success:true, brgyinfoall: action.payload }
          
        case BARANGAY_INFO_ALL_FAIL:
            return { loading:false, error: action.payload }
        
        case BARANGAY_INFO_ALL_RESET:
            return { brgyinfoall: {} } 
    
        default:
            return state
    }
}


export const totalReportReducer = (state = { totalReport: {} }, action) => {
    switch(action.type){
        case TOTAL_REPORT_REQUEST:
            return {...state , loading:true }

        case TOTAL_REPORT_SUCCESS:
            return { loading:false, success:true, totalReport: action.payload }
          
        case TOTAL_REPORT_FAIL:
            return { loading:false, error: action.payload }
        
        case TOTAL_REPORT_RESET:
            return { totalReport: {} } 
    
        default:
            return state
    }
}


export const floodReportReducer = (state = { floodReport: {} }, action) => {
    switch(action.type){
        case FLOOD_REPORT_REQUEST:
            return {...state , loading:true }

        case FLOOD_REPORT_SUCCESS:
            return { loading:false, success:true, floodReport: action.payload }
          
        case FLOOD_REPORT_FAIL:
            return { loading:false, error: action.payload }
        
        case FLOOD_REPORT_RESET:
            return { floodReport: {} } 
    
        default:
            return state
    }
}

export const floodReportBarangayReducer = (state = { floodBarangayReport: {} }, action) => {
    switch(action.type){
        case FLOOD_REPORT_BARANGAY_REQUEST:
            return {...state , loading:true }

        case FLOOD_REPORT_BARANGAY_SUCCESS:
            return { loading:false, success:true, floodBarangayReport: action.payload }
          
        case FLOOD_REPORT_BARANGAY_FAIL:
            return { loading:false, error: action.payload }
        
        case FLOOD_REPORT_BARANGAY_RESET:
            return { floodBarangayReport: {} } 
    
        default:
            return state
    }
}


export const floodReportPerMunicipalityReducer = (state = { floodReportPerMunicipality: {} }, action) => {
    switch(action.type){
        case FLOOD_REPORT_PER_MUNICIPALITY_REQUEST:
            return {...state , loading:true }

        case FLOOD_REPORT_PER_MUNICIPALITY_SUCCESS:
            return { loading:false, success:true, floodReportPerMunicipality: action.payload }
          
        case FLOOD_REPORT_PER_MUNICIPALITY_FAIL:
            return { loading:false, error: action.payload }
        
        case FLOOD_REPORT_PER_MUNICIPALITY_RESET:
            return { floodReportPerMunicipality: {} } 
    
        default:
            return state
    }
}


export const rilReportReducer = (state = { rilReport: {} }, action) => {
    switch(action.type){
        case RIL_REPORT_REQUEST:
            return {...state , loading:true }

        case RIL_REPORT_SUCCESS:
            return { loading:false, success:true, rilReport: action.payload }
          
        case RIL_REPORT_FAIL:
            return { loading:false, error: action.payload }
        
        case RIL_REPORT_RESET:
            return { rilReport: {} } 
    
        default:
            return state
    }
}

export const rilReportPerMunicipalityReducer = (state = { RilReportPerMunicipality: {} }, action) => {
    switch(action.type){
        case RIL_REPORT_PER_MUNICIPALITY_REQUEST:
            return {...state , loading:true }

        case RIL_REPORT_PER_MUNICIPALITY_SUCCESS:
            return { loading:false, success:true, RilReportPerMunicipality: action.payload }
          
        case RIL_REPORT_PER_MUNICIPALITY_FAIL:
            return { loading:false, error: action.payload }
        
        case RIL_REPORT_PER_MUNICIPALITY_RESET:
            return { RilReportPerMunicipality: {} } 
    
        default:
            return state
    }
}


export const stormsurgeReportReducer = (state = { stormsurgeReport: {} }, action) => {
    switch(action.type){
        case STORM_SURGE_REPORT_REQUEST:
            return {...state , loading:true }

        case STORM_SURGE_REPORT_SUCCESS:
            return { loading:false, success:true, stormsurgeReport: action.payload }
          
        case STORM_SURGE_REPORT_FAIL:
            return { loading:false, error: action.payload }
        
        case STORM_SURGE_REPORT_RESET:
            return { stormsurgeReport: {} } 
    
        default:
            return state
    }
}

export const stormsurgeReportPerMunicipalityReducer = (state = { stormsurgeReportPerMunicipality: {} }, action) => {
    switch(action.type){
        case STORM_SURGE_REPORT_PER_MUNICIPALITY_REQUEST:
            return {...state , loading:true }

        case STORM_SURGE_REPORT_PER_MUNICIPALITY_SUCCESS:
            return { loading:false, success:true, stormsurgeReportPerMunicipality: action.payload }
          
        case STORM_SURGE_REPORT_PER_MUNICIPALITY_FAIL:
            return { loading:false, error: action.payload }
        
        case STORM_SURGE_REPORT_PER_MUNICIPALITY_RESET:
            return { stormsurgeReportPerMunicipality: {} } 
    
        default:
            return state
    }
}