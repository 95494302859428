import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'
import Loader from '../components/Loader'

import {getRilReportPerMunicipality} from '../actions/reportActions'


import CanvasJSReact from '@canvasjs/react-charts';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function SocEcoRilMunPieGraph(props) {
    
    const dispatch = useDispatch()
    const [rilInfo,setRilInfo] = useState(null)

    useEffect(()=>{
        if(props.municipalId)
            {
                dispatch(getRilReportPerMunicipality())
            }
        
    },[props.municipalId,dispatch])

    const municipalagg = useSelector(state => state.municipalAggReducers)
    const {municipalsagg,success:successmunAgg,loading:loadingMunAgg} = municipalagg
                                            
    const rilReportR = useSelector(state => state.rilReportPerMunicipalityReducer) 
    const {RilReportPerMunicipality,success:successrilReportPerMunicipality} = rilReportR

    useEffect(()=>{

      if(successrilReportPerMunicipality){
        setRilInfo(RilReportPerMunicipality)
     }
    },[successrilReportPerMunicipality,RilReportPerMunicipality])


    const municipalsaggs = municipalsagg.find((feature) => feature.psgccode === props.municipalId)

    const rilMunReport = Array.isArray(rilInfo) && rilInfo.filter((feature) => feature.muncode === props.municipalId)
  
    const totalHHRilAll = Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.households || 0), 0) : 0;
    const totalFamRilAll = Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.families || 0), 0) : 0;
    const totalPopRilAll = Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0;


    const householdRilInfoAll = {

        animationEnabled: true,
        exportFileName: "Household Exposed to RIL",
        exportEnabled: true,
        title:{
          text: "Household Exposed to RIL"
        },
        data: [],
    }

    const famRilInfoAll = {

      animationEnabled: true,
      exportFileName: "Families Exposed to RIL",
      exportEnabled: true,
      title:{
        text: "Families Exposed to RIL"
      },
      data: [],
    }

    const popRilInfoAll = {

      animationEnabled: true,
      exportFileName: "Population Exposed to RIL",
      exportEnabled: true,
      title:{
        text: "Population Exposed to RIL"
      },
      data: [],
    }



    if (municipalsaggs && rilMunReport && rilMunReport.length > 0) {

        householdRilInfoAll.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints: rilMunReport.map((feature, index) => ({
          y: Number((feature.households / municipalsaggs.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.ril_id.toString(), // Ensure label is a string
          color: feature.ril_id === "VHL" ? "#963c1e" : feature.ril_id === "HL" ? "#f01e1e" : feature.ril_id === "ML" ? "#009600" : "#ffff00",
          indexLabelFontColor: feature.ril_id === "VHL" ? "#ffff" : feature.ril_id === "HL" ? "#ffff" : feature.ril_id === "ML" ? "#ffff" : "#00000",
      })).concat({
          y: Number(((municipalsaggs.households - totalHHRilAll) / municipalsaggs.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }];

      famRilInfoAll.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints:  (rilMunReport || []).map((feature, index) => ({
          y: Number((feature.families/municipalsaggs.families) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.ril_id.toString(), // Ensure label is a string
          color: feature.ril_id === "VHL" ? "#963c1e" : feature.ril_id === "HL" ? "#f01e1e" : feature.ril_id === "ML" ? "#009600" : "#ffff00",
          indexLabelFontColor: feature.ril_id === "VHL" ? "#ffff" : feature.ril_id === "HL" ? "#ffff" : feature.ril_id === "ML" ? "#ffff" : "#00000",
      })).concat({
          y: Number(((municipalsaggs.families - totalFamRilAll) / municipalsaggs.families)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }];

      popRilInfoAll.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints:  (rilMunReport || []).map((feature, index) => ({
          y: Number((feature.person/municipalsaggs.person) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.ril_id.toString(), // Ensure label is a string
          color: feature.ril_id === "VHL" ? "#963c1e" : feature.ril_id === "HL" ? "#f01e1e" : feature.ril_id === "ML" ? "#009600" : "#ffff00",
          indexLabelFontColor: feature.ril_id === "VHL" ? "#ffff" : feature.ril_id === "HL" ? "#ffff" : feature.ril_id === "ML" ? "#ffff" : "#00000",
      })).concat({
          y: Number(((municipalsaggs.person - totalPopRilAll) / municipalsaggs.person)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
        }]

      }

    return (
       <>
       {!rilMunReport ? <Loader/>
          :
            <div className='row'>        
                        <div className='col-md-4'>
                          <div className="well padding-10"> 
                            <CanvasJSChart options = {householdRilInfoAll}/>
                          </div>
                        </div>
                        <div className='col-md-4'>
                        <div className="well padding-10"> 
                            <CanvasJSChart options = {famRilInfoAll}/>
                          </div>                 
                        </div>
                        <div className='col-md-4'>
                        <div className="well padding-10"> 
                            <CanvasJSChart options = {popRilInfoAll}/>
                          </div>                
                        </div>     
                </div>
        }
      </>
    )
}
