export const RIL_INFO_REQUEST = 'RIL_INFO_REQUEST'
export const RIL_INFO_SUCCESS = 'RIL_INFO_SUCCESS'
export const RIL_INFO_FAIL = 'RIL_INFO_FAIL'
export const RIL_INFO_RESET = 'RIL_INFO_RESET'

export const RIL_REQUEST = 'RIL_REQUEST'
export const RIL_SUCCESS = 'RIL_SUCCESS'
export const RIL_FAIL = 'RIL_FAIL'
export const RIL_RESET = 'RIL_RESET'

export const RIL_MUNICIPALITY_INFO_REQUEST = 'RIL_MUNICIPALITY_INFO_REQUEST'
export const RIL_MUNICIPALITY_INFO_SUCCESS = 'RIL_MUNICIPALITY_INFO_SUCCESS'
export const RIL_MUNICIPALITY_INFO_FAIL = 'RIL_MUNICIPALITY_INFO_FAIL'
export const RIL_MUNICIPALITY_INFO_RESET = 'RIL_MUNICIPALITYL_INFO_RESET'
