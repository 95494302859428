import axios from 'axios'
import {
    FLOOD_INFO_FAIL,
    FLOOD_INFO_REQUEST,
    FLOOD_INFO_SUCCESS,

    FLOOD_FAIL,
    FLOOD_REQUEST,
    FLOOD_SUCCESS,

    FLOOD_MUNICIPALITY_INFO_FAIL,
    FLOOD_MUNICIPALITY_INFO_REQUEST,
    FLOOD_MUNICIPALITY_INFO_SUCCESS,

    FLOOD_PUROK_INFO_FAIL,
    FLOOD_PUROK_INFO_REQUEST,
    FLOOD_PUROK_INFO_SUCCESS,

} from '../constant/floodConstants'


export const getFloodInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FLOOD_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/flood/${id}/info/`)
        dispatch({
            type: FLOOD_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: FLOOD_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}


export const getFlood = () => async (dispatch) => {
    try{
        dispatch({
            type: FLOOD_REQUEST
        })
      
        const {data} = await axios.get(`/api/flood/`)
        dispatch({
            type: FLOOD_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: FLOOD_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getMunicipalityFloodInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FLOOD_MUNICIPALITY_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/flood/municipal/${id}/info/`)
        dispatch({
            type: FLOOD_MUNICIPALITY_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: FLOOD_MUNICIPALITY_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getPurokFloodInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: FLOOD_PUROK_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/flood/purok/${id}/info/`)
        dispatch({
            type: FLOOD_PUROK_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: FLOOD_PUROK_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}