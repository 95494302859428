import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'

import {getFloodReportPerMunicipality} from '../actions/reportActions'


import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
 
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function AgeDisaggregatedFloodMunGraph(props) {
    
    const dispatch = useDispatch()
    const [floodInfo,setFloodInfo] = useState(null)

    useEffect(()=>{
        if(props.municipalId){
           
            dispatch(getFloodReportPerMunicipality())
        }
        
    },[props.municipalId])
    
    const municipalagg = useSelector(state => state.municipalAggReducers)
    const {municipalsagg,success:successmunAgg,loading:loadingMunAgg} = municipalagg

    const floodReportR = useSelector(state => state.floodReportPerMunicipalityReducer)
    const {floodReportPerMunicipality,success:successfloodReportPerMunicipality} = floodReportR


    useEffect(()=>{
      if(successfloodReportPerMunicipality){
        setFloodInfo(floodReportPerMunicipality)
       }
    },[successfloodReportPerMunicipality,floodReportPerMunicipality])

    const municipalsaggs = municipalsagg.find((feature) => feature.psgccode === props.municipalId)

    const floodMunReport = Array.isArray(floodInfo) && floodInfo.filter((feature) => feature.psgccode === props.municipalId)


  const customOrder = ["LF", "MF", "HF", "VHF"]; // Define the desired order of flood IDs


  const options = {
      theme: "light2",
      exportFileName: "Expose Population",
            exportEnabled: true,
      title: {
        text: "Expose Population"
      },
      subtitles: [{
        text: "Flooding "
      }],
      axisY: {
        prefix: ""
      },
      toolTip: {
        shared: true
      },
      data: [
        {
          type: "line",
          name: "Elderly",
          showInLegend: true,
          dataPoints: (floodMunReport || []).sort((a, b) => customOrder.indexOf(a.flood_id) - customOrder.indexOf(b.flood_id)).map((feature) => {
            if (feature.flood_id === "VHF") {
              return { label: "Very High", y: feature.elderly_male + feature.elderly_female, color: "#28286e" };
            } else if (feature.flood_id === "HF") {
              return { label: "High", y: feature.elderly_male + feature.elderly_female, color: "#3c46c8" };
            } else if (feature.flood_id === "MF") {
              return { label: "Moderate", y: feature.elderly_male + feature.elderly_female, color: "#783cc8" };
            } else if (feature.flood_id === "LF") {
              return { label: "Low", y: feature.elderly_male + feature.elderly_female, color: "#f5dcf5" };
            } else {
              return null;
            }
          }).filter(dataPoint => dataPoint !== null)
        },
        {
          type: "line",
          name: "Adult",
          showInLegend: true,
          dataPoints: (floodMunReport || []).sort((a, b) => customOrder.indexOf(a.flood_id) - customOrder.indexOf(b.flood_id)).map((feature) => {
            if (feature.flood_id === "VHF") {
              return { label: "Very High", y: feature.adult_male + feature.adult_female, color: "#28286e" };
            } else if (feature.flood_id === "HF") {
              return { label: "High", y: feature.adult_male + feature.adult_female, color: "#3c46c8" };
            } else if (feature.flood_id === "MF") {
              return { label: "Moderate", y: feature.adult_male + feature.adult_female, color: "#783cc8" };
            } else if (feature.flood_id === "LF") {
              return { label: "Low", y: feature.adult_male + feature.adult_female, color: "#f5dcf5" };
            } else {
              return null;
            }
          }).filter(dataPoint => dataPoint !== null)
        },
        {
          type: "line",
          name: "Children",
          showInLegend: true,
          dataPoints: (floodMunReport || []).sort((a, b) => customOrder.indexOf(a.flood_id) - customOrder.indexOf(b.flood_id)).map((feature) => {
            if (feature.flood_id === "VHF") {
              return { label: "Very High", y: feature.children_male + feature.children_female, color: "#28286e" };
            } else if (feature.flood_id === "HF") {
              return { label: "High", y: feature.children_male + feature.children_female, color: "#3c46c8" };
            } else if (feature.flood_id === "MF") {
              return { label: "Moderate", y: feature.children_male + feature.children_female, color: "#783cc8" };
            } else if (feature.flood_id === "LF") {
              return { label: "Low", y: feature.children_male + feature.children_female, color: "#f5dcf5" };
            } else {
              return null;
            }
          }).filter(dataPoint => dataPoint !== null)
        },
        {
          type: "line",
          name: "Infant",
          showInLegend: true,
          dataPoints: (floodMunReport || []).sort((a, b) => customOrder.indexOf(a.flood_id) - customOrder.indexOf(b.flood_id)).map((feature) => {
            if (feature.flood_id === "VHF") {
              return { label: "Very High", y: feature.infant_male + feature.infant_female, color: "#28286e" };
            } else if (feature.flood_id === "HF") {
              return { label: "High", y: feature.infant_male + feature.infant_female, color: "#3c46c8" };
            } else if (feature.flood_id === "MF") {
              return { label: "Moderate", y: feature.infant_male + feature.infant_female, color: "#783cc8" };
            } else if (feature.flood_id === "LF") {
              return { label: "Low", y: feature.infant_male + feature.infant_female, color: "#f5dcf5" };
            } else {
              return null;
            }
          }).filter(dataPoint => dataPoint !== null)
        },
    ]
    }

    return (
        <>
             <CanvasJSChart options = {options}/>
        </>
    )
}
