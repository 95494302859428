import React , {useState,useEffect,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation} from 'react-router-dom'
import ReactDOMServer from 'react-dom/server';

import { getUserDetails,updateUserProfile,getUserLocation } from '../actions/userActions'

import { listPolygonBarangay,adnFlood,adnMunFlood} from '../actions/polygonActions'

import {getFloodReportPerMunicipality} from '../actions/reportActions'
import { listBarangay } from '../actions/barangayActions'
import { listMunicipal } from '../actions/municipalActions'
import adn_flood from '../shapefile/FloodADN.json'
import barangaymap from '../shapefile/Barangay.json'
import barangaypoint from '../shapefile/Barangay_Point.json'
import { getMunicipalityFloodInfo  } from '../actions/floodActions'

import { MapContainer, TileLayer, Marker, GeoJSON,Popup} from 'react-leaflet'
import L,{Icon,divIcon} from 'leaflet'

import municipal from '../shapefile/Municipal_ADN.json'

import OverAllFloodGraph from '../graph/OverAllFloodGraph';


import $ from 'jquery'
import jQuery from 'jquery'

function PrintFloodsMunicipalProfile({history}) {

    const dispatch = useDispatch()

    const location = useLocation();
    const { search } =location;

    const currentDate = new Date();


    const [adnmunfld,setAdnMunFld] = useState(null)
    const [adnmunfloods,setAdnMunFloods] = useState(null)
    const [floodMunInfos, setFloodMunInfos] = useState([])
    const [municipalArea, setMunicipalArea] = useState('')

    const [name,setName] = useState('')
    const [psgcmun,setPsgcmun] = useState('')

    const municipalist = useSelector(state => state.municipalListReducers)
    const {municipals} = municipalist

    const barangaylist = useSelector(state => state.barangayListReducers)
    const {barangays} = barangaylist

    const userDetails = useSelector(state => state.userDetails)
    const {error, loading, user} = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const userLocation = useSelector(state => state.userLocation)
    const {userlocation,success:locationsuccess} = userLocation

    const adnmunflo = useSelector(state => state.adnMunFloodReducers)
    const {adnmunflood,success:successadnmunflood} = adnmunflo

    
    const floodpermunicipalityreports = useSelector(state => state.floodReportPerMunicipalityReducer)
    const {floodReportPerMunicipality,success} = floodpermunicipalityreports 

    const municipalityfloodinform = useSelector(state => state.floodMunInfo)
    const {floodMunucipalityinfo,success:successfloodMunucipalityinfo} = municipalityfloodinform


  useEffect(() => {

  if(!userInfo){
      history.push('/login')
  }else{
      if(!user || !user.name ){
 
      }else{
          setName(user.name)
          dispatch(listMunicipal())
          dispatch(listBarangay())
          
      }
  }
  dispatch(getUserLocation())

}, [dispatch,history, userInfo, user]) 


useEffect(() => {
  
  dispatch(getFloodReportPerMunicipality())
  dispatch(listMunicipal())
},[dispatch])   

useEffect(()=>{

  if(locationsuccess){
      setPsgcmun(userlocation.psgccode_mun)
      
  }
  if(successadnmunflood){
    setAdnMunFld(adnmunflood)
  }

})

const numberofbrgy = barangays.filter((item) => item.psgcmun === psgcmun).length;
const selectedMunicipal = municipals.find((item) => item.psgccode === psgcmun);
const areaMunicipal = municipal.features.find((item) => item.MunPSGC === psgcmun);
const municipalname = selectedMunicipal ? selectedMunicipal.munname : null;
const munpsgccode = selectedMunicipal ? selectedMunicipal.psgccode : null;

const contentRef = useRef(null);

useEffect(()=>{
  if(munpsgccode !== null){
  dispatch(adnMunFlood(munpsgccode))
  dispatch(getMunicipalityFloodInfo(munpsgccode))
  }
  
  
},[munpsgccode])

useEffect(()=>{

  if(successfloodMunucipalityinfo)
  {
    setFloodMunInfos(floodMunucipalityinfo)
  }

},[floodMunucipalityinfo])


  
    // Format the date as you desire (e.g., "March 4, 2024")
    const formattedDate = currentDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  
  
    const setColor = ({ properties }) => {
      return { weight: 1,color:'white' };
    };
  
    const customMarkerIcon = (name) =>
      divIcon({
        html: name,
        className: "icon"
      });
  
      const setIcon = ({ properties }, latlng) => {
        return L.marker(latlng, { icon: customMarkerIcon(properties.Name) });
      };

      const customIcon = new Icon({
        iconUrl: require("../img/icons8-green-circle-48.png"),
        iconSize: [10,10],
      })


      const customIcon1 = new Icon({
        iconUrl: require("../img/marker-icon.png"),
        iconSize: [20,20],
      })
     
     

        const barangaypolygon = barangaymap.features.filter((feature) => {
          if(feature.properties.MunPSGC === Number(munpsgccode)){
  
              return  feature;
              }
              
          });
  
          const barangaypoints = barangaypoint.features.filter((feature) => {
            if(feature.properties.MunPSGC === Number(munpsgccode)){
    
                return  feature;
                }
                
            });
  
  
            const areaMun = municipal.features

            const area = areaMun.filter((item) => {
              if(item.properties.MunPSGC === Number(munpsgccode)){
      
                  return  item;
                  }
                  
              }
            
            )
          
            if (Array.isArray(area) && area.length > 0) {
              // Check if area[0] is defined and has properties
              if (area[0] && area[0].properties) {
                  var munArea = area[0].properties.MunArea;
                  console.log("MunArea:", munArea); // Display MunArea value
              } else {
                  console.log("Area[0] or its properties are undefined");
              }
          } else {
              console.log("Area is not defined or has no elements");
          }
  
  
  
      const printPage1 = () => {
  
          window.print();
        };


        console.log('selectedMunicipal',barangaypoints)
        
        console.log('prints',selectedMunicipal)

     


  return (
  
    <div id="printsw"  style={{ overflowY: 'scroll', height: 640, border: '1px solid #ccc' }}>
        <article className="col-sm-12 col-md-10 col-lg-10" id="pds-page-request-result">
          <div className="table-responsive" id="print-pds-page-1" style={{}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onClick={printPage1} className="btn btn-primary btn-sm" style={{display:'none'}}><i className="fa fa-print" /> Print Page 1</button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac1" className="printareac1">
              <center>
                <table width="820px" style={{fontFamily: 'Arial', border: 'none', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <tbody>
                    {/* <tr>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Calibri', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em'}}>{formattedDate}</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Calibri', fontSize: '9pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em'}}>{formattedDate}</div>
                      </td>
                    </tr> */}
                    <tr>
                        <td colSpan={1} style={{border: 'none'}}></td>
                        <td colSpan={1} style={{border: 'none'}}></td>
                        <td colSpan={1} style={{border: 'none'}}></td>
                      <td colSpan={1} style={{border: 'none'}}></td> 
                      <td colSpan={1} style={{border: 'none'}}></td>
                      <td colSpan={1} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial Black', fontSize: '22pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', marginTop: '0.3em', marginBottom: '0.3em'}}><img src="/images/adn-logo.png" alt="adn-logo" style={{width: 50,height:50}} /></div>
                      </td>
                      <td colSpan={1} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial Black', fontSize: '22pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', marginTop: '0.3em', marginBottom: '0.3em'}}><img src="/images/drrm.png" alt="adn-logo" style={{width: 80,height:50}} /></div>
                      </td>
                      <td colSpan={1} style={{border: 'none'}}></td>
                      <td colSpan={1} style={{border: 'none'}}></td>
                      <td colSpan={1} style={{border: 'none'}}></td>
                      <td colSpan={1} style={{border: 'none'}}></td>          
                      <td colSpan={1} style={{border: 'none'}}></td>
                    </tr>
                    <tr style={{ lineHeight: '0.30cm' }}>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial', fontSize: '10pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center'}}>
                          Republika ng Pilipinas
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial', fontSize: '10pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center'}}>
                       Province of Agusan del Norte
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '9pt', marginLeft: '0.3em', textAlign: 'left'}}><div>
                          </div></div></td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>                   
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '14pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>HAZARD EXPOSURE ASSESSMENT REPORT</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;PROVINCE:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={9} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                     
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;MUNICIPALITY:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={9} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                     
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;NUMBER OF BARANGAY:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={9} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    
                    </tr>
                    <tr>
                      <td colSpan={4} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;TOTAL NUMBER OF HOUSEHOLD:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={8} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      
                    </tr>
                    <tr>
                      <td colSpan={4} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;TOTAL NUMBER OF POPULATION:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={8} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                     
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;POPULATION DENSITY:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={9} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                     
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;AVERAGE FAMILY SIZE:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={9} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;LAND AREA:{Number(munArea).toLocaleString()} square meter
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={9} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                     
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>&nbsp;TYPE OF HAZARD:
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={9} style={{height: 38, border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>A. A. Household Exposed to Flood* Hazard Map</div>
                      </td>
                    </tr>
                  
                   
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white',height:'20px !important'}}>
                       
                    <MapContainer center={[8.722919, 125.538999]} zoom={11} scrollWheelZoom={true}  zoomControl={false} >
                    <TileLayer  url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                    {/* <GeoJSON data={barangaymap} style={setColor} key={barangaymap.BrgyPSGC} />    */}
                    {locationsuccess ? 
                          adnmunfld && adnmunfld.map((feature, index) => (
                            
                              <GeoJSON                         
                                data={feature}
                                color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                                weight={1}
                                opacity={.5}
                                fillOpacity={0.8}
                                dashArray={3}
                                key={feature.properties.Susc_Ratin}                               
                              >          
                                </GeoJSON>
                            )) :""
                          }

              {floodMunInfos && floodMunInfos.map((household, index) => (
                    <Marker   
                                key={index}
                                position={[household.lat,household.long]} 
                                icon={customIcon}
                             
                                >  
                    </Marker>
                          ))}     

{barangaypolygon && barangaypolygon.map((feature, index) => (
                            (<GeoJSON data={feature} 
                                color="white" 
                                weight={1}
                                opacity={1}
                                dashArray={10}
                                fillOpacity={0}
                                > 
                            </GeoJSON>
                            )
                          ))}
              

         
                          {barangaypoints && barangaypoints.map((feature, index) => (
  
                                 
                                    <Marker   
                                    key={index}
                                    position={[feature.geometry.coordinates[1],feature.geometry.coordinates[0]]} 
                                    icon={customIcon1}
                                    > 
                                    <Popup>{feature.properties.BarangayNa}</Popup>
                                    {/* <Tooltip permanent>{feature.properties.BarangayNa}</Tooltip> */}
                                 </Marker>
                              ))}
                        
                    

                    </MapContainer>
                 
                    </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>Map shows the households exposed to flood hazard.</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>B. Land Area Exposed to Flood</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white',height:'20px !important'}}>
                    <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={10} scrollWheelZoom={true}  zoomControl={false} >
                    <TileLayer  url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                    <GeoJSON data={barangaymap} style={setColor} key={barangaymap.BrgyPSGC} />   
                    {adn_flood.features && adn_flood.features.map((feature, index) =>  
                      <GeoJSON
                        data={feature}
                        color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                        weight={2}
                        opacity={1}
                        fillOpacity={0.8}
                        dashArray={3}
                      ></GeoJSON>
                    
                    )}  
                    </MapContainer>          
                    </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>Flood Hazard Map of _______________________showing the possible extent of flooding in the area.</div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          Municipality
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>susceptibility</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>household</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>Family</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>Male</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>Female</div>
                      </td>
                    </tr>

                    {municipals && municipals.map((municipal, index) => (
                      <tr key={index} >
                        <td colSpan={2} style={{ border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25 }}>
                          <div style={{ textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1 }}>{municipal.munname}</div>
                        </td>
                        <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.flood_id}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.household_number}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.families}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.male}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.female}</label><br />
                            </td></tr>
                          ))}
                          </td>
                      </tr>
                    ))} */}

                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                  <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>Name of Barangay</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>Areas exposed to flood (in hectares) </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>Not prone</div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>TOTAL</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>The table shows the number of barangays exposed to flood with their corresponding land areas in hectares exposed to flood. It also shows the areas in hectares not prone to flooding.</div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>C. Population (households) Exposed to Flood</div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white',height:'20px !important'}}>
                       
                    <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={10} scrollWheelZoom={true}  zoomControl={false} >
                    <TileLayer  url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                    <GeoJSON data={barangaymap} style={setColor} key={barangaymap.BrgyPSGC} />   
                    {adn_flood.features && adn_flood.features.map((feature, index) => 
                      
                      
                      <GeoJSON

                        data={feature}
                        color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                        weight={2}
                        opacity={1}
                        fillOpacity={0.8}
                        dashArray={3}
                      ></GeoJSON>
                    
                    )}  

                    

                    </MapContainer>
                 
                    </td>
                    </tr>

                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                   
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}></div>
                      </td>
                    </tr>


                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>Hazard Classification</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>Number of Households</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>Not prone</div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>Low</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>Moderate</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>High</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>Very High</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                     <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>TOTAL</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                      <td style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}></div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>A total of ____ are prone to flooding which constitute __% of the total population of the area( ___ out of ____). It includes households not prone to flooding.</div>
                        </div>
                      </td>
                      
                    </tr>
                   
                   
                    <tr>
                      <td colSpan={12} style={{border: 'none', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                  </tbody></table>
              </center>
            </div></div>
        </article>
        
    </div>
  )
      

  
}

export default PrintFloodsMunicipalProfile
