import React , {useState,useEffect}  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { aggregatedHousehold } from '../actions/reportActions'
import { Table } from 'react-bootstrap'
import Paginate from '../components/Paginate'

function AggregatePerMun({history}) {

    const dispatch = useDispatch()

    const aggregatedhousehold = useSelector(state => state.aggregatedHouseholdReducers)
    const {success,aggregated, page, pages} = aggregatedhousehold

    //let keyword =  history.location.search
    let keyword = '';

    if (history && history.location) {
      keyword =  history.location.search
    }

    useEffect(() => {


        dispatch(aggregatedHousehold(keyword))
        
        }, [dispatch,keyword])

      

  return (
    <div>
      <Table striped hover responsive className='table table-bordered table-sm'>
          <thead>
            <tr>
              <th>Municipal</th>
              <th>Barangay</th>
              <th>Purok</th>
              <th>Household</th>
              <th>Families</th>
              <th>Male</th>
              <th>Female</th>
              <th>Infant(M)</th>
              <th>Infant(F)</th>
              <th>Children(M)</th>
              <th>Children(F)</th>
              <th>Adult(M)</th>
              <th>Adult(F)</th>
              <th>Elderly(M)</th>
              <th>Elderly(F)</th>
              <th>PDW(M)</th>
              <th>PDW(F)</th>
              <th>IP(M)</th>
              <th>IP(F)</th>
            </tr>
          </thead>
          <tbody>

                    {aggregated && aggregated.map(aggregates => (

                      <tr key={aggregates.munname}>
                            <td>{aggregates.munname}</td>
                            <td>{aggregates.brgyname}</td>
                            <td>{aggregates.purok_name}</td>
                            <td>{aggregates.households}</td>
                            <td>{aggregates.families}</td>
                            <td>{aggregates.male}</td>
                            <td>{aggregates.female}</td>
                            <td>{aggregates.male_infant}</td>
                            <td>{aggregates.female_infant}</td>
                            <td>{aggregates.male_children}</td>
                            <td>{aggregates.female_children}</td>
                            <td>{aggregates.male_adult}</td>
                            <td>{aggregates.female_adult}</td>
                            <td>{aggregates.male_elderly}</td>
                            <td>{aggregates.female_elderly}</td>
                            <td>{aggregates.pwd_male}</td>
                            <td>{aggregates.pwd_female}</td>
                            <td>{aggregates.ip_male}</td>
                            <td>{aggregates.ip_female}</td>
                      </tr>

            ))}
            
          </tbody>
        </Table>
          <Paginate pages={pages} page={page}  keyword={keyword}/>          
    </div>
  )
}

export default AggregatePerMun
