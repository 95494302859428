import React , {useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getTotalReport} from '../actions/reportActions'
import MainMap from '../map/MainMap'
import Loader from './Loader'
import Message from './Message'


function HomeSmartAdmin() {

  
  const [totalReports, setTotalReports] = useState([])

  const dispatch = useDispatch()

  
  const totalrep = useSelector(state => state.totalReportReducer)
  const {totalReport,success,loading,error} = totalrep
 
  
  const [PMShow, setPMShow] = useState(false);

  useEffect(() => {
    dispatch(getTotalReport())
    },[dispatch])  


  return (
    <div id="home-panel" className='container-fluid'>
        <div className="row"  style={{ position: 'relative' }}>
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-4">
            {/* <h1 className="page-title txt-color-blueDark"><i className="fa-fw fa fa-home" /> Dashboard <span>&gt; My Dashboard</span></h1> */}
          </div>
          {loading ? <Loader/>
                  : error ? <Message variant='danger'>{error}</Message>
                      :
                          <div className="col-xs-12 col-sm-5 col-md-5 col-lg-8">
                            <ul id="sparks" className="">
                              <li className="sparks-info">
                                <h6> Province<span className="txt-color-blue" >Agusan del Norte</span></h6>
                              </li>
                              <li className="sparks-info">
                                <h6> Household <span className="txt-color-blue">{totalReport[0] && Number(totalReport[0].households).toLocaleString()}</span></h6>
                                
                              </li>
                              <li className="sparks-info">
                                <h6> Families<span className="txt-color-blue">&nbsp;{totalReport[0] && Number(totalReport[0].families).toLocaleString()}</span></h6>
                                
                              </li>
                              <li className="sparks-info">
                                <h6>Populations <span className="txt-color-blue">&nbsp;{totalReport[0] && Number(totalReport[0].person).toLocaleString()}</span></h6>
                                
                              </li>
                            </ul>
                          </div> 
          }           
        </div>
    </div>
  )
}

export default HomeSmartAdmin
