export const STORM_SURGE_INFO_REQUEST = 'STORM_SURGE_INFO_REQUEST'
export const STORM_SURGE_INFO_SUCCESS = 'STORM_SURGE_INFO_SUCCESS'
export const STORM_SURGE_INFO_FAIL = 'STORM_SURGE_INFO_FAIL'
export const STORM_SURGE_INFO_RESET = 'STORM_SURGE_INFO_RESET'

export const STORM_SURGE_REQUEST = 'STORM_SURGE_REQUEST'
export const STORM_SURGE_SUCCESS = 'STORM_SURGE_SUCCESS'
export const STORM_SURGE_FAIL = 'STORM_SURGE_FAIL'
export const STORM_SURGE_RESET = 'STORM_SURGE_RESET'

export const STORM_SURGE_MUNICIPALITY_INFO_REQUEST = 'STORM_SURGE_MUNICIPALITY_INFO_REQUEST'
export const STORM_SURGE_MUNICIPALITY_INFO_SUCCESS = 'STORM_SURGE_MUNICIPALITY_INFO_SUCCESS'
export const STORM_SURGE_MUNICIPALITY_INFO_FAIL = 'STORM_SURGE_MUNICIPALITY_INFO_FAIL'
export const STORM_SURGE_MUNICIPALITY_INFO_RESET = 'STORM_SURGE_MUNICIPALITYL_INFO_RESET'

