import axios from 'axios'
import {BARANGAY_LIST_FAIL,BARANGAY_LIST_REQUEST,BARANGAY_LIST_SUCCESS,
    BARANGAY_PUROK_LIST_FAIL,BARANGAY_PUROK_LIST_REQUEST,BARANGAY_PUROK_LIST_SUCCESS} from '../constant/barangayConstants'


export const listBarangay = () => async (dispatch) => {
    try{
        dispatch({type:BARANGAY_LIST_REQUEST})

        const {data} = await axios.get('/api/barangay/')

        dispatch({
            type:BARANGAY_LIST_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:BARANGAY_LIST_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const puroklistBarangay = (id) => async (dispatch, getState) => {
    try{
        dispatch({type:BARANGAY_PUROK_LIST_REQUEST})

        const {data} = await axios.get(`/api/purok/${id}/info/`)

        dispatch({
            type:BARANGAY_PUROK_LIST_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:BARANGAY_PUROK_LIST_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


