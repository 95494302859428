import React from 'react'
import { Alert } from 'react-bootstrap'

function Message({ variant, children}) {
  return (
    <Alert variant={variant} className={variant} style={{ opacity: 1 }} >
      {children}
    </Alert>
  )
}

export default Message
