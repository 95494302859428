import {

PUROK_AGGREGATE_FAIL,
PUROK_AGGREGATE_REQUEST,
PUROK_AGGREGATE_RESET,
PUROK_AGGREGATE_SUCCESS,

FLOOD_WALL_ROOF_FAIL,
FLOOD_WALL_ROOF_REQUEST,
FLOOD_WALL_ROOF_RESET,
FLOOD_WALL_ROOF_SUCCESS,

    } from '../constant/purokConstants'

export const purokAggReducers = (state = { purokAgg: {} }, action) => {
    switch(action.type){
        case PUROK_AGGREGATE_REQUEST:
            return {...state , loading:true }
        case PUROK_AGGREGATE_SUCCESS:
            return { loading:false, success:true, purokAgg: action.payload }
        case PUROK_AGGREGATE_FAIL:
            return { loading:false, error: action.payload }
        case PUROK_AGGREGATE_RESET:
                return { purokAgg: {} } 
        default: 
            return state
    }
}

export const floodWallRoofReducers = (state = { wallroof: {} }, action) => {
    switch(action.type){
        case FLOOD_WALL_ROOF_REQUEST:
            return {...state , loading:true }
        case FLOOD_WALL_ROOF_SUCCESS:
            return { loading:false, success:true, wallroof: action.payload }
        case FLOOD_WALL_ROOF_FAIL:
            return { loading:false, error: action.payload }
        case FLOOD_WALL_ROOF_RESET:
                return { wallroof: {} } 
        default: 
            return state
    }
}