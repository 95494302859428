import axios from 'axios'
import {RIL_INFO_FAIL,
    RIL_INFO_REQUEST,
    RIL_INFO_SUCCESS,
    RIL_FAIL,
    RIL_REQUEST,
    RIL_SUCCESS,
    RIL_MUNICIPALITY_INFO_FAIL,
    RIL_MUNICIPALITY_INFO_REQUEST,
    RIL_MUNICIPALITY_INFO_SUCCESS,

} from '../constant/rilConstant'


export const getRilInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: RIL_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/ril/${id}/info/`)
        dispatch({
            type: RIL_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: RIL_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}


export const getRil = () => async (dispatch) => {
    try{
        dispatch({
            type: RIL_REQUEST
        })
      
        const {data} = await axios.get(`/api/ril/`)
        dispatch({
            type: RIL_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: RIL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}


export const getMunicipalityRilInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: RIL_MUNICIPALITY_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/ril/municipal/${id}/info/`)
        dispatch({
            type: RIL_MUNICIPALITY_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: RIL_MUNICIPALITY_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}