import React from 'react'
import FamilyGroupByGraph from '../graph/FamilyGroupByGraph'
import FamilyPerMunPieGraph from '../graph/FamilyPerMunPieGraph'

function FamilyDashboardScreen() {
  return (
     <div>
      <FamilyGroupByGraph/>
      <br/>
      <FamilyPerMunPieGraph/>
     </div>  	   
  )
}

export default FamilyDashboardScreen
