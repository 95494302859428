import React from 'react'
import { FaPeopleLine } from "react-icons/fa6";
import { MdFamilyRestroom,MdNaturePeople } from "react-icons/md"
import PopulationPerMunCombineGraph from '../graph/PopulationPerMunCombineGraph'


function MunicipalGraphScreen() {
  return (
    <div>
      <section className="col-lg-7 connectedSortable">
        <div className="card">
            <div className="card-header">
              <h3 className="card-title">
              <i className="mr-1"><FaPeopleLine /></i>
               Population Per Municipal
              </h3>
              <div className="card-tools">
                
              </div>
            </div>{/* /.card-header */}
            <div className="card-body">
            <PopulationPerMunCombineGraph/>
            </div>{/* /.card-body */}
          </div>
         
        </section>
        {/* /.Left col */}
        {/* right col (We are only adding the ID to make the widgets sortable)*/}
        <section className="col-lg-5 connectedSortable">
        <div className="card">
            <div className="card-header border-0">
              <h3 className="card-title">
                <i className="mr-1"><MdFamilyRestroom /></i>
                FAMILY PER MUNICIPAL
              </h3>
              {/* card tools */}
              <div className="card-tools">
                
              </div>
              {/* /.card-tools */}
            </div>
            <div className="card-body">
            {/* <FamilyPerMunPieGraph/> */}
            </div>
            {/* /.card-body*/}
            <div className="card-footer bg-transparent">
           
            </div>
          </div>
        
          {/* Map card */}
          <div className="card">
            <div className="card-header border-0">
              <h3 className="card-title">
              <i className="mr-1"><MdFamilyRestroom /></i>
              Family Endicator by Municipal
              </h3>
              {/* card tools */}
              <div className="card-tools">
                
              </div>
              {/* /.card-tools */}
            </div>
            <div className="card-body">
            {/* <FamilyGroupByGraph/> */}
            </div>
            {/* /.card-body*/}
            <div className="card-footer bg-transparent">
              <div className="row">
                <div className="col-4 text-center">
                  <div id="sparkline-1" />
                  <div className="text-white">Visitors</div>
                </div>
                {/* ./col */}
                <div className="col-4 text-center">
                  <div id="sparkline-2" />
                  <div className="text-white">Online</div>
                </div>
                {/* ./col */}
                <div className="col-4 text-center">
                  <div id="sparkline-3" />
                  <div className="text-white">Sales</div>
                </div>
                {/* ./col */}
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.card */}
        </section>
    </div>
  )
}

export default MunicipalGraphScreen
