import axios from 'axios'
import {MUNICIPAL_LIST_FAIL,MUNICIPAL_LIST_REQUEST,MUNICIPAL_LIST_SUCCESS,
    MUNICIPAL_AGGREGATE_FAIL,MUNICIPAL_AGGREGATE_REQUEST,MUNICIPAL_AGGREGATE_SUCCESS} from '../constant/municipalConstants'


export const listMunicipal = () => async (dispatch) => {
    try{
        dispatch({type:MUNICIPAL_LIST_REQUEST})

        const {data} = await axios.get('/api/municipal/')

        dispatch({
            type:MUNICIPAL_LIST_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:MUNICIPAL_LIST_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const aggregateMunicipal = () => async (dispatch) => {
    try{
        dispatch({type:MUNICIPAL_AGGREGATE_REQUEST})

        const {data} = await axios.get('/api/municipal/agg/')

        dispatch({
            type:MUNICIPAL_AGGREGATE_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:MUNICIPAL_AGGREGATE_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}