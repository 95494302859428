import {
    STORM_SURGE_INFO_FAIL,
    STORM_SURGE_INFO_REQUEST,
    STORM_SURGE_INFO_RESET,
    STORM_SURGE_INFO_SUCCESS,
    STORM_SURGE_FAIL,
    STORM_SURGE_REQUEST,
    STORM_SURGE_RESET,
    STORM_SURGE_SUCCESS,
    STORM_SURGE_MUNICIPALITY_INFO_FAIL,
    STORM_SURGE_MUNICIPALITY_INFO_REQUEST,
    STORM_SURGE_MUNICIPALITY_INFO_RESET,
    STORM_SURGE_MUNICIPALITY_INFO_SUCCESS


} from '../constant/stormsurgeConstant'

export const stormsurgeInfoReducer = (state = { stormsurgeinfo: {} }, action) => {
    switch(action.type){
        case STORM_SURGE_INFO_REQUEST:
            return {...state , loading:true }

        case STORM_SURGE_INFO_SUCCESS:
            return { loading:false, success:true, stormsurgeinfo: action.payload }
          
        case STORM_SURGE_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case STORM_SURGE_INFO_RESET:
            return { stormsurgeinfo: {} } 
    
        default:
            return state
    }
}


export const stormsurgeReducer = (state = { stormsurge: {} }, action) => {
    switch(action.type){
        case STORM_SURGE_REQUEST:
            return {...state , loading:true }

        case STORM_SURGE_SUCCESS:
            return { loading:false, success:true, stormsurge: action.payload }
          
        case STORM_SURGE_FAIL:
            return { loading:false, error: action.payload }
        
        case STORM_SURGE_RESET:
            return { stormsurge: {} } 
    
        default:
            return state
    }
}


export const stormSurgeMunicipalityInfosReducer = (state = { stormSurges: {} }, action) => {
    switch(action.type){
        case STORM_SURGE_MUNICIPALITY_INFO_REQUEST:
            return {...state , loading:true }

        case STORM_SURGE_MUNICIPALITY_INFO_SUCCESS:
            return { loading:false, success:true, stormSurges: action.payload }
          
        case STORM_SURGE_MUNICIPALITY_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case STORM_SURGE_MUNICIPALITY_INFO_RESET:
            return { stormSurges: {} } 
    
        default:
            return state
    }
}