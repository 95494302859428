import React , {useState,useEffect,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


import { listMunicipal,aggregateMunicipal } from '../actions/municipalActions'

function MunicipalScreen() {

  const dispatch = useDispatch()

  const municipalist = useSelector(state => state.municipalListReducers)
  const {municipals} = municipalist
  const municipalagg = useSelector(state => state.municipalAggReducers)
  const {municipalsagg} = municipalagg

  useEffect(() => {

    dispatch(listMunicipal())
    dispatch(aggregateMunicipal())

  },[dispatch]) 
  
  const [hovered, setHovered] = useState(false);
  

  return (
    <div >
       {/* MAIN CONTENT */}
        <div id="content">
        <div className="row">
            <div className="col-sm-12">
            <div className="well well-light">
                <h1>Agusan del Norte<small>  11 Municipalities</small></h1>
                <div className="row">
                {municipals && municipals.map((municipal, index) => (
                   <LinkContainer className='btn' key={municipal.id} to={`/municipalDetails/${municipal.psgccode}`} >                  
                   <div className="col-xs-12 col-sm-4 col-md-2"  >
                    <div className="panel panel-orange">
                   <div className="panel-heading">
                     <h3 className="panel-title" >
                    {municipal.munname}</h3>
                    </div>
                    <div key={index} className="panel-body no-padding text-align-center">
                        {/* <div className="the-price">
                        <h1>
                            $10<span className="subscript">/mo</span></h1>
                        <small>1 month FREE trial</small>
                        </div> */}
                        
                        
                        {Array.isArray(municipalsagg) && municipalsagg
                          .filter((agg) => agg.psgccode === municipal.psgccode)
                          .map((filteredmunicipalsagg, index) => (
                            <table className="table">
                            <tbody>
                            <tr>
                            <td>
                            Household - {Number(filteredmunicipalsagg.households).toLocaleString()}
                            </td>
                            </tr>
                            <tr className="active">
                            <td>
                            Families - {Number(filteredmunicipalsagg.families).toLocaleString()}
                            </td>
                            </tr>
                            <tr>
                            <td>
                            Individual - {Number(filteredmunicipalsagg.person).toLocaleString()}
                            </td>
                            </tr>
                            <tr className="active">
                            <td>
                            PWD - {Number(filteredmunicipalsagg.pwd).toLocaleString()}
                            </td>
                            </tr>
                            <tr>
                            <td>
                            Malnourished - {Number(filteredmunicipalsagg.malnurished).toLocaleString()}
                            </td>
                            </tr>
                            <tr className="active">
                            <td>
                            IP - {Number(filteredmunicipalsagg.ip).toLocaleString()}
                            </td>
                            </tr>
                            </tbody></table>
                          ))} 
                            
                           
                        
                    </div>
                    <div className="panel-footer no-padding">
                        {/* <a href="javascript:void(0);" className="btn bg-color-orange txt-color-white btn-block" role="button">Sign Up</a> */}
                    </div>
                    </div>
                    </div>
                   
                    </LinkContainer>
                ))}
                
                </div>			
            </div>
            </div>
        </div>
        </div>
      {/* END MAIN CONTENT */}

      
    </div>
  )
}

export default MunicipalScreen
