import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'

import {getFloodReport} from '../actions/reportActions'
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function SocEcoFloodBrgyPieGraph(props) {

    const dispatch = useDispatch()
    const [floodInfo,setFloodInfo] = useState(null)

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const floodreports = useSelector(state => state.floodReportReducer)
    const {floodReport,success:successfloodreports} = floodreports

    useEffect(()=> {
        dispatch(getFloodReport(props.brgyId))
       },[props.brgyId])

    useEffect(()=>{
      if(successfloodreports){
        setFloodInfo(floodReport)
       }
    },[successfloodreports,floodReport])

       
                
    const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === props.brgyId)
    

    const totalHHFlood = Array.isArray(floodInfo) ? floodInfo.reduce((sum, entry) => sum + (entry.households || 0), 0) : 0;
    const totalFamFlood = Array.isArray(floodInfo) ? floodInfo.reduce((sum, entry) => sum + (entry.families || 0), 0) : 0;
    const totalPopFlood = Array.isArray(floodInfo) ? floodInfo.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0;

    
    const householdFloodInfo = {

        animationEnabled: true,
        exportFileName: "Household Exposed to Flood",
        exportEnabled: true,
        title:{
          text: "Household Exposed to Flood"
        },
        data: [],
    }

    const famFloodInfo = {

      animationEnabled: true,
      exportFileName: "Families Exposed to Flood",
      exportEnabled: true,
      title:{
        text: "Families Exposed to Flood"
      },
      data: [],
    }

    const popFloodInfo = {

      animationEnabled: true,
      exportFileName: "Population Exposed to Flood",
      exportEnabled: true,
      title:{
        text: "Population Exposed to Flood"
      },
      data: [],
    }

    if (barangay_info && floodInfo && floodInfo.length > 0) {
      householdFloodInfo.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints: floodInfo.map((feature, index) => ({
          y: Number((feature.households / barangay_info.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.flood_id.toString(), // Ensure label is a string
          color: feature.flood_id === 'VHF' ? "#28286e" : feature.flood_id === 'HF' ? "#3c46c8" : feature.flood_id === 'MF' ? "#783cc8" : "#f5dcf5",
          indexLabelFontColor: feature.flood_id === 'VHF' ? "#ffff" : feature.flood_id === 'HF' ? "#ffff" : feature.flood_id === 'MF' ? "#ffff" : "#00000",
        })).concat({
          y: Number(((barangay_info.households - totalHHFlood) / barangay_info.households) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }];

      famFloodInfo.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints:  (floodInfo || []).map((feature, index) => ({
          y: Number((feature.families/barangay_info.families) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.flood_id.toString(), // Ensure label is a string
          color: feature.flood_id === 'VHF' ? "#28286e" : feature.flood_id === 'HF' ? "#3c46c8" : feature.flood_id === 'MF' ? "#783cc8" : "#f5dcf5",
          indexLabelFontColor: feature.flood_id === 'VHF' ? "#ffff" : feature.flood_id === 'HF' ? "#ffff" : feature.flood_id === 'MF' ? "#ffff" : "#00000",
        })).concat({
          y: Number(((barangay_info.families - totalFamFlood) / barangay_info.families)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }]


      popFloodInfo.data = [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{y}%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints:  (floodInfo || []).map((feature, index) => ({
          y: Number((feature.person/barangay_info.person) * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: feature.flood_id.toString(), // Ensure label is a string
          color: feature.flood_id === 'VHF' ? "#28286e" : feature.flood_id === 'HF' ? "#3c46c8" : feature.flood_id === 'MF' ? "#783cc8" : "#f5dcf5",
          indexLabelFontColor: feature.flood_id === 'VHF' ? "#ffff" : feature.flood_id === 'HF' ? "#ffff" : feature.flood_id === 'MF' ? "#ffff" : "#00000",
        })).concat({
          y: Number(((barangay_info.person - totalPopFlood) / barangay_info.person)  * 100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2,}),
          label: "Safe",
          color: "#4CBB17",
          indexLabelFontColor: "#ffff",
        }),
      }]

    }

    

    return (
        
            <div className='row'>
                  <div className='col-md-4'>
                    <div className="well padding-10"> 
                      <CanvasJSChart options = {householdFloodInfo}
                     
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                  <div className="well padding-10"> 
                      <CanvasJSChart options = {famFloodInfo}
                        
                      />
                    </div>                 
                  </div>
                  <div className='col-md-4'>
                  <div className="well padding-10"> 
                      <CanvasJSChart options = {popFloodInfo}
                        
                      />
                    </div>                
                  </div>

            </div>
        
    )
}
