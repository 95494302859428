import axios from 'axios'
import {
    ROOF_FAIL,
    ROOF_REQUEST,
    ROOF_SUCCESS,

    WALL_FAIL,
    WALL_REQUEST,
    WALL_RESET,
    WALL_SUCCESS
} from '../constant/libraryConstants'


export const roofMaterials = () => async (dispatch) => {
    try{
        dispatch({type:ROOF_REQUEST})

        const {data} = await axios.get('/api/library/materials/roof/')

        dispatch({
            type:ROOF_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:ROOF_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const wallMaterials = () => async (dispatch) => {
    try{
        dispatch({type:WALL_REQUEST})

        const {data} = await axios.get('/api/library/materials/wall/')

        dispatch({
            type:WALL_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:WALL_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}