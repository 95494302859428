import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'
import {floodWallRoofMaterial} from '../actions/purokActions'
import {puroklistBarangay} from '../actions/barangayActions'
import {roofMaterials,wallMaterials} from '../actions/libraryActions'


import CanvasJSReact from '@canvasjs/react-charts';
import { Col, Row } from 'react-bootstrap'
//var CanvasJSReact = require('@canvasjs/react-charts');
 
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function WallRoofBrgyGraph(props) {
    const dispatch = useDispatch()
    const [floodInfo,setFloodInfo] = useState(null)

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const roofinfo = useSelector(state => state.roofReducers)
    const {success:successRoof,roof} = roofinfo

    const wallinfo = useSelector(state => state.wallReducers)
    const {success:successWall,wall} = wallinfo

    const purokinfo = useSelector(state => state.barangayPurokListReducers)
    const {success:successpurokinfo,purok} = purokinfo

    const floodreports = useSelector(state => state.floodWallRoofReducers)
    const {wallroof,success:successfloodreports} = floodreports

    useEffect(() => {
      if (props.brgyId && successbrgyinfo) { 
        dispatch(roofMaterials())
        dispatch(wallMaterials())
        dispatch(floodWallRoofMaterial(props.brgyId));
        dispatch(puroklistBarangay(props.brgyId))
      }
    }, [dispatch, props.brgyId, successbrgyinfo]); 

    
    useEffect(() => {
      if (successfloodreports) {
        setFloodInfo(wallroof);
      }
    }, [successfloodreports, wallroof]); 
    const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === props.brgyId)

    const [chart, setChart] = useState(null);

    const toggleDataSeries = (e) => {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        chart.render();
      };
      const options = {
        animationEnabled: true,
        colorSet: "colorSet5",
        exportFileName: "Wall Materials",
        exportEnabled: true,
        title: {
          text: "Wall Materials",
        },
        axisX: {
                indexLabel:"{x}",
                labelFontSize: 8
        },
        axisY: {
                indexLabel:"{y}",
                labelFontSize: 8
        },
        toolTip: {
          shared: true,
        },
        legend: {
          cursor: "pointer",
          itemclick: toggleDataSeries,
          verticalAlign: "top",
        },
        data: [
          {
            type: "column",
            name: "Low",
            color: "#f5dcf5",
            showInLegend: true,
            dataPoints: wall && wall.map((wall) => (
                
                { label: wall.id === 1 ? "Storng" : wall.id === 2 ? "Light ": wall.id === 3 ? "Salvaged" : wall.id === 4 ? " Mixed but predominantly  Strong" : wall.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged" , 
                y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'LF' && flood.householdroofmaterials_id === wall.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0
            }))

          },
          {
            type: "column",
            color: "#783cc8",
            name: "Moderate",
            showInLegend: true,
            dataPoints: wall && wall.map((wall) => (
                
                { label:wall.id === 1 ? "Storng" : wall.id === 2 ? "Light ": wall.id === 3 ? "Salvaged" : wall.id === 4 ? " Mixed but predominantly  Strong" : wall.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged",y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'MF' && flood.householdroofmaterials_id === wall.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}))

          },
          {
            type: "column",
            color: "#3c46c8",
            name: "High",
            showInLegend: true,
            dataPoints: wall && wall.map((wall) => (
                
                { label: wall.id === 1 ? "Storng" : wall.id === 2 ? "Light ": wall.id === 3 ? "Salvaged" : wall.id === 4 ? " Mixed but predominantly  Strong" : wall.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged",y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'HF' && flood.householdroofmaterials_id === wall.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}))

          },
          {
            type: "column",
            color: "#28286e",
            name: "Very High",
            showInLegend: true,
            dataPoints: wall && wall.map((wall) => (
                
                { label: wall.id === 1 ? "Storng" : wall.id === 2 ? "Light ": wall.id === 3 ? "Salvaged" : wall.id === 4 ? " Mixed but predominantly  Strong" : wall.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged",y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'VHF' && flood.householdroofmaterials_id === wall.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}))

          }
          
        ],
      };

      const options2 = {
        animationEnabled: true,
        exportFileName: "Roof Materials",
        exportEnabled: true,
        colorSet: "colorSet5",
        title: {
          text: "Roof Materials",
        },
        axisX: {
                indexLabel:"{x}",
                labelFontSize: 8
        },
        axisY: {
                indexLabel:"{y}",
                labelFontSize: 8
        },
        toolTip: {
          shared: true,
        },
        legend: {
          cursor: "pointer",
          itemclick: toggleDataSeries,
          verticalAlign: "top",
        },
        data: [
          {
            type: "column",
            name: "Low",
            color: "#f5dcf5",
            showInLegend: true,
            dataPoints: roof && roof.map((roof) => (
                
                { label: roof.id === 1 ? "Storng" : roof.id === 2 ? "Light ": roof.id === 3 ? "Salvaged" : roof.id === 4 ? " Mixed but predominantly  Strong" : roof.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged" , 
                y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'LF' && flood.householdroofmaterials_id === roof.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0
            }))

          },
          {
            type: "column",
            color: "#783cc8",
            name: "Moderate",
            showInLegend: true,
            dataPoints: roof && roof.map((rool) => (
                
                { label:roof.id === 1 ? "Storng" : roof.id === 2 ? "Light ": roof.id === 3 ? "Salvaged" : roof.id === 4 ? " Mixed but predominantly  Strong" : roof.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged",y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'MF' && flood.householdroofmaterials_id === roof.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}))

          },
          {
            type: "column",
            color: "#3c46c8",
            name: "High",
            showInLegend: true,
            dataPoints: roof && roof.map((roof) => (
                
                { label: roof.id === 1 ? "Storng" : roof.id === 2 ? "Light ": roof.id === 3 ? "Salvaged" : roof.id === 4 ? " Mixed but predominantly  Strong" : roof.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged",y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'HF' && flood.householdroofmaterials_id === roof.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}))

          },
          {
            type: "column",
            color: "#28286e",
            name: "Very High",
            showInLegend: true,
            dataPoints: roof && roof.map((roof) => (
                
                { label: roof.id === 1 ? "Storng" : roof.id === 2 ? "Light ": roof.id === 3 ? "Salvaged" : roof.id === 4 ? " Mixed but predominantly  Strong" : roof.id === 5 ? "Mixed but predominantly  Light" : "Mixed but predominantly  Salvaged",y: floodInfo ? floodInfo.filter( flood => flood.flood_id === 'VHF' && flood.householdroofmaterials_id === roof.id ).reduce((sum, entry) => sum + (entry.roof_materials_count || 0), 0) : 0}))

          }
          
        ],
      };


               
    return (
        <Row>
            <Col>
            <CanvasJSChart options={options} onRef={(ref) => setChart(ref)} />
            </Col>
            <Col>
            <CanvasJSChart options={options2} onRef={(ref) => setChart(ref)} />
            </Col>

        </Row>
    )
}
