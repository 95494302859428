import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'

import { getRilReportPerMunicipality} from '../actions/reportActions'

export default function SocEcoAffRilMunTable(props) {
    
    const dispatch = useDispatch()
    const [rilInfo,setRilInfo] = useState(null)

    useEffect(()=>{
      
      if(props.municipalId){
         
          dispatch(getRilReportPerMunicipality())
      }
      
    },[props.municipalId,dispatch])

    const rilReportR = useSelector(state => state.rilReportPerMunicipalityReducer)
    const {RilReportPerMunicipality,success:successrilReportPerMunicipality} = rilReportR

    useEffect(()=>{
      if(successrilReportPerMunicipality){
        setRilInfo(RilReportPerMunicipality)
       }
    },[successrilReportPerMunicipality,RilReportPerMunicipality])

    const municipalagg = useSelector(state => state.municipalAggReducers)
    const {municipalsagg,success:successmunAgg,loading:loadingMunAgg} = municipalagg

    const rilMunReport = Array.isArray(rilInfo) && rilInfo.filter((feature) => feature.muncode === props.municipalId)
    
    const municipalsaggs = municipalsagg.find((feature) => feature.psgccode === props.municipalId)
    const tableRef = useRef(null);

    const exportToCSV = () => {
      if (!tableRef.current) return;
      
      const title = ['Buenavista Flood Susciptibility ']; 
      const headerRow = Array.from(tableRef.current.querySelectorAll('thead tr th')).map(cell => cell.innerText);
      const rows = Array.from(tableRef.current.querySelectorAll('tbody tr'));
      const data = rows.map(row => Array.from(row.children).map(cell => {
          let cellText = cell.innerText;
          // Wrap in quotes if the text contains a comma or a quote
          if (cellText.includes(',') || cellText.includes(' ')) {
              cellText = `"${cellText.replace(/"/g, ' ')}"`;
          }
          return cellText;
      }));
  
      const csvArray = [title,headerRow, ...data];
  
      // Convert CSV data array to a string with BOM character
      const csv = '\uFEFF' + csvArray.map(row => row.join(',')).join('\n');
  
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'exported_data.csv';
      link.click();
  };

    return (
            <div className="table-responsive">
              <span className="txt-color-purple pull-right"> <a href="#" onClick={exportToCSV}><i className="fa fa-download"></i> Export CSV</a></span>     
              <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Classification</th>
                          <th>Low</th>
                          <th>Moderate</th>
                          <th>High</th>
                          <th>Very High</th>
                          <th>Safe</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>                      
                          <td>Population</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.person).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.person).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.person).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.person).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.person - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.person).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Households</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.households).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.households).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.households).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.households).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.households - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.households || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.households).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Families</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.families).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.families).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.families).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.families).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.families - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.families || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ?  Number(municipalsaggs.families).toLocaleString() : null}</td>
                        </tr> 
                        <tr>
                          <td>Male</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.male).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.male).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.male).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.male).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.male - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.male || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ?  Number(municipalsaggs.male).toLocaleString() : null}</td>
                        </tr>  
                        <tr>
                          <td>Female</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.female).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.female - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ?  Number(municipalsaggs.female).toLocaleString() : null}</td>
                        </tr>                        
                        <tr>
                          <td>Elderly</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.elderly_male + ril.elderly_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.elderly_male + ril.elderly_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.elderly_male + ril.elderly_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.elderly_male + ril.elderly_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.elderly) - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.elderly_male || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.elderly).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Children</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.children_male + ril.children_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.children) - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.children_male || entry.children_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.children).toLocaleString(): null}</td>
                        </tr>
                        <tr>
                          <td>Adult</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.adult_male + ril.adult_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.adult) - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.adult_male || entry.adult_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.adult).toLocaleString(): null}</td>
                        </tr>    
                        <tr>
                          <td>Infant</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.infant_male + ril.infant_female).toLocaleString() : null})}</td>
                          <td>{Number((municipalsaggs.infant) - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.infant_male || entry.infant_female || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{municipalsaggs ? Number(municipalsaggs.infant).toLocaleString(): null}</td>
                        </tr>
                        <tr>
                          <td>Person With Disabilities</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.pwd).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.pwd).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.pwd).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.pwd).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.pwd - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.pwd || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ? Number(municipalsaggs.pwd).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Indigenous People</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.ip).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.ip).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.ip).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.ip).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.ip - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.ip || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.ip).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Malnourished</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.malnurished).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.malnurished).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.malnurished).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.malnurished).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.malnurished - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.malnurished || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ? Number(municipalsaggs.malnurished).toLocaleString() : null}</td>
                        </tr>                
                        <tr>
                          <td>HH With Illness</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_with_illness).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_with_illness - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_with_illness || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_with_illness).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Person With Philhealth</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.philhealth).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.philhealth - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.philhealth || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.philhealth).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Person With Financial Assistance</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.sss_gsis).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.sss_gsis - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.sss_gsis || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.sss_gsis).toLocaleString() : null}</td>
                        </tr>                      
                        <tr>
                          <td>HH With Paticipation in any Drill</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_access_drill).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_access_drill - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_access_drill || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_access_drill).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>Student</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hd_attending_school).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hd_attending_school - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hd_attending_school || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hd_attending_school).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Potable Water</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.potable_water).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.potable_water - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.potable_water || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.potable_water).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Electricity</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_access_electricity).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_access_electricity - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_access_electricity || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_access_electricity).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>HH With Access of Internet</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_access_internet).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_access_internet - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_access_internet || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_access_internet).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Construct Before 1972</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_1972).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_1972 - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_1972 || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_1972).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Construct After 1972</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_1972_onwards).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_1972_onwards - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_1972_onwards || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_1972_onwards).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Susceptibility</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_susc_ril).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_house - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_susc_ril || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_house).toLocaleString() : null}</td>
                        </tr>
                        <tr>
                          <td>House Susceptibility Estimated Cost</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "LL" ? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "ML" ? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "HL" ? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{rilMunReport && rilMunReport.map((ril) =>{return ril.ril_id === "VHL"? Number(ril.hh_susc_ril_cost).toLocaleString() : null})}</td>
                          <td>{Number(municipalsaggs.hh_house_cost - (Array.isArray(rilMunReport) ? rilMunReport.reduce((sum, entry) => sum + (entry.hh_house_cost || 0), 0) : 0)).toLocaleString()}</td>
                          <td>{ municipalsaggs ?  Number(municipalsaggs.hh_house_cost).toLocaleString() : null}</td>
                        </tr>             
                      </tbody>
                    </table>
          </div>
    )
}
