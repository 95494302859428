import React ,{useState,useEffect } from 'react'
import { Link , useLocation} from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch,useSelector} from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'

import { register } from '../actions/userActions'

function RegisterScreen({history}) {

    
   
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const [message,setMessage] = useState('')

    const dispatch = useDispatch()
    const location = useLocation();
    const { search } =location;
  
    // const redirect = search ? Number(search.split("=")[1]) : '/';
    const redirect = search ? search.split('=')[1] : '/municipal'; // Using the query parameter value as the redirect path

    const userRegister = useSelector(state => state.userRegister)
    const {error, loading, userInfo} = userRegister



    useEffect(() => {
        if(userInfo){
            history.push(redirect)
        }
      }, [history, userInfo, redirect]) 
  

    const submiHandler = (e) => {
        e.preventDefault()

        if(password !== confirmPassword)
        {
            setMessage('Passwords do not match')
        }else{
            dispatch(register(name,email,username, password))
        }
        

    }


  return (
     <FormContainer>
                <h1>Sign In</h1>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submiHandler}>

        <Form.Group controlId='username'>
                <Form.Label>Name</Form.Label>
                    <Form.Control
                        required
                        type='name'
                        placeholder='Enter Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    >   
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        required
                        type='email'
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >   
                    </Form.Control>
                </Form.Group>


                <Form.Group controlId='username'>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        required
                        type='username'
                        placeholder='Enter Username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    >   
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >   
                    </Form.Control>
                </Form.Group>


                <Form.Group controlId='confirmpassword'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >   
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    Register
                </Button>
        </Form>

        <Row className='py-3'>
            <Col>
                Have an Account? <Link
                    to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                    Sign In</Link>
            </Col>
      </Row>
      
    </FormContainer>
  )
}

export default RegisterScreen
