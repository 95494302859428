export const SENIOR_POINT_REQUEST = 'SENIOR_POINT_REQUEST'
export const SENIOR_POINT_SUCCESS = 'SENIOR_POINT_SUCCESS'
export const SENIOR_POINT_FAIL = 'SENIOR_POINT_FAIL'
export const SENIOR_POINT_RESET = 'SENIOR_POINT_RESET'

export const SENIOR_POINT_REQUEST_HF = 'SENIOR_POINT_REQUEST_HF'
export const SENIOR_POINT_SUCCESS_HF = 'SENIOR_POINT_SUCCESS_HF'
export const SENIOR_POINT_FAIL_HF = 'SENIOR_POINT_FAIL_HF'
export const SENIOR_POINT_RESET_HF = 'SENIOR_POINT_RESET'

export const SENIOR_POINT_REQUEST_LF = 'SENIOR_POINT_REQUEST_LF'
export const SENIOR_POINT_SUCCESS_LF = 'SENIOR_POINT_SUCCESS_LF'
export const SENIOR_POINT_FAIL_LF = 'SENIOR_POINT_FAIL_LF'
export const SENIOR_POINT_RESET_LF = 'SENIOR_POINT_RESET_LF'

export const SENIOR_POINT_REQUEST_MF = 'SENIOR_POINT_REQUEST_MF'
export const SENIOR_POINT_SUCCESS_MF= 'SENIOR_POINT_SUCCESS_MF'
export const SENIOR_POINT_FAIL_MF = 'SENIOR_POINT_FAIL_MF'
export const SENIOR_POINT_RESET_MF = 'SENIOR_POINT_RESET_MF'

export const CHILD_POINT_REQUEST = 'CHILD_POINT_REQUEST'
export const CHILD_POINT_SUCCESS = 'CHILD_POINT_SUCCESS'
export const CHILD_POINT_FAIL = 'CHILD_POINT_FAIL'
export const CHILD_POINT_RESET = 'CHILD_POINT_RESET'

export const TEENAGE_POINT_REQUEST = 'TEENAGE_POINT_REQUEST'
export const TEENAGE_POINT_SUCCESS = 'TEENAGE_POINT_SUCCESS'
export const TEENAGE_POINT_FAIL = 'TEENAGE_POINT_FAIL'
export const TEENAGE_POINT_RESET = 'TEENAGE_POINT_RESET'

export const BRIDGE_POINT_REQUEST = 'BRIDGE_POINT_REQUEST'
export const BRIDGE_POINT_SUCCESS = 'BRIDGE_POINT_SUCCESS'
export const BRIDGE_POINT_FAIL = 'BRIDGE_POINT_FAIL'
export const BRIDGE_POINT_RESET = 'BRIDGE_POINT_RESET'

export const EVACUTATION_CENTER_POINT_REQUEST = 'EVACUTATION_CENTER_POINT_REQUEST'
export const EVACUTATION_CENTER_POINT_SUCCESS = 'EVACUTATION_CENTER_POINT_SUCCESS'
export const EVACUTATION_CENTER_POINT_FAIL = 'EVACUTATION_CENTER_POINT_FAIL'
export const EVACUTATION_CENTER_POINT_RESET = 'EVACUTATION_CENTER_POINT_RESET'