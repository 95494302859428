import React , {useState,useEffect,useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../index.css"
import { Row, Col} from 'react-bootstrap'
import { MapContainer, TileLayer, Marker, GeoJSON, LayersControl, LayerGroup,  useMap } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import 'leaflet.browser.print/dist/leaflet.browser.print'
import {Icon,divIcon} from 'leaflet'

import { getBarangayHouseholds } from '../actions/householdActions'
import { listMunicipal } from '../actions/municipalActions'
import { listBarangay } from '../actions/barangayActions'
import { listPolygonBarangay,adnFlood,adnRil,adnStormSurge,adnMunFlood,adnMunRil,adnMunStormSurge } from '../actions/polygonActions'
import { listBrgyInfo ,getFloodReport,getRilReport,getStormSurgeReport} from '../actions/reportActions'
import { getFloodInfo  } from '../actions/floodActions'
import { getRilInfo  } from '../actions/rilActions'
import { getStormsurgeInfo  } from '../actions/stormsurgeActions'

import { BARANGAY_POLYGON_RESET,ADN_FLOOD_RESET,ADN_MUN_FLOOD_RESET,ADN_MUN_RIL_RESET,ADN_RIL_RESET,ADN_MUN_STORMSURGE_RESET,ADN_STORMSURGE_RESET } from '../constant/polygonConstants'
import barangaymap from '../shapefile/Barangay.json'
import Select from 'react-select'


import Loader from '../components/Loader'
import Message from '../components/Message'


const MapWithPrint = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    L.BrowserPrint.Utils.registerLayer(L.MarkerClusterGroup, 'L.MarkerClusterGroup', function (layer, utils) {
      var cluster = L.markerClusterGroup(layer._group.options);
      cluster.addLayers(utils.cloneInnerLayers(layer._group));
      return cluster;
  });

    const printControl = L.control.browserPrint({
      title: 'Print map',
      documentTitle: 'Map Export',
      position: 'topright',
      printModes: ['Portrait', 'Landscape', 'Auto', 'Custom'],
      manualMode: false,
      closePopupsOnPrint: false,
      printLayer: () => {
        const layers = L.layerGroup(map._layers);
        return layers;
      }
    });

    map.addControl(printControl);

    return () => {
      map.removeControl(printControl);
    };
  }, [map]);

  
  return null;
};



function MainMap() {

    const [brgyinformation, setBrgyInformation] = useState([])
    const [floodinfoVHF, setFloodInfoVHF] = useState([])
    const [floodinfoHF, setFloodInfoHF] = useState([])
    const [floodinfoMF, setFloodInfoMF] = useState([])
    const [floodinfoLF, setFloodInfoLF] = useState([])
    const [rilinfoVHL, setRilInfoVHL] = useState([])
    const [rilinfoHL, setRilInfoHL] = useState([])
    const [rilinfoML, setRilInfoML] = useState([])
    const [rilinfoLL, setRilInfoLL] = useState([])
    const [stormsurgeinfo3, setStormsurgeInfo3] = useState([])
    const [stormsurgeinfo2, setStormsurgeInfo2] = useState([])
    const [stormsurgeinfo1, setStormsurgeInfo1] = useState([])
    const [floodrpt, setFloodrpt] = useState([])
    const [rilrpt, setRilrpt] = useState([])
    const [stormsurgerpt, setStormsurgerpt] = useState([])
    const [brgy, setBrgy] = useState([])
    const [brgyhouseholds, setBrgyHouseholds] = useState([])
    const [brgypolygons,setBrgyPolygons] = useState(null)
    const [adnfloods,setAdnFloods] = useState(null)
    const [adnrils,setAdnRils] = useState(null)
    const [adnstormsurges,setAdnStormsurges] = useState(null)
    const [adnmunfloods,setAdnMunFloods] = useState(null)
    const [adnmunrils,setAdnMunRils] = useState(null)
    const [adnmunstormsurges,setAdnMunStormsurges] = useState(null)
    const [selectedValue, setSelectedValue] = useState('')
    const [selectedHazard, setSelectedHazard] = useState('')
    const [selectedMun, setSelectedMun] = useState('')
    const [hazards, setHazards] = useState([])
    const [previousEvent, setPreviousEvent] = useState(null);

    const dispatch = useDispatch()
  
    const householdList = useSelector(state => state.householdListReducers)
    const {gethouseholds,success:successHouseholdlist} = householdList
  
    const municipalist = useSelector(state => state.municipalListReducers)
    const {municipals,loading,error} = municipalist
  
    const barangaylist = useSelector(state => state.barangayListReducers)
    const {barangays} = barangaylist

    const barangayinfo = useSelector(state => state.brgyInfoReducer)
    const {success:successbrgyinfo,brgyinfo} = barangayinfo
  
    const barangayhouseholdslist = useSelector(state => state.barangayHouseholdsReducer)
    const {success:successbrgy, barangayhouseholds} = barangayhouseholdslist
   
    const barangaypolygon = useSelector(state => state.barangayPolygonReducers)
    const {barangaypolygons,success:successbarangaypolygon} = barangaypolygon

    const adnflo = useSelector(state => state.adnFloodReducers)
    const {adnflood,success:successadnflood} = adnflo

    const adnrilp = useSelector(state => state.adnRilReducers)
    const {adnril,success:successadnrilp} = adnrilp

    const adnstormsurgep = useSelector(state => state.adnStormSurgeReducers)
    const {adnstormsurge,success:successadnstormsurgep} = adnstormsurgep

    const adnmunflo = useSelector(state => state.adnMunFloodReducers)
    const {adnmunflood,success:successadnmunflood} = adnmunflo

    const adnmunrilp = useSelector(state => state.adnMunRilReducers)
    const {adnmunril,success:successadnmunrilp} = adnmunrilp

    const adnmunstormsurgep = useSelector(state => state.adnMunStormSurgeReducers)
    const {adnmunstormsurge,success:successadnmunstormsurgep} = adnmunstormsurgep

    const floodinform = useSelector(state => state.floodInfoReducer)
    const {floodinfo,success:successfloodinfo} = floodinform

    const floodreports = useSelector(state => state.floodReportReducer)
    const {floodReport,success:successfloodreports} = floodreports

    const rilinform = useSelector(state => state.rilInfoReducer)
    const {rilinfo,success:successrilinfo} = rilinform

    const rilreports = useSelector(state => state.rilReportReducer)
    const {rilReport,success:successrilreports} = rilreports

    const stormsurgeinform = useSelector(state => state.stormsurgeInfoReducer)
    const {stormsurgeinfo,success:successstormsurgeinfo} = stormsurgeinform

    const stormsurgereports = useSelector(state => state.stormsurgeReportReducer)
    const {stormsurgeReport,success:successstormsurgreports} = stormsurgereports
  
    useEffect(() => {
      dispatch(listMunicipal())
      dispatch(listBarangay())
    },[dispatch])   
    
    useEffect(() => {
      // if(successHouseholdlist){
      //   setBrgyHouseholds(gethouseholds)   
      // }

      if(selectedMun){
        dispatch({type:ADN_MUN_FLOOD_RESET})
        dispatch({type:ADN_MUN_RIL_RESET})
        dispatch({type:ADN_MUN_STORMSURGE_RESET})
        dispatch(adnMunFlood(selectedMun))
        dispatch(adnMunRil(selectedMun))
        dispatch(adnMunStormSurge(selectedMun))
      }

      if(selectedValue) {
        dispatch({type:BARANGAY_POLYGON_RESET})    
        dispatch(getBarangayHouseholds(selectedValue))
        dispatch(listPolygonBarangay(selectedValue))
        dispatch(listBrgyInfo(selectedValue))
        dispatch(adnFlood(selectedValue))
        dispatch(adnRil(selectedValue))
        dispatch(adnStormSurge(selectedValue))
        dispatch(getFloodInfo(selectedValue))
        dispatch(getRilInfo(selectedValue))
        dispatch(getStormsurgeInfo(selectedValue))
        dispatch(getFloodReport(selectedValue))
        dispatch(getRilReport(selectedValue))
        dispatch(getStormSurgeReport(selectedValue))      
        setHazards(optionsHazard)
      }

     
      
  
    },[successHouseholdlist,selectedValue,dispatch,gethouseholds])   

    useEffect(() => {
  
      async function fetchHouseholdData() {
        try {
          
          if(successadnflood){
            setAdnFloods(adnflood)
          }

          if(successadnrilp){
            setAdnRils(adnril)
          }
          if(successadnstormsurgep){
            setAdnStormsurges(adnstormsurge)
          }

          if(successadnmunflood){
            setAdnMunFloods(adnmunflood)
          }

          if(successadnmunrilp){
            setAdnMunRils(adnmunril)
          }
          if(successadnmunstormsurgep){
            setAdnMunStormsurges(adnmunstormsurge)
          }

          if(successbarangaypolygon){
            setBrgyPolygons(barangaypolygons)
            
          }
          
          if(successbrgy){
            setBrgyHouseholds(barangayhouseholds)
          }
          if(successfloodreports){
              setFloodrpt(floodReport)
              
          }
          if(successrilreports){
            setRilrpt(rilReport)
            
            }

            if(successstormsurgreports){
              
              setStormsurgerpt(stormsurgeReport)
              
              }
          
          if(successbrgyinfo){
            setBrgyInformation(brgyinfo[0])
          }
          
        } catch (error) {
          console.error("Error fetching household data:", error);
        }
      }

      if(selectedHazard != null)
      {
        fetchHouseholdData()
      }
     
  
    })

  
    const optionsMun = municipals.map((municipal) => ({
      label: municipal.munname, // Assuming 'name' is the name of the municipal
      value: municipal.psgccode,   // Assuming 'id' is the ID of the municipal
    }));
  
    const changeMunicipal = (event) => {
      dispatch({type:BARANGAY_POLYGON_RESET})   
      dispatch({type:ADN_FLOOD_RESET})
      dispatch({type:ADN_RIL_RESET})
      dispatch({type:ADN_STORMSURGE_RESET})
      dispatch({type:ADN_MUN_FLOOD_RESET})
      dispatch({type:ADN_MUN_RIL_RESET})
      dispatch({type:ADN_MUN_STORMSURGE_RESET})
        setBrgy([]) 
        setHazards([])
        setBrgyPolygons(null);
        setAdnFloods(null);
        setAdnRils(null);
        setAdnStormsurges(null);
        setAdnMunFloods(null);
        setAdnMunRils(null);
        setAdnMunStormsurges(null);

        getFloodInfo([]);
        setFloodInfoHF([]);
        setFloodInfoVHF([]);
        setFloodInfoMF([]);
        setFloodInfoLF([]);

        getRilInfo([]);
        setRilInfoHL([]);
        setRilInfoVHL([]);
        setRilInfoML([]);
        setRilInfoLL([]);

        getStormsurgeInfo([]);
        setStormsurgeInfo3([]);
        setStormsurgeInfo2([]);
        setStormsurgeInfo1([]);
        setBrgy(barangays.filter(barangay => barangay.psgcmun === event.value)) 
        setSelectedMun(event.value) 

        if(selectedValue != null ){
          setSelectedValue('');
            document.getElementById('soc').style.display = 'none';
  
        }
        
    }
  
    const optionsBrgy = brgy.map((b) => ({
      label: b.brgyname, 
      value: b.psgccode,  
    }));   
   
    const changeBrgy = (event) => {
      
      if (!event || (previousEvent && event.value === previousEvent.value)) {
        // If event is not provided, setHazards to optionsHazard
        setHazards(optionsHazard);
        return;
      }else{
      setHazards([])
      setSelectedValue(event.value)
      setBrgyPolygons(null);
      setAdnFloods(null);
      setAdnRils(null);
      setAdnStormsurges(null);
      getFloodInfo([]);
      setFloodInfoHF([]);
      setFloodInfoVHF([]);
      setFloodInfoMF([]);
      setFloodInfoLF([]);

      getRilInfo([]);
      setRilInfoHL([]);
      setRilInfoVHL([]);
      setRilInfoML([]);
      setRilInfoLL([]);

      getStormsurgeInfo([]);
      setStormsurgeInfo3([]);
      setStormsurgeInfo2([]);
      setStormsurgeInfo1([]);

      setSelectedHazard('');
      }
      setPreviousEvent(event);
      if(event.value != null ){
        document.getElementById('soc').style.display = 'block';
      }
      
      
    }

    const optionsHazard = [
      {label:"FLOOD",value:"1"},
      {label:"RAIN-INDUCED LANDSLIDE",value:"2"},
      {label:"STORM SURGE",value:"3"},
    ]


    const changeHazard = (event) => {
      setSelectedHazard(event.value)
      if(event.value == 1)
      {
        if(successfloodinfo)
        {
          setFloodInfoVHF(floodinfo.filter(info => info.flood_id === "VHF"))
          setFloodInfoHF(floodinfo.filter(info => info.flood_id === "HF"))
          setFloodInfoMF(floodinfo.filter(info => info.flood_id === "MF"))
          setFloodInfoLF(floodinfo.filter(info => info.flood_id === "LF"))
        }
        
      }
      if(event.value == 2)
      {
        if(successrilinfo)
        {
          setRilInfoVHL(rilinfo.filter(info => info.ril_id === "VHL"))
          setRilInfoHL(rilinfo.filter(info => info.ril_id === "HL"))
          setRilInfoML(rilinfo.filter(info => info.ril_id === "ML"))
          setRilInfoLL(rilinfo.filter(info => info.ril_id === "LL"))
        }
        
      }
      if(event.value == 3)
      {
        if(successrilinfo)
        {
          setStormsurgeInfo3(stormsurgeinfo.filter(info => info.stormsurge_id === 3))
          setStormsurgeInfo2(stormsurgeinfo.filter(info => info.stormsurge_id === 2))
          setStormsurgeInfo1(stormsurgeinfo.filter(info => info.stormsurge_id === 1))
        }
        
      }
      
    }

    const customIcon = new Icon({
      iconUrl: require("../img/icons8-green-circle-48.png"),
      iconSize: [10,10]
    })

    const mapRef = useRef(null);


    useEffect(() => {
      if (mapRef.current && brgyhouseholds.length > 0) {
        const bounds = L.latLngBounds(brgyhouseholds && brgyhouseholds.map((household) => [household.lat, household.long]));
        mapRef.current.fitBounds(bounds, { padding: [50, 50] });
      }
    }, [brgyhouseholds]);


    const setColor = ({ properties }) => {
      return { weight: 1 };
    };
  
    const customMarkerIcon = (name) =>
      divIcon({
        html: name,
        className: "icon"
      });

    const setIcon = ({ properties }, latlng) => {
      return L.marker(latlng, { icon: customMarkerIcon(properties.Name) });
    };

    const [hoveredFeature, setHoveredFeature] = useState(null);

    const onEachFeature = (feature, layer) => {
      // You can customize the tooltip content here
     // layer.bindTooltip();
      layer.on({
        mouseover: () => {
          if(feature.properties.Susc_Ratin === "HF" ){
            document.getElementById('HF').style.display = 'block';
          }
          else if(feature.properties.Susc_Ratin === "LF" )
          {
            document.getElementById('LF').style.display = 'block';
          }else if(feature.properties.Susc_Ratin === "MF" ){
            document.getElementById('MF').style.display = 'block';
          }else{
            document.getElementById('VHF').style.display = 'block';
          }
          
 
        },
        mouseout: () => {
          // Reset the style on mouseout
          if(feature.properties.Susc_Ratin === "HF" ){
            document.getElementById('HF').style.display = 'none';
          }
          else if(feature.properties.Susc_Ratin === "LF" )
          {
            document.getElementById('LF').style.display = 'none';
          }else if(feature.properties.Susc_Ratin === "MF" ){
            document.getElementById('MF').style.display = 'none';
          }else{
            document.getElementById('VHF').style.display = 'none';
          }
        },
      });
    };

    const [hoveredMarker, setHoveredMarker] = useState(null);


    const onEachFeatureRIL = (feature, layer) => {
      // You can customize the tooltip content here
     // layer.bindTooltip();
      layer.on({
        mouseover: () => {
         
          if(feature.properties.LndslideSu === "HL" ){
            document.getElementById('HL').style.display = 'block';
          }
          else if(feature.properties.LndslideSu === "LL" )
          {
            document.getElementById('LL').style.display = 'block';
          }else if(feature.properties.LndslideSu === "ML" ){
            document.getElementById('ML').style.display = 'block';
          }else if(feature.properties.LndslideSu === "VHL" ){
            document.getElementById('VHL').style.display = 'block';
          }else{
          
          }
          
 
        },
        mouseout: () => {
          // Reset the style on mouseout
          
          if(feature.properties.LndslideSu === "HL" ){
            document.getElementById('HL').style.display = 'none';
          }
          else if(feature.properties.LndslideSu === "LL" )
          {
            document.getElementById('LL').style.display = 'none';
          }else if(feature.properties.LndslideSu === "ML" ){
            document.getElementById('ML').style.display = 'none';
          }else if(feature.properties.LndslideSu === "VHL" ){
            document.getElementById('VHL').style.display = 'none';
          }else{
            document.getElementById('HL').style.display = 'none';
            document.getElementById('LL').style.display = 'none';
            document.getElementById('ML').style.display = 'none';
            document.getElementById('VHL').style.display = 'none';
          }
        },
      });
    };
    

    const onEachFeatureStormSurge = (feature, layer) => {
      // You can customize the tooltip content here
     // layer.bindTooltip();
      layer.on({
        mouseover: () => {
          if(feature.properties.HAZ === 1 ){
            document.getElementById('surge1').style.display = 'block';
          }
          else if(feature.properties.HAZ === 2 )
          {
            document.getElementById('surge2').style.display = 'block';
          }else if(feature.properties.HAZ === 3 ){
            document.getElementById('surge3').style.display = 'block';
          }else{
          
          }
          
 
        },
        mouseout: () => {
          // Reset the style on mouseout
          
            if(feature.properties.HAZ === 1 ){
              document.getElementById('surge1').style.display = 'none';
            }
            else if(feature.properties.HAZ === 2 )
            {
              document.getElementById('surge2').style.display = 'none';
            }else if(feature.properties.HAZ === 3 ){
              document.getElementById('surge3').style.display = 'none';
            }else{
              
            }
        }
      });
    };

    const handleMarkerHover = (markerId) => {
      // Handle marker hover event
      setHoveredMarker(markerId);
    };
  
   
    const handleMarkerLeave = () => {
      // Handle marker leave event
      setHoveredMarker(null);
    };


  return (
   <>
   
      <div style={{ position: 'relative'}} >
          <div  style={{ position: 'absolute', top: 10, left: "2%",zIndex:500,height:"100",width:"80%" }}>
            <form  className='text-dark'>
            
              <Row style={{ position: 'relative' }}>
              <fieldset style={{height:"auto",width:"50%"}}>
                <section className="col-12 col-md-6 col-lg-3">
                    <div className="form-group" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Select key={optionsMun} options={optionsMun} onChange={changeMunicipal} placeholder="Select Municipal" className="form-select-sm" />
                    </div>
                </section>
                <section className="col-12 col-md-6 col-lg-3">
                <div className="form-group" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  
                    <Select key={optionsBrgy} options={optionsBrgy} onChange={changeBrgy} placeholder="Select Barangay" className="form-select-sm"/>
                </div>
                </section>
                <section className="col-12 col-md-6 col-lg-3">
                <div className="form-group" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  
                    <Select key={hazards} options={hazards} onChange={changeHazard} placeholder="Select Hazard" className="form-select-sm"/>
                </div>
                </section>
                <Row style={{ position: 'relative' }}>
                  <ol className="breadcrumb float-lg" style={{ position: 'absolute' }}>
                    <li className="breadcrumb-item"><h6>{brgyinformation && brgyinformation.munname}</h6></li>
                    <li className="breadcrumb-item">{brgyinformation && brgyinformation.brgyname}</li>
                  </ol>
              </Row>
              </fieldset>
              <fieldset style={{height:"auto",width:"50%",display:"none" }} id="soc">
                <section className="col-12 col-md-6 col-lg-3">
              
                <div className="small-box bg-warning">           
                  <div className="inner">
                    <h5>{brgyinformation?.person && Number(brgyinformation.person).toLocaleString()}</h5>
                    <p>Population</p>
                  </div>
                  <div className="icon" >
                    <i className="ion ion-person-add" style={{ fontSize: "20px" }}/>
                  </div>
                </div>
                
                </section>
                <section className="col-12 col-md-6 col-lg-3">
                
              <div className="small-box bg-warning">
                  <div className="inner">
                    <h5>{brgyinformation?.households && Number(brgyinformation.households).toLocaleString()}</h5>
                    <p>Households</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-home" style={{ fontSize: "20px" }}/>
                  </div>
              </div>
            
                </section>
                <section className="col-12 col-md-6 col-lg-3">
              
                <div className="small-box bg-warning">
                  
                  <div className="inner">
                    <h5>{brgyinformation?.families && Number(brgyinformation.families).toLocaleString()}</h5>
                    <p>Families</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-ios-people" style={{ fontSize: "30px" }}/>
                  </div>
                </div>
              
                </section>
              </fieldset>
              </Row>
            
              
            </form>
          </div>    
          {loading ? <Loader/>
                  : error ? <Message variant='danger'>{error}</Message>
                      :
          <MapContainer center={[9.082335, 125.659677]} zoom={10} zoomControl={false} scrollWheelZoom={true}  ref={mapRef} id='mapreport' >

          <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"/>

        <LayersControl position="topright" collapsed={true}>
                <LayersControl.BaseLayer name="OSMap">
                    <TileLayer
                      url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                      
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="ESRI">
                    <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                      
                      maxZoom='20'
                    />

                 </LayersControl.BaseLayer>
             
             

                  {/* <LayersControl.Overlay  name="Houses" >
                    <LayerGroup> */}
                      {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                      {/* {brgyhouseholds && brgyhouseholds.map((household, index) => (
                        <Marker   
                          key={index}
                          position={[household.lat,household.long]} icon={customIcon}>
                        <Popup>
                                <Table responsive striped bordered hover size="sm">
                                <thead >
                                  <tr>
                                    <th colSpan={2}  style={{textAlign: 'center'}}>{household.barangay_name}</th>                               
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Purok</td>
                                    <td>{household.purok_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Families</td>
                                    <td>{household.families}</td>
                                  </tr>
                                  <tr>
                                    <td>Population</td>
                                    <td>{household.person}</td>
                                  </tr>
                                  <tr>
                                    <td>Male</td>
                                    <td>{household.male}</td>
                                  </tr>
                                  <tr>
                                    <td>Female</td>
                                    <td>{household.female}</td>
                                  </tr>
                                  <tr>
                                    <td>Infant(M/F)</td>
                                    <td>{household.infant_male}/{household.infant_female}</td>
                                  </tr>
                                  <tr>
                                    <td>Elderly(M/F)</td>
                                    <td>{household.elderly_male}/{household.elderly_female}</td>
                                  </tr>
                                  <tr>
                                    <td>Illiteracy</td>
                                    <td>{household.illiteracy}</td>
                                  </tr>
                                  <tr>
                                    <td>IP</td>
                                    <td>{household.ip}</td>
                                  </tr>
                                  <tr>
                                    <td>PWD</td>
                                    <td>{household.pwd}</td>
                                  </tr>
                                  <tr>
                                    <td>Malnourished</td>
                                    <td>{household.malnurished}</td>
                                  </tr>
                                </tbody>
                              </Table>
                                </Popup>
                        </Marker>
                      ) )}                    */}
                {/* </MarkerClusterGroup> */}
                    {/* </LayerGroup>
                  </LayersControl.Overlay> */}
                  
                
                  <LayersControl.Overlay name="Barangay Boundary"  >
                      <GeoJSON data={barangaymap} style={setColor} key={barangaymap.BrgyPSGC} pointToLayer={setIcon}/>    
                  </LayersControl.Overlay>
                  
                  {selectedHazard == 1 && (
                      <>
                        <LayersControl.Overlay checked  name="Very High Flood">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {floodinfoVHF && floodinfoVHF.map((household, index) => (
                              <Marker   
                                key={index}
                                position={[household.lat,household.long]} 
                                icon={customIcon}
                                eventHandlers={{
                                  mouseover: () => handleMarkerHover(household.household_number),
                                  mouseout: handleMarkerLeave,
                                }}
                                >  
                                
                                 {hoveredMarker === household.household_number && (
                                  <div className="jarviswidget" id="MVHF" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                    
                                <header role="heading" >
                                             <h2 className="text-sm font-weight-bold" >{household.barangay_name} - {household.purok_name}</h2>  
                                </header>
                                <div role="content">
                                                <div className="widget-body">
                                                <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                     {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column" >
                                                    
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant(M/F)
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly(M/F)
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                     </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column">
                                                      
                                                        <li className="nav-item text-sm" >
                                                        {household.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.infant_male}/{household.infant_female}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.elderly_male}/{household.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.malnurished}
                                                      </li>
                                                       
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div> 
                                                </div>
                                </div>
                                </div>
                                )}
                                                       
                                
                              </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                        </LayersControl.Overlay>

                        <LayersControl.Overlay checked   name="High Flood">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {floodinfoHF && floodinfoHF.map((household, index) => (
                              <Marker   
                              key={index}
                              position={[household.lat,household.long]} 
                              icon={customIcon}
                              eventHandlers={{
                                mouseover: () => handleMarkerHover(household.household_number),
                                mouseout: handleMarkerLeave,
                              }}
                              >  
                              
                               {hoveredMarker === household.household_number && (
                                <div className="jarviswidget" id="MHF" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                  
                              <header role="heading">
                                              <h2 className="text-sm font-weight-bold" >{household.barangay_name}- {household.purok_name}</h2>				
                              </header>
                              <div role="content">
                                              <div className="widget-body">
                                              <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                   {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column" >
                                                  
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant(M/F)
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly(M/F)
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                   </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column">
                                                   
                                                      <li className="nav-item text-sm" >
                                                      {household.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.infant_male}/{household.infant_female}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.elderly_male}/{household.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.malnurished}
                                                    </li>
                                                     
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div> 
                                              </div>
                              </div>
                              </div>
                              )}
                                                     
                              
                            </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                        </LayersControl.Overlay>
                        
                        <LayersControl.Overlay checked  name="Moderate Flood">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {floodinfoMF && floodinfoMF.map((household, index) => (
                              <Marker   
                              key={index}
                              position={[household.lat,household.long]} 
                              icon={customIcon}
                              eventHandlers={{
                                mouseover: () => handleMarkerHover(household.household_number),
                                mouseout: handleMarkerLeave,
                              }}
                              >  
                              
                               {hoveredMarker === household.household_number && (
                                <div className="jarviswidget" id="MF" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                  
                              <header role="heading">
                                              <h2 className="text-sm font-weight-bold" >{household.barangay_name}- {household.purok_name}</h2>				
                              </header>
                              <div role="content">
                                              <div className="widget-body">
                                              <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                   {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column" >
                                                   
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant(M/F)
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly(M/F)
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                   </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column">
                                                    
                                                      <li className="nav-item text-sm" >
                                                      {household.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.infant_male}/{household.infant_female}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.elderly_male}/{household.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.malnurished}
                                                    </li>
                                                     
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div> 
                                              </div>
                              </div>
                              </div>
                              )}
                                                     
                              
                            </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                        </LayersControl.Overlay>
                        
                        <LayersControl.Overlay checked   name="Low Flood">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {floodinfoLF && floodinfoLF.map((household, index) => (
                              <Marker   
                              key={index}
                              position={[household.lat,household.long]} 
                              icon={customIcon}
                              eventHandlers={{
                                mouseover: () => handleMarkerHover(household.household_number),
                                mouseout: handleMarkerLeave,
                              }}
                              >  
                              
                               {hoveredMarker === household.household_number && (
                                <div className="jarviswidget" id="LF" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                  
                              <header role="heading">
                                              <h2 className="text-sm font-weight-bold" >{household.barangay_name}- {household.purok_name}</h2>				
                              </header>
                              <div role="content">
                                              <div className="widget-body">
                                              <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                   {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column" >
                                                    
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant(M/F)
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly(M/F)
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                   </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column">
                                                    
                                                      <li className="nav-item text-sm" >
                                                      {household.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.infant_male}/{household.infant_female}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.elderly_male}/{household.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.malnurished}
                                                    </li>
                                                     
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div> 
                                              </div>
                              </div>
                              </div>
                              )}
                                                     
                              
                            </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                        </LayersControl.Overlay>

                      <LayerGroup>
                          {adnmunfloods && adnmunfloods.map((feature, index) => (
                      
                              <GeoJSON      
                                key={index}                   
                                data={feature}
                                color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                                weight={1}
                                opacity={1}
                                fillOpacity={0.2}
                                dashArray={3}
                                // key={feature.properties.Susc_Ratin}                               
                              >          
                                </GeoJSON>
                            ))}
                       </LayerGroup>

                        <LayerGroup>
                          {adnfloods && adnfloods.map((feature, index) => (
                      
                              <GeoJSON
                                 key={index} 
                                data={feature}
                                color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                                weight={2}
                                opacity={1}
                                fillOpacity={0.8}
                                dashArray={3}
                               // key={feature.properties.Susc_Ratin}  
                                onEachFeature={onEachFeature}
                              >
                                
                                {feature.properties.Susc_Ratin === "HF" 
                                      ?   
                                                        
                                        <div className="jarviswidget" id="HF" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute'   }}>
                                          <header role="heading">
                                                <h2 className="text-sm font-weight-bold" >Flood-Induced High</h2>				
                                                </header>
                                                <div role="content">
          
                                                <div className="widget-body">
                                                
                                          {floodrpt && floodrpt.map((report) => (
                                            report.flood_id === "HF" && 
                                                (
                                                  <div key={report.flood_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                    <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                     {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={5} md={9}>
                                                      <ul className="nav nav-pills flex-column" >
                                                      <li className="nav-item text-sm" >
                                                        Household
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant-Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant-Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Elderly-Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly-Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                      </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={2} md={3}>
                                                      <ul className="nav nav-pills flex-column">
                                                      <li className="nav-item text-sm" >
                                                      {report.households}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.infant_male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.infant_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.elderly_male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.malnurished}
                                                      </li>
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div>                                
                                                )
                                          ))
                                          }  
                                          </div>
                                          </div>                         
                                        </div>
                
                                  : feature.properties.Susc_Ratin === "LF" 
                                      ? 
                                          <div className="jarviswidget"  id="LF" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute' }}>
                                            <header role="heading">                             
                                                <h2 className="text-sm font-weight-bold" >Flood-Induced Low</h2>				
                                                </header>
                                                <div role="content">
                                                <div className="widget-body">
                                            {floodrpt && floodrpt.map((report) => (
                                              report.flood_id === "LF" && 
                                                  (
                                                    <div key={report.flood_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                      <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                      {/* <Row notters> */}
                                                      <Row >
                                                        <Col xs={5} md={9}>
                                                        <ul className="nav nav-pills flex-column" >
                                                        <li className="nav-item text-sm" >
                                                          Household
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Families
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Population
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Infant-Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant-Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Elderly-Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Elderly-Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Illiteracy
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        IP
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          PWD
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Malnourished
                                                        </li>
                                                      </ul>
                                                        </Col>
                                                        <Col xs={2} md={2}>
                                                        <ul className="nav nav-pills flex-column">
                                                        <li className="nav-item text-sm" >
                                                        {report.households}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.families}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.person}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.infant_male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.infant_female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.elderly_male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.elderly_female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.illiteracy}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.ip}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.pwd}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.malnurished}
                                                        </li>
                                                      </ul>
                                                        </Col>
                                                      </Row>
                                                      
                                                    </div>                                
                                                  )
                                            ))
                                            }                
                                              </div>
                                          </div>         
                                        </div>
                                      
                                  : feature.properties.Susc_Ratin === "MF" 
                                    ? 
                                    
                                      <div  className="jarviswidget"  id="MF" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute'}}>
                                            <header role="heading">
                                                <h2 className="text-sm font-weight-bold" >Flood-Induced Moderate</h2>				
                                                </header>
                                                <div role="content">                                 
                                                <div className="widget-body">

                                        {floodrpt && floodrpt.map((report) => (
                                          report.flood_id === "MF" && 
                                              (
                                                <div key={report.flood_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                  <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                  {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={5} md={9}>
                                                    <ul className="nav nav-pills flex-column" >
                                                    <li className="nav-item text-sm" >
                                                      Household
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Infant-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={2} md={2}>
                                                    <ul className="nav nav-pills flex-column">
                                                    <li className="nav-item text-sm" >
                                                    {report.households}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.infant_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.infant_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.elderly_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.malnurished}
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div>                                
                                              )
                                        ))
                                        }                
                                                    
                                      </div>
                                    </div>
                                    </div>
                                  : 
                                  
                                      <div  className="jarviswidget"  id="VHF" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute' }}>
                                      <header role="heading">

                                                <h2 className="text-sm font-weight-bold" >Flood-Induced Very High</h2>				

                                                </header>
                                                <div role="content">
                                                <div className="widget-body">
                                        {floodrpt && floodrpt.map((report) => (
                                          report.flood_id === "VHF" && 
                                              (
                                                <div key={report.flood_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                  <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                  {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={5} md={9}>
                                                    <ul className="nav nav-pills flex-column" >
                                                    <li className="nav-item text-sm" >
                                                      Household
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Infant-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={2} md={2}>
                                                    <ul className="nav nav-pills flex-column">
                                                    <li className="nav-item text-sm" >
                                                    {report.households}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.infant_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.infant_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.elderly_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.malnurished}
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div>                                
                                              )
                                        ))
                                        }                          
                                      </div>
                                </div>
                                </div>
                                }              
                                </GeoJSON>
                            ))}
                       </LayerGroup>

                      </> 
                  )}
                  {selectedHazard == 2 && (
                      <>
                       <LayersControl.Overlay checked  name="Very High Landslide">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {rilinfoVHL && rilinfoVHL.map((household, index) => (
                              <Marker   
                                key={index}
                                position={[household.lat,household.long]} 
                                icon={customIcon}
                                eventHandlers={{
                                  mouseover: () => handleMarkerHover(household.household_number),
                                  mouseout: handleMarkerLeave,
                                }}
                                >  
                                
                                 {hoveredMarker === household.household_number && (
                                  <div className="jarviswidget" id="MHL" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                    
                                <header role="heading" >
                                             <h2 className="text-sm font-weight-bold" >{household.barangay_name} - {household.purok_name}</h2>  
                                </header>
                                <div role="content">
                                                <div className="widget-body">
                                                <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                    {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column" >
                                                    
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant(M/F)
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly(M/F)
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                     </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column">
                                                      
                                                        <li className="nav-item text-sm" >
                                                        {household.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.infant_male}/{household.infant_female}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.elderly_male}/{household.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.malnurished}
                                                      </li>
                                                       
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div> 
                                                </div>
                                </div>
                                </div>
                                )}
                                                       
                                
                              </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked   name="High Landslide">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {rilinfoHL && rilinfoHL.map((household, index) => (
                              <Marker   
                              key={index}
                              position={[household.lat,household.long]} 
                              icon={customIcon}
                              eventHandlers={{
                                mouseover: () => handleMarkerHover(household.household_number),
                                mouseout: handleMarkerLeave,
                              }}
                              >  
                              
                               {hoveredMarker === household.household_number && (
                                <div className="jarviswidget" id="MHL" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                  
                              <header role="heading">
                                              <h2 className="text-sm font-weight-bold" >{household.barangay_name}- {household.purok_name}</h2>				
                              </header>
                              <div role="content">
                                              <div className="widget-body">
                                              <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                  {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column" >
                                                  
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant(M/F)
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly(M/F)
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                   </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column">
                                                   
                                                      <li className="nav-item text-sm" >
                                                      {household.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.infant_male}/{household.infant_female}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.elderly_male}/{household.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.malnurished}
                                                    </li>
                                                     
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div> 
                                              </div>
                              </div>
                              </div>
                              )}
                                                     
                              
                            </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked  name="Moderate Landslide">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {rilinfoML && rilinfoML.map((household, index) => (
                              <Marker   
                              key={index}
                              position={[household.lat,household.long]} 
                              icon={customIcon}
                              eventHandlers={{
                                mouseover: () => handleMarkerHover(household.household_number),
                                mouseout: handleMarkerLeave,
                              }}
                              >  
                              
                               {hoveredMarker === household.household_number && (
                                <div className="jarviswidget" id="MML" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                  
                              <header role="heading">
                                              <h2 className="text-sm font-weight-bold" >{household.barangay_name}- {household.purok_name}</h2>				
                              </header>
                              <div role="content">
                                              <div className="widget-body">
                                              <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                  {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column" >
                                                   
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant(M/F)
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly(M/F)
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                   </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column">
                                                    
                                                      <li className="nav-item text-sm" >
                                                      {household.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.infant_male}/{household.infant_female}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.elderly_male}/{household.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.malnurished}
                                                    </li>
                                                     
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div> 
                                              </div>
                              </div>
                              </div>
                              )}
                                                     
                              
                            </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                        </LayersControl.Overlay>                       
                        <LayersControl.Overlay checked   name="Low Landslide">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {rilinfoLL && rilinfoLL.map((household, index) => (
                              <Marker   
                              key={index}
                              position={[household.lat,household.long]} 
                              icon={customIcon}
                              eventHandlers={{
                                mouseover: () => handleMarkerHover(household.household_number),
                                mouseout: handleMarkerLeave,
                              }}
                              >  
                              
                               {hoveredMarker === household.household_number && (
                                <div className="jarviswidget" id="MLL" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                  
                              <header role="heading">
                                              <h2 className="text-sm font-weight-bold" >{household.barangay_name}- {household.purok_name}</h2>				
                              </header>
                              <div role="content">
                                              <div className="widget-body">
                                              <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                  {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column" >
                                                    
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant(M/F)
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly(M/F)
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                   </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                    <ul className="nav nav-pills flex-column">
                                                    
                                                      <li className="nav-item text-sm" >
                                                      {household.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.infant_male}/{household.infant_female}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.elderly_male}/{household.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {household.malnurished}
                                                    </li>
                                                     
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div> 
                                              </div>
                              </div>
                              </div>
                              )}
                                                     
                              
                            </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>          
                        </LayersControl.Overlay>

                        <LayerGroup>
                          {adnmunrils && adnmunrils.map((feature, index) => (
                      
                              <GeoJSON     
                              key={index}                     
                                data={feature}
                                color={feature.properties.LndslideSu === "HL" ? "#f01e1e" : feature.properties.LndslideSu === "LL" ? "#ffff00" : feature.properties.LndslideSu === "ML" ? "#009600" : "#963c1e"} 
                                weight={1}
                                opacity={1}
                                fillOpacity={0.2}
                                dashArray={3}
                                //key={feature.properties.LndslideSu}                               
                              >          
                                </GeoJSON>
                            ))}
                       </LayerGroup>
                      <LayerGroup> 
                          {adnrils && adnrils.map((feature, index) => (   
                              <GeoJSON
                                 key={index} 
                                data={feature}
                                color={feature.properties.LndslideSu === "HL" ? "#f01e1e" : feature.properties.LndslideSu === "LL" ? "#ffff00" : feature.properties.LndslideSu === "ML" ? "#009600" : "#963c1e"} 
                                weight={2}
                                opacity={1}
                                fillOpacity={0.8}
                                dashArray={3}
                                //key={feature.properties.LndslideSu}  
                                onEachFeature={onEachFeatureRIL}
                              >
                              {feature.properties.LndslideSu === "HL" 
                                  ?   
                                       ( 
                                         <div className="jarviswidget" id="HL" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute'   }}>
                                          <header role="heading">
                                                <h2 className="text-sm font-weight-bold" >RAIN-Induced High</h2>				
                                                </header>
                                                <div role="content">
          
                                                <div className="widget-body">
                                                
                                          {rilrpt && rilrpt.map((report) => (
                                           
                                            report.ril_id === "HL" && 
                                                (
                                                  <div key={report.ril_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                    <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                    {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={5} md={9}>
                                                      <ul className="nav nav-pills flex-column" >
                                                      <li className="nav-item text-sm" >
                                                        Household
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant-Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant-Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Elderly-Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly-Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                      </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={2} md={3}>
                                                      <ul className="nav nav-pills flex-column">
                                                      <li className="nav-item text-sm" >
                                                      {report.household_number}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.infant_male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.infant_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.elderly_male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.malnurished}
                                                      </li>
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div>                                
                                                )
                                          ))
                                          }  
                                          </div>
                                          </div>                         
                                        </div>
                                        )
                                  : feature.properties.LndslideSu === "LL" 
                                  ? 
                                        <div className="jarviswidget"  id="LL" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute' }}>
                                            <header role="heading">                             
                                                <h2 className="text-sm font-weight-bold" >Rain-Induced Low</h2>				
                                                </header>
                                                <div role="content">
                                                <div className="widget-body">
                                            {rilrpt && rilrpt.map((report) => (
                                              report.ril_id === "LL" && 
                                                  (
                                                    <div key={report.ril_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                      <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                      {/* <Row notters> */}
                                                      <Row >
                                                        <Col xs={5} md={9}>
                                                        <ul className="nav nav-pills flex-column" >
                                                        <li className="nav-item text-sm" >
                                                          Household
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Families
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Population
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Infant-Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant-Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Elderly-Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Elderly-Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Illiteracy
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        IP
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          PWD
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Malnourished
                                                        </li>
                                                      </ul>
                                                        </Col>
                                                        <Col xs={2} md={2}>
                                                        <ul className="nav nav-pills flex-column">
                                                        <li className="nav-item text-sm" >
                                                        {report.household_number}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.families}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.person}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.infant_male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.infant_female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.elderly_male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.elderly_female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.illiteracy}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.ip}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.pwd}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.malnurished}
                                                        </li>
                                                      </ul>
                                                        </Col>
                                                      </Row>
                                                      
                                                    </div>                                
                                                  )
                                            ))
                                            }                
                                              </div>
                                          </div>         
                                        </div> 
                                  : feature.properties.LndslideSu === "ML" 
                                  ? 
                                      <div  className="jarviswidget"  id="ML" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute'}}>
                                            <header role="heading">
                                                <h2 className="text-sm font-weight-bold" >Rain-Induced Moderate</h2>				
                                                </header>
                                                <div role="content">                                 
                                                <div className="widget-body">

                                        {rilrpt && rilrpt.map((report) => (
                                          report.ril_id === "ML" && 
                                              (
                                                <div key={report.ril_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                  <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                  {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={5} md={9}>
                                                    <ul className="nav nav-pills flex-column" >
                                                    <li className="nav-item text-sm" >
                                                      Household
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Infant-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={2} md={2}>
                                                    <ul className="nav nav-pills flex-column">
                                                    <li className="nav-item text-sm" >
                                                    {report.household_number}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.infant_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.infant_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.elderly_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.malnurished}
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div>                                
                                              )
                                        ))
                                        }                
                                                    
                                      </div>
                                    </div>
                                      </div>
                                  : feature.properties.LndslideSu === "VHL"
                                  ?
                                      <div  className="jarviswidget"  id="VHL" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute' }}>
                                                <header role="heading">

                                                <h2 className="text-sm font-weight-bold" >Rain-Induced Very High</h2>				

                                                </header>
                                                <div role="content">
                                                <div className="widget-body">
                                                  {rilrpt && rilrpt.map((report) => (
                                                    report.ril_id === "VHL" && 
                                                        (
                                                          <div key={report.ril_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                            <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                            {/* <Row notters> */}
                                                            <Row >
                                                              <Col xs={5} md={9}>
                                                              <ul className="nav nav-pills flex-column" >
                                                              <li className="nav-item text-sm" >
                                                                Household
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                Families
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              Population
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                Male
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              Female
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                Infant-Male
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              Infant-Female
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                Elderly-Male
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              Elderly-Female
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                Illiteracy
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              IP
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                PWD
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              Malnourished
                                                              </li>
                                                            </ul>
                                                              </Col>
                                                              <Col xs={2} md={2}>
                                                              <ul className="nav nav-pills flex-column">
                                                              <li className="nav-item text-sm" >
                                                              {report.household_number}
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                {report.families}
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              {report.person}
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                {report.male}
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              {report.female}
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                {report.infant_male}
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              {report.infant_female}
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                {report.elderly_male}
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              {report.elderly_female}
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                {report.illiteracy}
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              {report.ip}
                                                              </li>
                                                                <li className="nav-item text-sm" >
                                                                {report.pwd}
                                                              </li>
                                                              <li className="nav-item text-sm" >
                                                              {report.malnurished}
                                                              </li>
                                                            </ul>
                                                              </Col>
                                                            </Row>
                                                            
                                                          </div>                                
                                                        )
                                                  ))
                                                  }                          
                                                </div>
                                                </div>
                                      </div>
                                    :
                                    <></>
                                }     
                               
                              </GeoJSON>
                              ))}
                      </LayerGroup>

                      
                      </>
                    )
                  }
                  {selectedHazard == 3 &&(
                    <>
                    <LayersControl.Overlay checked  name="Above head surge height">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {stormsurgeinfo3 && stormsurgeinfo3.map((household, index) => (
                              <Marker   
                                key={index}
                                position={[household.lat,household.long]} 
                                icon={customIcon}
                                eventHandlers={{
                                  mouseover: () => handleMarkerHover(household.household_number),
                                  mouseout: handleMarkerLeave,
                                }}
                                >  
                                
                                 {hoveredMarker === household.household_number && (
                                  <div className="jarviswidget" id="level3" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                    
                                <header role="heading" >
                                             <h2 className="text-sm font-weight-bold" >{household.barangay_name} - {household.purok_name}</h2>  
                                </header>
                                <div role="content">
                                                <div className="widget-body">
                                                <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column" >
                                                    
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant(M/F)
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly(M/F)
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                     </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column">
                                                      
                                                        <li className="nav-item text-sm" >
                                                        {household.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.infant_male}/{household.infant_female}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.elderly_male}/{household.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.malnurished}
                                                      </li>
                                                       
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div> 
                                                </div>
                                </div>
                                </div>
                                )}
                                                       
                                
                              </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked  name="Chest deep surge height">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {stormsurgeinfo2 && stormsurgeinfo2.map((household, index) => (
                              <Marker   
                                key={index}
                                position={[household.lat,household.long]} 
                                icon={customIcon}
                                eventHandlers={{
                                  mouseover: () => handleMarkerHover(household.household_number),
                                  mouseout: handleMarkerLeave,
                                }}
                                >  
                                
                                 {hoveredMarker === household.household_number && (
                                  <div className="jarviswidget" id="level2" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                    
                                <header role="heading" >
                                             <h2 className="text-sm font-weight-bold" >{household.barangay_name} - {household.purok_name}</h2>  
                                </header>
                                <div role="content">
                                                <div className="widget-body">
                                                <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                     {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column" >
                                                    
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant(M/F)
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly(M/F)
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                     </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column">
                                                      
                                                        <li className="nav-item text-sm" >
                                                        {household.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.infant_male}/{household.infant_female}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.elderly_male}/{household.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.malnurished}
                                                      </li>
                                                       
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div> 
                                                </div>
                                </div>
                                </div>
                                )}
                                                       
                                
                              </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked  name="Knee deep surge height">
                          <LayerGroup>
                            {/* <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={false} maxClusterRadius={35}  disableClusteringAtZoom={18} >  */}
                            {stormsurgeinfo1 && stormsurgeinfo1.map((household, index) => (
                              <Marker   
                                key={index}
                                position={[household.lat,household.long]} 
                                icon={customIcon}
                                eventHandlers={{
                                  mouseover: () => handleMarkerHover(household.household_number),
                                  mouseout: handleMarkerLeave,
                                }}
                                >  
                                
                                 {hoveredMarker === household.household_number && (
                                  <div className="jarviswidget" id="level1" style={{ display: 'block', top: window.innerWidth <= 150 ? 50 : 190, left: window.innerWidth <= 250 ? 5 : 5,zIndex:500, width:150 ,position: 'absolute'}}>
                                    
                                <header role="heading" >
                                             <h2 className="text-sm font-weight-bold" >{household.barangay_name} - {household.purok_name}</h2>  
                                </header>
                                <div role="content">
                                                <div className="widget-body">
                                                <div key={household.household_number} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                     {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column" >
                                                    
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant(M/F)
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly(M/F)
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                     </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={6} md={6}>
                                                      <ul className="nav nav-pills flex-column">
                                                      
                                                        <li className="nav-item text-sm" >
                                                        {household.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.infant_male}/{household.infant_female}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.elderly_male}/{household.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {household.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {household.malnurished}
                                                      </li>
                                                       
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div> 
                                                </div>
                                </div>
                                </div>
                                )}
                                                       
                                
                              </Marker>
                            ) )}                   
                      {/* </MarkerClusterGroup> */}
                          </LayerGroup>
                    </LayersControl.Overlay>
                    <LayerGroup>
                          {adnmunstormsurges && adnmunstormsurges.map((feature, index) => (
                      
                              <GeoJSON       
                                key={index}                   
                                data={feature}
                                color={feature.properties.HAZ === 1 ? "#ffff00" : feature.properties.HAZ === 2 ? "#ff7f00" : "#f01e1e" } 
                                weight={1}
                                opacity={1}
                                fillOpacity={0.2}
                                dashArray={3}
                                //key={feature.properties.HAZ}                               
                              >          
                                </GeoJSON>
                            ))}
                       </LayerGroup>
                    <LayerGroup> 
                          {adnstormsurges && adnstormsurges.map((feature, index) => (
                            
                              <GeoJSON
                                 key={index} 
                                data={feature}
                                color={feature.properties.HAZ === 1 ? "#ffff00" : feature.properties.HAZ === 2 ? "#ff7f00" : "#f01e1e" } 
                                weight={2}
                                opacity={1}
                                fillOpacity={0.8}
                                dashArray={3}
                                //key={feature.properties.HAZ}  
                                onEachFeature={onEachFeatureStormSurge}
                              >
                                
                                {feature.properties.HAZ === 1 
                                      ?   
                                                        
                                        <div className="jarviswidget" id="surge1" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute'   }}>
                                          <header role="heading">
                                                <h2 className="text-sm font-weight-bold" >Knee deep surge height</h2>				
                                                </header>
                                                <div role="content">
          
                                                <div className="widget-body">
                                                
                                          {stormsurgerpt && stormsurgerpt.map((report) => (
                                            report.stormsurge_id === '1' && 
                                                (
                                                  <div key={report.stormsurge_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                    <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                     {/* <Row notters> */}
                                                    <Row >
                                                      <Col xs={5} md={9}>
                                                      <ul className="nav nav-pills flex-column" >
                                                      <li className="nav-item text-sm" >
                                                        Household
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Families
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Population
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant-Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant-Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Elderly-Male
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly-Female
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        Illiteracy
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      IP
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        PWD
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      Malnourished
                                                      </li>
                                                    </ul>
                                                      </Col>
                                                      <Col xs={2} md={3}>
                                                      <ul className="nav nav-pills flex-column">
                                                      <li className="nav-item text-sm" >
                                                      {report.household_number}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.families}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.person}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.infant_male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.infant_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.elderly_male}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.elderly_female}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.illiteracy}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.ip}
                                                      </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.pwd}
                                                      </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.malnurished}
                                                      </li>
                                                    </ul>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div>                                
                                                )
                                          ))
                                          }  
                                          </div>
                                          </div>                         
                                        </div>
                
                                  : feature.properties.HAZ === 2 
                                      ? 
                                        <div className="jarviswidget"  id="surge2" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute' }}>
                                            <header role="heading">                             
                                                <h2 className="text-sm font-weight-bold" >Chest deep surge height</h2>				
                                                </header>
                                                <div role="content">
                                                <div className="widget-body">
                                            {stormsurgerpt && stormsurgerpt.map((report) => (
                                              report.stormsurge_id === '2' && 
                                                  (
                                                    <div key={report.flood_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                      <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                       {/* <Row notters> */}
                                                      <Row >
                                                        <Col xs={5} md={9}>
                                                        <ul className="nav nav-pills flex-column" >
                                                        <li className="nav-item text-sm" >
                                                          Household
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Families
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Population
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Infant-Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Infant-Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Elderly-Male
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Elderly-Female
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          Illiteracy
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        IP
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          PWD
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        Malnourished
                                                        </li>
                                                      </ul>
                                                        </Col>
                                                        <Col xs={2} md={2}>
                                                        <ul className="nav nav-pills flex-column">
                                                        <li className="nav-item text-sm" >
                                                        {report.household_number}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.families}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.person}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.infant_male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.infant_female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.elderly_male}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.elderly_female}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.illiteracy}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.ip}
                                                        </li>
                                                          <li className="nav-item text-sm" >
                                                          {report.pwd}
                                                        </li>
                                                        <li className="nav-item text-sm" >
                                                        {report.malnurished}
                                                        </li>
                                                      </ul>
                                                        </Col>
                                                      </Row>
                                                      
                                                    </div>                                
                                                  )
                                            ))
                                            }                
                                              </div>
                                          </div>         
                                        </div>                                                                 
                                  : 
                                  
                                      <div  className="jarviswidget"  id="surge3" style={{display: 'none', top: window.innerWidth <= '250px' ? 50 : 190,left:window.innerWidth <= '250a' ? 5 : 5,zIndex:500, width:150, position: 'absolute' }}>
                                      <header role="heading">

                                                <h2 className="text-sm font-weight-bold" >Above head surge height</h2>				

                                                </header>
                                                <div role="content">
                                                <div className="widget-body">
                                        {stormsurgerpt && stormsurgerpt.map((report) => (
                                          report.stormsurge_id === '3' && 
                                              (
                                                <div key={report.flood_id} style={{ '@media screen and (maxWidth: 250px)': { maxWidth: '250px' } }}>
                                                  <p className="text-sm">{report.barangay_name},{report.municipality_name}</p> 
                                                   {/* <Row notters> */}
                                                  <Row >
                                                    <Col xs={5} md={9}>
                                                    <ul className="nav nav-pills flex-column" >
                                                    <li className="nav-item text-sm" >
                                                      Household
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Families
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Population
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Infant-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Infant-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Elderly-Male
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Elderly-Female
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      Illiteracy
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    IP
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      PWD
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    Malnourished
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                    <Col xs={2} md={2}>
                                                    <ul className="nav nav-pills flex-column">
                                                    <li className="nav-item text-sm" >
                                                    {report.household_number}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.families}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.person}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.infant_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.infant_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.elderly_male}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.elderly_female}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.illiteracy}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.ip}
                                                    </li>
                                                      <li className="nav-item text-sm" >
                                                      {report.pwd}
                                                    </li>
                                                    <li className="nav-item text-sm" >
                                                    {report.malnurished}
                                                    </li>
                                                  </ul>
                                                    </Col>
                                                  </Row>
                                                  
                                                </div>                                
                                              )
                                        ))
                                        }                          
                                      </div>
                                </div>
                                      </div>
                                }  


                              </GeoJSON>
                              ))}
                    </LayerGroup>
                </>
                  )}
        </LayersControl>  

        {brgypolygons &&
          (<GeoJSON data={brgypolygons} 
              color="black" 
              weight={3}
              opacity={1}
              dashArray={10}
              fillOpacity={0}
              > 
          </GeoJSON>
          
          )
        }
        
        {selectedHazard == 1 && (
                      <>
          <div className="jarviswidget" style={{ position: 'absolute',zIndex:500, bottom: 0,left: '0.5%',  width:147,height:80  }}>
          
              <header role="heading">
              <h2 className="text-sm font-weight-bold" >FLOOD</h2>				
              </header>
              <div role="content" style={{ height: 70 }}>
              <div className="widget-body" >
                            <div className="row show-grid"  style={{ backgroundColor: '#28286e', color: '#ffffff', textAlign: 'center'  }}>
                              <div className="col-md-12 text-sm">
                              Very High
                              </div>
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#3c46c8', color: '#ffffff', textAlign: 'center'  }}>
                              <div className="col-md-12 text-sm" >
                              High
                              </div>
                            
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#783cc8' , color: '#ffffff', textAlign: 'center' }}>
                              <div className="col-md-12 text-sm">
                              Moderate
                              </div>
                            
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#f5dcf5' , color: '#000', textAlign: 'center' }}>
                              <div className="col-md-12 text-sm">
                              Low
                              </div>
                            
                           
                          </div>


              </div></div>
          </div>
          </>
        )}
        {selectedHazard == 2 && (
                      <>


          <div className="jarviswidget" style={{ position: 'absolute',zIndex:500, bottom: 0,left: '0.5%',  width:147,height:80  }}>

          <header role="heading">
              <h2 className="text-sm font-weight-bold" >RAIN-INDUCED LANDSLIDE </h2>
              <br />
              <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Susceptibility Level	</p>			
              </header>
              <div role="content" style={{ height: 70 }}>
              <div className="widget-body" >
                            <div className="row show-grid"  style={{ backgroundColor: '#963c1e', color: '#ffffff', textAlign: 'center'  }}>
                              <div className="col-md-12 text-sm">
                              Very High
                              </div>
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#f01e1e', color: '#ffffff', textAlign: 'center'  }}>
                              <div className="col-md-12 text-sm" >
                              High
                              </div>
                            
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#009600' , color: '#ffffff', textAlign: 'center' }}>
                              <div className="col-md-12 text-sm">
                              Moderate
                              </div>
                            
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#ffff00' , color: '#000', textAlign: 'center' }}>
                              <div className="col-md-12 text-sm">
                              Low
                              </div>
                            
                           
                          </div>


            </div></div>     
          </div>
         
          </>
        )}
        {selectedHazard == 3 && (
                      <>
          <div className="jarviswidget" style={{ position: 'absolute',zIndex:500, bottom: 0,left: '0.5%',  width:147,height:80 }}>

          <header role="heading">
              <h2 className="text-sm font-weight-bold" >STORM SURGE HEIGHTS</h2>	
              </header>
              <div role="content" style={{ height:70 }}>
              <div  className="widget-body" >
                            <div className="row show-grid"  style={{ backgroundColor: '#f01e1e', color: '#ffffff', textAlign: 'center'  }}>
                              <div className="col-md-12 text-sm">
                              Above head surge height
                              </div>
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#ff7f00', color: '#ffffff', textAlign: 'center'  }}>
                              <div className="col-md-12 text-sm" >
                              Chest deep surge height
                              </div>
                            
                            </div>
                            <div className="row show-grid" style={{ backgroundColor: '#ffff00' , color: '#000', textAlign: 'center' }}>
                              <div className="col-md-12 text-sm">
                              Knee deep surge height
                              </div>
                            
                            </div>
                           
                       </div></div>  

          </div>
          </>
        )}
        
        <MapWithPrint />  
    
          

          </MapContainer>
          }
        </div>
    </> 
  )
}

export default MainMap
