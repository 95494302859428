import axios from 'axios'
import {
    BARANGAY_POLYGON_FAIL,
    BARANGAY_POLYGON_REQUEST,
    BARANGAY_POLYGON_SUCCESS,

    ADN_FLOOD_FAIL,
    ADN_FLOOD_REQUEST,
    ADN_FLOOD_SUCCESS,

    ADN_RIL_FAIL,
    ADN_RIL_REQUEST,
    ADN_RIL_SUCCESS,

    ADN_STORMSURGE_FAIL,
    ADN_STORMSURGE_REQUEST,
    ADN_STORMSURGE_SUCCESS,

    ADN_MUN_FLOOD_FAIL,
    ADN_MUN_FLOOD_REQUEST,
    ADN_MUN_FLOOD_SUCCESS,

    ADN_MUN_RIL_FAIL,
    ADN_MUN_RIL_REQUEST,
    ADN_MUN_RIL_SUCCESS,

    ADN_MUN_STORMSURGE_FAIL,
    ADN_MUN_STORMSURGE_REQUEST,
    ADN_MUN_STORMSURGE_SUCCESS

} from '../constant/polygonConstants'
import barangaymap from '../shapefile/Barangay.json'
import adn_flood from '../shapefile/FloodADN.json'
import adn_ril from '../shapefile/rilADN.json'
import adn_stormsurge from '../shapefile/StormSurgeADN.json'

export const listPolygonBarangay = (barangayID) => async (dispatch,getState) => {
    try{
        dispatch({type:BARANGAY_POLYGON_REQUEST})

        const data = barangaymap.features.find((feature) => {
                            if(feature.properties.BrgyPSGC === Number(barangayID)){

                                return  feature.geometry.coordinates;
                            }
                            
                        });

        dispatch({
            type:BARANGAY_POLYGON_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:BARANGAY_POLYGON_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const adnFlood = (barangayID) => async (dispatch,getState) => {
    try{
        dispatch({type:ADN_FLOOD_REQUEST})

        const data = adn_flood.features.filter(feature => feature.properties.BrgyPSGC === Number(barangayID));  

        dispatch({
            type:ADN_FLOOD_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:ADN_FLOOD_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const adnMunFlood = (munID) => async (dispatch,getState) => {
    try{
        dispatch({type:ADN_MUN_FLOOD_REQUEST})

        const data = adn_flood.features.filter(feature => feature.properties.MunPSGC === Number(munID));  

        dispatch({
            type:ADN_MUN_FLOOD_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:ADN_MUN_FLOOD_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const adnRil = (barangayID) => async (dispatch,getState) => {
    try{
        dispatch({type:ADN_RIL_REQUEST})

        const data = adn_ril.features.filter(feature => feature.properties.BrgyPSGC === Number(barangayID));  

        dispatch({
            type:ADN_RIL_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:ADN_RIL_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const adnMunRil = (munID) => async (dispatch,getState) => {
    try{
        dispatch({type:ADN_MUN_RIL_REQUEST})

        const data = adn_ril.features.filter(feature => feature.properties.MunPSGC === Number(munID));  

        dispatch({
            type:ADN_MUN_RIL_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:ADN_MUN_RIL_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const adnStormSurge = (barangayID) => async (dispatch,getState) => {
   
    try{
        dispatch({type:ADN_STORMSURGE_REQUEST})
        
        const data = adn_stormsurge.features.filter(feature => feature.properties.BrgyPSGC === Number(barangayID));  
        dispatch({
            type:ADN_STORMSURGE_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:ADN_STORMSURGE_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const adnMunStormSurge = (munID) => async (dispatch,getState) => {
   
    try{
        dispatch({type:ADN_MUN_STORMSURGE_REQUEST})
        
        const data = adn_stormsurge.features.filter(feature => feature.properties.MunPSGC === Number(munID));  
        dispatch({
            type:ADN_MUN_STORMSURGE_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:ADN_MUN_STORMSURGE_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}
