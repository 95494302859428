import React , {useState,useEffect,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'


import { listBarangay,puroklistBarangay } from '../actions/barangayActions'
import { listMunicipal } from '../actions/municipalActions'
import { listBrgyInfo} from '../actions/reportActions'
import { BARANGAY_PUROK_LIST_RESET } from '../constant/barangayConstants'
import { BARANGAY_INFO_RESET } from '../constant/reportsConstatant'
import adn_flood from '../shapefile/FloodADN.json'
import barangaymap from '../shapefile/Barangay.json'

import { MapContainer, TileLayer, Marker, GeoJSON} from 'react-leaflet'
import L,{Icon,divIcon} from 'leaflet'


function BarangayDetails({ match }) {
    const barangay_id = match.params.barangay_id;


    const dispatch = useDispatch()

  const currentDate = new Date();

  // Format the date as you desire (e.g., "March 4, 2024")
  const formattedDate = currentDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });


  const setColor = ({ properties }) => {
    return { weight: 1,color:'white' };
  };

  const customMarkerIcon = (name) =>
    divIcon({
      html: name,
      className: "icon"
    });

    const [puroks, setPuroks] = useState({})

    const barangayinfo = useSelector(state => state.brgyInfoReducer)
    const {success:successbrgyinfo,brgyinfo} = barangayinfo

    const municipalist = useSelector(state => state.municipalListReducers)
    const {municipals} = municipalist

    const barangaylist = useSelector(state => state.barangayListReducers)
    const {barangays} = barangaylist
    
    const puroklist = useSelector(state => state.barangayPurokListReducers)
    const {purok,success:puroksuccess} = puroklist

  useEffect(() => {
    dispatch({type:BARANGAY_PUROK_LIST_RESET})
    dispatch({type:BARANGAY_INFO_RESET})
    dispatch(listBarangay())
    dispatch(listMunicipal())
    dispatch(puroklistBarangay(barangay_id))
    dispatch(listBrgyInfo(barangay_id))
  },[dispatch,barangay_id])   


  useEffect(()=>{
    
    if(puroksuccess)
    {
      setPuroks(purok)
    }

  },[dispatch])

    const printPage1 = () => {
        window.print();
      };
console.log('pokkkk',brgyinfo)

      const selectedBarangay = barangays.find((item) => item.psgccode === barangay_id);
      const barangayName = selectedBarangay ? selectedBarangay.brgyname : null;

  return (
    <div>
    {/* MAIN CONTENT */}
      <div id="content">
       
        {/* row */}
        <div className="row">
          <div className="col-sm-12">
            <div className="well well-sm">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="well well-light well-sm no-margin no-padding">
                    <div className="row">
                      <div className="col-sm-12">
                        <div id="myCarousel" className="carousel fade profile-carousel">
                          <div className="air air-bottom-right padding-10">
                            <a href="javascript:void(0);" className="btn txt-color-white bg-color-teal btn-sm"><i className="fa fa-check" /> Follow</a>&nbsp; <a href="javascript:void(0);" className="btn txt-color-white bg-color-pinkDark btn-sm"><i className="fa fa-link" /> Connect</a>
                          </div>
                          <div className="air air-top-left padding-10">
                            <h4 className="txt-color-white font-md">Jan 1, 2014</h4>
                          </div>
                          <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to={0} className="active" />
                            <li data-target="#myCarousel" data-slide-to={1} className />
                            <li data-target="#myCarousel" data-slide-to={2} className />
                          </ol>
                          <div className="carousel-inner">
                            {/* Slide 1 */}
                            <div className="item active">
                              <img src="img/demo/s1.jpg" alt="demo user" />
                            </div>
                            {/* Slide 2 */}
                            <div className="item">
                              <img src="img/demo/s2.jpg" alt="demo user" />
                            </div>
                            {/* Slide 3 */}
                            <div className="item">
                              <img src="img/demo/m3.jpg" alt="demo user" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        {/* <div className="row"> */}
                          {/* <div className="col-sm-3 profile-pic">
                            <img src="img/avatars/sunny-big.png" alt="Logo" />
                            <div className="padding-10">
                              <h4 className="font-md"><strong>1,543</strong>
                                <br />
                                <small>Followers</small></h4>
                              <br />
                              <h4 className="font-md"><strong>419</strong>
                                <br />
                                <small>Connections</small></h4>
                            </div>
                          </div> */}
                          <div className="col-sm-6 padding-10">
                            <h1>Brgy : {barangayName}, <span className="semi-bold">Agusan del Norte</span>
                              <br />
                              <small>Barangay Captian</small></h1>
                            <ul className="list-unstyled">
                              <li>
                                <p className="text-muted">
                                  <i className="fa fa-phone" />&nbsp;&nbsp;(<span className="txt-color-darken">313</span>) <span className="txt-color-darken">464</span> - <span className="txt-color-darken">6473</span>
                                </p>
                              </li>
                              <li>
                                <p className="text-muted">
                                  <i className="fa fa-envelope" />&nbsp;&nbsp;<a href="mailto:simmons@smartadmin">ceo@smartadmin.com</a>
                                </p>
                              </li>
                              <li>
                                <p className="text-muted">
                                  <i className="fa fa-skype" />&nbsp;&nbsp;<span className="txt-color-darken">john12</span>
                                </p>
                              </li>
                              <li>
                                <p className="text-muted">
                                  <i className="fa fa-calendar" />&nbsp;&nbsp;<span className="txt-color-darken">Free after <a href="javascript:void(0);" rel="tooltip" title data-placement="top" data-original-title="Create an Appointment">4:30 PM</a></span>
                                </p>
                              </li>
                            </ul>
                            <br />
                            <p className="font-md">
                              <i>Info</i>
                            </p>
                                  <div className="panel-body no-padding text-align-center">
                                    
                                    {Array.isArray(brgyinfo) && brgyinfo.map((info, index) => (
                                    <table className="table">
                                      <tbody style={{textAlign:'left'}}><tr>
                                          <td>
                                         Household: {info.households}
                                          </td>
                                        </tr>
                                        <tr className="active">
                                          <td>
                                         Families: {info.families}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                        Population:  {info.person}
                                          </td>
                                        </tr>
                                        <tr className="active">
                                          <td>
                                         Indigenous People: {info.ip}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                         Person with Disability: {info.pwd}
                                          </td>
                                        </tr>
                                        <tr className="active">
                                          <td>
                                          Malnourished: {info.malnurished}
                                          </td>
                                        </tr>
                                      </tbody></table>
                                       ))}
                                  </div>
                          </div>
                          <div className="col-sm-3">
                            {/* <h1><small>Connections</small></h1>
                            <ul className="list-inline friends-list">
                              <li><img src="img/avatars/1.png" alt="friend-1" />
                              </li>
                              <li><img src="img/avatars/2.png" alt="friend-2" />
                              </li>
                              <li><img src="img/avatars/3.png" alt="friend-3" />
                              </li>
                              <li><img src="img/avatars/4.png" alt="friend-4" />
                              </li>
                              <li><img src="img/avatars/5.png" alt="friend-5" />
                              </li>
                              <li><img src="img/avatars/male.png" alt="friend-6" />
                              </li>
                              <li>
                                <a href="javascript:void(0);">413 more</a>
                              </li>
                            </ul>
                            <h1><small>Recent visitors</small></h1>
                            <ul className="list-inline friends-list">
                              <li><img src="img/avatars/male.png" alt="friend-1" />
                              </li>
                              <li><img src="img/avatars/female.png" alt="friend-2" />
                              </li>
                              <li><img src="img/avatars/female.png" alt="friend-3" />
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      {/* </div> */}
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <hr />
                        <div className="padding-10">
                          <ul className="nav nav-tabs tabs-pull-right">
                            <li className="active">
                              <a href="#flood" data-toggle="tab">Flood</a>
                            </li>
                            <li>
                              <a href="#landslide" data-toggle="tab">Landslide</a>
                            </li>
                            <li className="pull-left">
                            <a href="#stormsurge" data-toggle="tab"> Storm Surge</a>
                            </li>
                          </ul>
                          <div className="tab-content padding-top-10">
                            <div className="tab-pane in active" id="flood">
                            <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={10} scrollWheelZoom={true}  zoomControl={false} id='mapbrgyflood'>
                            <TileLayer  url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                            </MapContainer>
                            </div>
                            <div className="tab-pane" id="landslide">
                            <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={10} scrollWheelZoom={true}  zoomControl={false} id='mapbrgylandslide'>
                            <TileLayer  url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                            </MapContainer>
                            </div>
                            <div className="tab-pane" id="stormsurge">
                            <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={10} scrollWheelZoom={true}  zoomControl={false} id='mapbrgystormsurge'>
                            <TileLayer  url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                            </MapContainer>
                            </div>
                            {/* end tab */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end row */}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="well well-light well-sm no-margin ">
                    <div className="row">
                  {/* widget grid */}
                    <section id="widget-grid" className>
                        {/* NEW WIDGET START */}
                        <article className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          {/* Widget ID (each widget will need unique ID)*/}
                          <div className="jarviswidget jarviswidget-color-blueDark" id="wid-id-0" data-widget-editbutton="false">
                            {/* widget options:
                                    usage: <div class="jarviswidget" id="wid-id-0" data-widget-editbutton="false">

                                    data-widget-colorbutton="false"
                                    data-widget-editbutton="false"
                                    data-widget-togglebutton="false"
                                    data-widget-deletebutton="false"
                                    data-widget-fullscreenbutton="false"
                                    data-widget-custombutton="false"
                                    data-widget-collapsed="true"
                                    data-widget-sortable="false"

                                    */}
                            <header>
                              <span className="widget-icon"> <i className="fa fa-table" /> </span>
                              <h2>By Purok</h2>
                            </header>
                            {/* widget div*/}
                            <div>
                              {/* widget edit box */}
                              <div className="jarviswidget-editbox">
                                {/* This area used as dropdown edit box */}
                              </div>
                              {/* end widget edit box */}
                              {/* widget content */}
                              <div className="widget-body">
                                <p>sample data</p>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Purok</th>
                                        <th>Household</th>
                                        <th>Families</th>
                                        <th>Population</th>
                                        <th>IP</th>
                                        <th>PWD</th>
                                        <th>malnurished</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {Array.isArray(purok) && purok.map((data, index) => (
                                      <tr>
                                      <td>{data.purok_name}</td>
                                      <td>{data.households}</td>
                                      <td>{data.families}</td>
                                      <td>{data.person}</td>
                                      <td>{data.ip}</td>
                                      <td>{data.pwd}</td>
                                      <td>{data.malnurished}</td>
                                    </tr>
                                      ))}
                                      
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* end widget content */}
                            </div>
                            {/* end widget div */}
                          </div>
                          {/* end widget */}
                        </article>
                        {/* WIDGET END */}                     
                    </section>
                    {/* end widget grid */}
                    </div>
                   </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end row */}
      </div>
{/* END MAIN CONTENT */}



    </div>
  )
}

export default BarangayDetails

