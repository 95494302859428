import axios from 'axios'
import {
    STORM_SURGE_INFO_FAIL,
    STORM_SURGE_INFO_REQUEST,
    STORM_SURGE_INFO_SUCCESS,
    STORM_SURGE_FAIL,STORM_SURGE_REQUEST,
    STORM_SURGE_SUCCESS,

    STORM_SURGE_MUNICIPALITY_INFO_FAIL,
    STORM_SURGE_MUNICIPALITY_INFO_REQUEST,
    STORM_SURGE_MUNICIPALITY_INFO_SUCCESS
} from '../constant/stormsurgeConstant'


export const getStormsurgeInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: STORM_SURGE_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/stormsurge/${id}/info/`)
        dispatch({
            type: STORM_SURGE_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: STORM_SURGE_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}


export const getStormsurge = () => async (dispatch) => {
    try{
        dispatch({
            type: STORM_SURGE_REQUEST
        })
      
        const {data} = await axios.get(`/api/stormsurge/`)
        dispatch({
            type: STORM_SURGE_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: STORM_SURGE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}


export const getMunicipalityStormSurgeInfos = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: STORM_SURGE_MUNICIPALITY_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/stormsurge/municipal/${id}/info/`)
        dispatch({
            type: STORM_SURGE_MUNICIPALITY_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: STORM_SURGE_MUNICIPALITY_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}