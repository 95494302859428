import React ,{useState,useEffect } from 'react'
import { Link , useLocation} from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch,useSelector} from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'

import { login } from '../actions/userActions'


// Define getCookie function to retrieve CSRF token from cookies
const getCookie = (name) => {
  const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : null;
};


function LoginScreen({history}) {

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const dispatch = useDispatch()
    const location = useLocation();
    const { search } =location;
  
    // const redirect = search ? Number(search.split("=")[1]) : '/';
    const redirect = search ? search.split('=')[1] : '/profile'; // Using the query parameter value as the redirect path

    const csrftoken = getCookie('csrftoken'); // Make sure to define getCookie function

    const userLogins = useSelector(state => state.userLogin)
    const {error, loading, userInfo} = userLogins



    useEffect(() => {
        if(userInfo){
            history.push(redirect)
        }
      }, [history, userInfo, redirect]) 
  

    const submiHandler = (e) => {
        e.preventDefault()
        dispatch(login(username, password))
    }

  return (
    <FormContainer>
      <h1>Sign In</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submiHandler}>
             <Form.Group controlId='username'>
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type='username'
                    placeholder='Enter Username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                >   
                </Form.Control>
             </Form.Group>

             <Form.Group controlId='password'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type='password'
                    placeholder='Enter Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                >   
                </Form.Control>
             </Form.Group>

             <Button type='submit' variant='primary'>
                Sign In
             </Button>
      </Form>

      <Row className='py-3'>
            <Col>
                New User? <Link
                    to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                    Register</Link>
            </Col>
      </Row>
    </FormContainer>
  )
}

export default LoginScreen
