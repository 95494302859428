import {MUNICIPAL_LIST_FAIL,MUNICIPAL_LIST_REQUEST,MUNICIPAL_LIST_SUCCESS,MUNICIPAL_LIST_RESET,
    MUNICIPAL_AGGREGATE_FAIL,MUNICIPAL_AGGREGATE_REQUEST,MUNICIPAL_AGGREGATE_SUCCESS} from '../constant/municipalConstants'

export const municipalListReducers = (state = {municipals:[]},action) => {
    switch(action.type){
        case MUNICIPAL_LIST_REQUEST:
            return {loading:true, municipals:[]}
        case MUNICIPAL_LIST_SUCCESS:
            return {loading:false, municipals:action.payload}
        case MUNICIPAL_LIST_FAIL:
            return {loading:false, error:action.payload}
        case MUNICIPAL_LIST_RESET:
            return {municipals:[]}
        default: 
            return state
    }
}

export const municipalAggReducers = (state = {municipalsagg:[]},action) => {
    switch(action.type){
        case MUNICIPAL_AGGREGATE_REQUEST:
            return {loading:true, municipalsagg:[]}
        case MUNICIPAL_AGGREGATE_SUCCESS:
            return {loading:false,success:true, municipalsagg:action.payload}
        case MUNICIPAL_AGGREGATE_FAIL:
            return {loading:false, error:action.payload}
        default: 
            return state
    }
}