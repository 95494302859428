import {
    FLOOD_INFO_FAIL,
    FLOOD_INFO_REQUEST,
    FLOOD_INFO_RESET,
    FLOOD_INFO_SUCCESS,

    FLOOD_FAIL,
    FLOOD_REQUEST,
    FLOOD_RESET,
    FLOOD_SUCCESS,

    FLOOD_MUNICIPALITY_INFO_FAIL,
    FLOOD_MUNICIPALITY_INFO_REQUEST,
    FLOOD_MUNICIPALITY_INFO_RESET,
    FLOOD_MUNICIPALITY_INFO_SUCCESS,

    FLOOD_PUROK_INFO_FAIL,
    FLOOD_PUROK_INFO_REQUEST,
    FLOOD_PUROK_INFO_RESET,
    FLOOD_PUROK_INFO_SUCCESS,
} from '../constant/floodConstants'

export const floodInfoReducer = (state = { floodinfo: {} }, action) => {
    switch(action.type){
        case FLOOD_INFO_REQUEST:
            return {...state , loading:true }

        case FLOOD_INFO_SUCCESS:
            return { loading:false, success:true, floodinfo: action.payload }
          
        case FLOOD_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case FLOOD_INFO_RESET:
            return { floodinfo: {} } 
    
        default:
            return state
    }
}


export const floodReducer = (state = { flood: {} }, action) => {
    switch(action.type){
        case FLOOD_REQUEST:
            return {...state , loading:true }

        case FLOOD_SUCCESS:
            return { loading:false, success:true, flood: action.payload }
          
        case FLOOD_FAIL:
            return { loading:false, error: action.payload }
        
        case FLOOD_RESET:
            return { flood: {} } 
    
        default:
            return state
    }
}


export const floodMunicipalityInfoReducer = (state = { floodMunucipalityinfo: {} }, action) => {
    switch(action.type){
        case FLOOD_MUNICIPALITY_INFO_REQUEST:
            return {...state , loading:true }

        case FLOOD_MUNICIPALITY_INFO_SUCCESS:
            return { loading:false, success:true, floodMunucipalityinfo: action.payload }
          
        case FLOOD_MUNICIPALITY_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case FLOOD_MUNICIPALITY_INFO_RESET:
            return { floodMunucipalityinfo: {} } 
    
        default:
            return state
    }
}

export const floodPurokInfoReducer = (state = { floodpurokinfo: {} }, action) => {
    switch(action.type){
        case FLOOD_PUROK_INFO_REQUEST:
            return {...state , loading:true }

        case FLOOD_PUROK_INFO_SUCCESS:
            return { loading:false, success:true, floodpurokinfo: action.payload }
          
        case FLOOD_PUROK_INFO_FAIL:
            return { loading:false, error: action.payload }
        
        case FLOOD_PUROK_INFO_RESET:
            return { floodpurokinfo: {} } 
    
        default:
            return state
    }
}