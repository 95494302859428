export const HOUSEHOLD_LIST_REQUEST = 'HOUSEHOLD_LIST_REQUEST'
export const HOUSEHOLD_LIST_SUCCESS = 'HOUSEHOLD_LIST_SUCCESS'
export const HOUSEHOLD_LIST_FAIL = 'HOUSEHOLD_LIST_FAIL'

export const BARANGAY_HOUSEHOLDS_REQUEST = 'BARANGAY_HOUSEHOLDS_REQUEST'
export const BARANGAY_HOUSEHOLDS_SUCCESS = 'BARANGAY_HOUSEHOLDS_SUCCESS'
export const BARANGAY_HOUSEHOLDS_FAIL = 'BARANGAY_HOUSEHOLDS_FAIL'
export const BARANGAY_HOUSEHOLDS_RESET = 'BARANGAY_HOUSEHOLDS_RESET'


export const BARANGAY_HOUSEHOLDS_INFO_REQUEST = 'BARANGAY_HOUSEHOLDS_INFO_REQUEST'
export const BARANGAY_HOUSEHOLDS_INFO_SUCCESS = 'BARANGAY_HOUSEHOLDS_INFO_SUCCESS'
export const BARANGAY_HOUSEHOLDS_INFO_FAIL = 'BARANGAY_HOUSEHOLDS_INFO_FAIL'
export const BARANGAY_HOUSEHOLDS_INFO_RESET = 'BARANGAY_HOUSEHOLDS_INFO_RESET'