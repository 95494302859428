import axios from 'axios'
import {
    SENIOR_POINT_REQUEST,
    SENIOR_POINT_SUCCESS,
    SENIOR_POINT_FAIL
        } from '../constant/pointConstants'

export const getpointSeniorHF = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SENIOR_POINT_REQUEST
        })

        const {data} = await axios.get(`/api/point/${id}/senior/`)
        
        dispatch({
            type: SENIOR_POINT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: SENIOR_POINT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getpointSeniorVHF = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SENIOR_POINT_REQUEST
        })

        const {data} = await axios.get(`/api/point/${id}/senior/`)
        
        dispatch({
            type: SENIOR_POINT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: SENIOR_POINT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getpointSeniorLF = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SENIOR_POINT_REQUEST
        })

        const {data} = await axios.get(`/api/point/${id}/senior/`)
        
        dispatch({
            type: SENIOR_POINT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: SENIOR_POINT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getpointSeniorMF = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SENIOR_POINT_REQUEST
        })

        const {data} = await axios.get(`/api/point/${id}/senior/`)
        
        dispatch({
            type: SENIOR_POINT_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: SENIOR_POINT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}