import React , {useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CanvasJSReact from '@canvasjs/react-charts';
import { familydPerMun } from '../actions/reportActions'

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function FamilyGroupByGraph() {

  const dispatch = useDispatch()

  const familyNum = useSelector(state => state.familyPerMunReducers)
  const {familymun} = familyNum

  const [chart, setChart] = useState(null);
  useEffect(() => {
    dispatch(familydPerMun())
  },[dispatch])  
    
      const toggleDataSeries = (e) => {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        chart.render();
      };
    

const optionsStarBar = {
        animationEnabled: true,
        
         title:{
           text: "FAMILY ENDICATOR PER MUNICIPAL"
         },
        axisX: {
            indexLabel:"{x}",
                            labelFontSize: 12
        },
        axisY: {
            indexLabel:"{y}",
                            labelFontSize: 8
        },
        toolTip: {
            shared: true
        },
        legend:{
            cursor: "pointer",
            itemclick: toggleDataSeries
        },
        data: [{
            type: "stackedBar",
            name: "Infant(M)",
            showInLegend: "true",
            dataPoints: familymun.map((family) => (
                                    { label: family.munname,y: family.infant_male}))
        },
        {
            type: "stackedBar",
            name: "Infant(F)",
            showInLegend: "true",
            dataPoints:  familymun.map((family) => (
                                    { label: family.munname,y: family.infant_female}))
        },
        {
            type: "stackedBar",
            name: "Elderly(M)",
            showInLegend: "true",
            dataPoints: familymun.map((family) => (
                                    { label: family.munname,y: family.elderly_male}))
        },
        {
            type: "stackedBar",
            name: "Elderly(F)",
            showInLegend: "true",
            dataPoints: familymun.map((family) => (
                                    { label: family.munname,y: family.elderly_female}))
        },
        {
            type: "stackedBar",
            name: "IP",
            showInLegend: "true",
            dataPoints: familymun.map((family) => (
                                    { label: family.munname,y: family.ip}))
        },
        {
            type: "stackedBar",
            name: "PWD",
            showInLegend: "true",
            dataPoints: familymun.map((family) => (
                                    { label: family.munname,y: family.pwd}))
        },
        {
            type: "stackedBar",
            name: "Malnurished",
            showInLegend: "true",
            dataPoints: familymun.map((family) => (
                                    { label: family.munname,y: family.malnurished}))
        }
                    
            ]
    }
  return (
    <div>
      <CanvasJSChart options={optionsStarBar}  />
    </div>
  )
}

export default FamilyGroupByGraph
