import React  from 'react';
import { Helmet } from 'react-helmet';

import { BrowserRouter as Router, Route } from 'react-router-dom'
// import { Container } from 'react-bootstrap'
 import FloodDashboardScreen from './screens/FloodDashboardScreen'
 import HouseholdDashboardScreen from './screens/HouseholdDashboardScreen'
 import FamilyDashboardScreen from './screens/FamilyDashboardScreen';
 import MunicipalGraphScreen from './screens/MunicipalGraphScreen'
 import LoginScreen from './screens/LoginScreen';
 import RegisterScreen from './screens/RegisterScreen';
 import ProfileScreen from './screens/ProfileScreen';

 import AggregatePerMun from './table/AggregatePerMun'

 import FloodMap from './map/FloodMap';
 import RilMap from './map/RilMap';
 import StormSurgeMap  from './map/StormSurgeMap';
 

import HeaderSmartAdmin from './components/HeaderSmartAdmin'
import SideNavSmartAdmin from './components/SideNavSmartAdmin'
import HomeSmartAdmin from './components/HomeSmartAdmin'
import FooterSmartAdmin from './components/FooterSmartAdmin'

import FLoodGraph from './graph/FLoodGraph'
import MalnourishedGraph from './graph/MalnourishedGraph'
import RilGraph from './graph/RilGraph'
import StormSurgeGraph from './graph/StormSurgeGraph'
import MainMap from './map/MainMap';
import MunicipalScreen from './screens/MunicipalScreen';
import PrintFloods from './printables/PrintFloods';
import MunicipalDetails from './municipal/MunicipalDetails';
import BarangayDetails from './barangay/BarangayDetails';


function App() {
  
  return (
    <>
  <Router >
  <HeaderSmartAdmin/>
  
      {/* <SideNavSmartAdmin/>  */}
          <div className='container-fluid'>
            <div id='main' role='main' style={{marginLeft:3}} > 
            {/* <div id="ribbon"><ol class="breadcrumb"><li>Home</li><li>Dashboard</li></ol></div> */}
            <HomeSmartAdmin/>
                    <Route  path='/' component={MainMap} exact />
                    <Route  path='/login' component={LoginScreen}  />
                    <Route  path='/register' component={RegisterScreen}  />
                    <Route  path='/profile' component={ProfileScreen}  />
                    <Route  path='/municipal/' component={MunicipalScreen} />   
                    <Route  path='/printables/flood/' component={PrintFloods} />
                    <Route  path='/municipalDetails/:municipal_id/' component={MunicipalDetails} />
                    <Route  path='/barangayDetails/:barangay_id/' component={BarangayDetails} />          
                    <Route  path='/family/' component={FamilyDashboardScreen} />
                    <Route  path='/person/' component={MunicipalGraphScreen} />
                    <Route  path='/household/' component={HouseholdDashboardScreen} />
                    <Route  path='/flood/' component={FloodDashboardScreen} />
                    <Route  path='/aggregate/' component={AggregatePerMun} />
                    <Route  path='/map/flood/' component={FloodMap} />
                    <Route  path='/map/ril/' component={RilMap} />
                    <Route  path='/map/stormsurge/' component={StormSurgeMap} />
                    <Route  path='/graph/flood/' component={FLoodGraph} />
                    <Route  path='/graph/malnourished/' component={MalnourishedGraph} />
                    <Route  path='/graph/ril/' component={RilGraph} />
                    <Route  path='/graph/stormsurge/' component={StormSurgeGraph} />    
                    
            </div>
          </div> 
      <FooterSmartAdmin/>  
    </Router >
    </>
  );
}

export default App;
