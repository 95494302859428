import axios from 'axios'
import {HOUSEHOLD_LIST_FAIL,HOUSEHOLD_LIST_REQUEST,HOUSEHOLD_LIST_SUCCESS,
         BARANGAY_HOUSEHOLDS_FAIL,BARANGAY_HOUSEHOLDS_REQUEST,BARANGAY_HOUSEHOLDS_SUCCESS,
         BARANGAY_HOUSEHOLDS_INFO_FAIL,BARANGAY_HOUSEHOLDS_INFO_REQUEST,BARANGAY_HOUSEHOLDS_INFO_SUCCESS
        } from '../constant/householdConstants'


export const listHouseholds = () => async (dispatch) => {
    try{
        dispatch({type:HOUSEHOLD_LIST_REQUEST})

        const {data} = await axios.get('/api/households/')

        dispatch({
            type:HOUSEHOLD_LIST_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:HOUSEHOLD_LIST_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}


export const getBarangayHouseholds = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: BARANGAY_HOUSEHOLDS_REQUEST
        })
      
        const {data} = await axios.get(`/api/households/${id}/brgy/`)
        
        dispatch({
            type: BARANGAY_HOUSEHOLDS_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: BARANGAY_HOUSEHOLDS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getHouseholdInfo = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: BARANGAY_HOUSEHOLDS_INFO_REQUEST
        })
      
        const {data} = await axios.get(`/api/households/${id}/info/`)
        
        dispatch({
            type: BARANGAY_HOUSEHOLDS_INFO_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type: BARANGAY_HOUSEHOLDS_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}