import React from 'react'
import OverAllFloodGraph from '../graph/OverAllFloodGraph'

function FloodDashboardScreen() {
  return (
    <div>
      <OverAllFloodGraph/>
    </div>
  )
}

export default FloodDashboardScreen
