import React , {useState,useEffect,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'

import ReactDOMServer from 'react-dom/server';

import {getFloodReportPerMunicipality} from '../actions/reportActions'
import { listMunicipal } from '../actions/municipalActions'
import adn_flood from '../shapefile/FloodADN.json'
import barangaymap from '../shapefile/Barangay.json'

import { MapContainer, TileLayer, Marker, GeoJSON} from 'react-leaflet'
import L,{Icon,divIcon} from 'leaflet'

import OverAllFloodGraph from '../graph/OverAllFloodGraph';

export default function PrintFloods() {

  const dispatch = useDispatch()

  const currentDate = new Date();

  // Format the date as you desire (e.g., "March 4, 2024")
  const formattedDate = currentDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });


  const setColor = ({ properties }) => {
    return { weight: 1,color:'white' };
  };

  const customMarkerIcon = (name) =>
    divIcon({
      html: name,
      className: "icon"
    });


  const municipalist = useSelector(state => state.municipalListReducers)
  const {municipals} = municipalist

  const floodpermunicipalityreports = useSelector(state => state.floodReportPerMunicipalityReducer)
  const {floodReportPerMunicipality,success} = floodpermunicipalityreports 


  useEffect(() => {

    dispatch(getFloodReportPerMunicipality())
    dispatch(listMunicipal())
  },[dispatch])   





    const printPage1 = () => {

      

        window.print();
      };
  return (
    <div id="printsw"  style={{ overflowY: 'scroll', height: 640, border: '1px solid #ccc' }}>
        <article className="col-sm-12 col-md-10 col-lg-10" id="pds-page-request-result">
          <div className="table-responsive" id="print-pds-page-1" style={{}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onClick={printPage1} className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Page 1</button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac1" className="printareac1">
              <center>
                <table width="820px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <tbody>
                    {/* <tr>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Calibri', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em'}}>{formattedDate}</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Calibri', fontSize: '9pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em'}}>{formattedDate}</div>
                      </td>
                    </tr> */}
                    <tr>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial Black', fontSize: '22pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', marginTop: '0.3em', paddingBottom: 2}}>&nbsp;&nbsp;&nbsp;&nbsp;<img src="/images/adn-logo.png" alt="adn-logo" style={{width: 50, marginRight: 30}} /></div>
                      </td>
                    </tr>
                    <tr style={{ lineHeight: '0.30cm' }}>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial', fontSize: '10pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center'}}>
                          Republika ng Pilipinas
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial', fontSize: '10pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center'}}>
                       Province of Agusan del Norte
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td colSpan={9} style={{border: 'none'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '9pt', marginLeft: '0.3em', textAlign: 'left'}}>Print legibly. Tick appropriate boxes (<input type="checkbox" disableds />) and use separate sheet if necessary. Indicate N/A if not applicable.  DO NOT ABBREVIATE.<div>
                          </div></div></td>
                      <td colSpan={1} style={{backgroundColor: 'gray', color: 'black', border: '1px solid black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', marginLeft: '0.3em', textAlign: 'left'}}>1. CS ID No.<div>
                          </div></div></td>
                      <td colSpan={2} style={{border: '1px solid black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', marginLeft: '0.3em', textAlign: 'left'}}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <div>
                          </div></div></td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>AGUSAN DEL NORTE</div>
                      </td>
                    </tr> */}
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white',height:'20px !important'}}>
                       
                    <MapContainer center={[8.947405262757629, 125.54603678275947]} zoom={10} scrollWheelZoom={true}  zoomControl={false} >
                    <TileLayer  url="https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png" />
                    <GeoJSON data={barangaymap} style={setColor} key={barangaymap.BrgyPSGC} />   
                    {adn_flood.features && adn_flood.features.map((feature, index) => 
                      
                      
                      <GeoJSON

                        data={feature}
                        color={feature.properties.Susc_Ratin === "HF" ? "#3c46c8" : feature.properties.Susc_Ratin === "LF" ? "#f5dcf5" : feature.properties.Susc_Ratin === "MF" ? "#783cc8" : "#28286e"} 
                        weight={2}
                        opacity={1}
                        fillOpacity={0.8}
                        dashArray={3}
                      ></GeoJSON>
                    
                    )}  

                    

                    </MapContainer>
                 
                    </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'center', padding: 1}}>FLOOD HAZARD </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          Municipality
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>susceptibility</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>household</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>Family</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>Male</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25}}>
                        <div id="family-background-s_surname" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '10pt', fontStyle: 'normal', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>Female</div>
                      </td>
                      {/* <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          23. NAME of CHILDREN <span style={{fontSize: '7pt'}}> (Write full name and list all)</span>
                        </div>
                      </td> */}
                      {/* <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          DATE OF BIRTH (mm/dd/yyyy)
                        </div>
                      </td> */}
                    </tr>

                    {municipals && municipals.map((municipal, index) => (
                      <tr key={index} >
                        <td colSpan={2} style={{ border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 25 }}>
                          <div style={{ textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', fontWeight: 'bold', marginLeft: '1.4em', textAlign: 'left', padding: 1 }}>{municipal.munname}</div>
                        </td>
                        <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.flood_id}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.household_number}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.families}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.male}</label><br />
                            </td></tr>
                          ))}
                          </td>
                          <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        {Array.isArray(floodReportPerMunicipality) && floodReportPerMunicipality
                          .filter((flood) => flood.psgccode === municipal.psgccode)
                          .map((filteredFlood, index) => (
                            <tr style={{borderBottom: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}><td style={{width:'150px'}}>
                            <label style={{textTransform: 'uppercase',fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', marginTop: '-1em', marginLeft: '0.9em', textAlign: 'center', padding: 1}}>{filteredFlood.female}</label><br />
                            </td></tr>
                          ))}
                          </td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>GRAPH</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={12} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                           {/* <OverAllFloodGraph/> */}
                        </div>
                      </td>     
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>GRAPH 2</div>
                      </td>
                    </tr>


                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>From</div>
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>To</div>
                        </div>
                      </td>
                    </tr>
                   
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>ELEMENTARY</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>CABADBARAN SOUTH CENTRAL ELEMENTARY SCHOOL</div>
                      </td>
                      <td colSpan={3} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>ELEMENTARY</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>1998</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2004</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-units_earned_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '7pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>GRADUATED</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-year_graduated_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2004</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-honors_received_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>N/A</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>SECONDARY</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-name_of_school_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>CANDELARIA INSTITUTE</div>
                      </td>
                      <td colSpan={3} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-course_id_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>SECONDARY EDUCATION</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_from_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2004</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-poa_to_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2008</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-units_earned_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '7pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>GRADUATED</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-year_graduated_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2008</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-honors_received_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>N/A</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>VOCATIONAL/<br />TRADE COURSE</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-vocational-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td colSpan={3} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-vocational-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-vocational-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-vocational-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-vocational-units_earned_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '7pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-vocational-year_graduated_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-vocational-honors_received_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>COLLEGE</div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-college-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>CARAGA STATE UNIVERSITY (MAIN CAMPUS)</div>
                      </td>
                      <td colSpan={3} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-college-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>BACHELOR OF SCIENCE IN INFORMATION TECHNOLOGY (BSIT)</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-college-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2009</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-college-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2014</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-college-units_earned_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '7pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>N/A</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-college-year_graduated_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2014</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-college-honors_received_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>N/A</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} style={{height: 38, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '1.4em', textAlign: 'left', padding: 1}}>GRADUATE STUDIES </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-graduate-name_of_school_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>CARAGA STATE UNIVERSITY (MAIN CAMPUS)</div>
                      </td>
                      <td colSpan={3} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-graduate-course_id_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>MASTER OF SCIENCE IN INFORMATION TECHNOLOGY</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-graduate-poa_from_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2018</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-graduate-poa_to_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>2019</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-graduate-units_earned_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '7pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>15 UNITS</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-graduate-year_graduated_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>N/A</div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="family-background-graduate-honors_received_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>N/A</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#eaeaea', color: 'red'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', padding: 1}}>
                          (Continue on separate sheet if necessary)
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 40}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>SIGNATURE</div>
                      </td>
                      <td colSpan={4} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td colSpan={1} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>DATE</div>
                      </td>
                      <td colSpan={5} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}> 11/21/2021 </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'normal', textAlign: 'right'}}>CS FORM 212 (Revised 2017), Page 1 of 4</div>
                      </td>
                    </tr>
                  </tbody></table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-page-2" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage2();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Page 2</button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac2" className="printareac2">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <tbody><tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>IV.  CIVIL SERVICE ELIGIBILITY</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>27.</div> 
                          <div style={{display: 'inline', marginLeft: '1em'}}>CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER </div>
                          <div style={{display: 'inline', marginLeft: '7em'}}>SPECIAL LAWS/ CES/ CSEE<div>
                              <div style={{display: 'inline', marginLeft: '3.4em'}}>BARANGAY ELIGIBILITY/ DRIVER'S LICENSE</div>
                            </div>
                          </div></div></td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>RATING <br /> (If Applicable)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>DATE OF EXAMINATION/ CONFERMENT</div>
                        </div>
                      </td>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>PLACE OF EXAMINATION/ CONFERMENT</div>
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>LICENSE (if applicable)</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          NUMBER
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          Date of Validity
                        </div>
                      </td>
                    </tr>
                  </tbody><tbody id="print-pds-eligibility-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                  <tbody><tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#eaeaea', color: 'red'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', padding: 1}}>
                          (Continue on separate sheet if necessary)
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          V. WORK EXPERIENCE <br />
                          <label style={{fontSize: '8pt'}}>(Include private employment.  Start from your recent work) Description of duties should be indicated in the attached Work Experience sheet.</label>
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          28. <span style={{marginLeft: '1em'}}>INCLUSIVE DATES
                            <div style={{textAlign: 'center'}}>(mm/dd/yyyy)<div>
                              </div>
                            </div></span></div></td>
                      <td colSpan={3} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>POSITION TITLE <br /> (Write in full/Do not abbreviate)</div>
                        </div>
                      </td>
                      <td colSpan={3} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>DEPARTMENT/ AGENCY/ OFFICE/ COMPANY  <br /> (Write in full/Do not abbreviate)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>MONTHLY SALARY</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>SALARY/ JOB/ PAY GRADE (if applicable)&amp; STEP  (Format "00-0")/ INCREMENT</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>STATUS OF <span style={{fontSize: '7.5pt'}}>APPOINTMENT</span></div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>GOV'T SERVICE <br /> (Y/ N)</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          From
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          To
                        </div>
                      </td>
                    </tr>
                  </tbody><tbody id="print-pds-work-experience-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                  <tbody><tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#eaeaea', color: 'red'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', padding: 1}}>
                          (Continue on separate sheet if necessary)
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 40}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 10}}>SIGNATURE</div>
                      </td>
                      <td colSpan={4} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td colSpan={1} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>DATE</div>
                      </td>
                      <td colSpan={5} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}> 11/21/2021 </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'normal', textAlign: 'right'}}>CS FORM 212 (Revised 2017), Page 2 of 4</div>
                      </td>
                    </tr>
                  </tbody></table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-page-3" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage3();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Page 3</button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac3" className="printareac3">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <tbody><tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          VI. VOLUNTARY WORK OR INVOLVEMENT IN CIVIC/ NON-GOVERNMENT/ PEOPLE/ VOLUNTARY ORGANIZATION/S
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>29.</div> <div style={{display: 'inline', marginLeft: '3em'}}>NAME &amp; ADDRESS OF ORGANIZATION </div>
                          <div style={{marginLeft: '10em'}}>(Write in full)</div>
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>INCLUSIVE DATES <br /> (mm/dd/yyyy)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>NUMBER OF HOURS</div>
                        </div>
                      </td>
                      <td colSpan={5} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>POSITION/ NATURE OF WORK</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          From
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          To
                        </div>
                      </td>
                    </tr>
                  </tbody><tbody id="print-pds-voluntary-work-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                  <tbody><tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#eaeaea', color: 'red'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', padding: 1}}>
                          (Continue on separate sheet if necessary)
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          VII. LEARNING AND DEVELOPMENT (L&amp;D) INTERVENTIONS/TRAINING PROGRAMS ATTENDED <br />
                          <label style={{fontSize: '8pt'}}>(Start from the most recent L&amp;D/training program and include only the relevant L&amp;D/training taken for the last five (5) years for Division Chief/Executive/Managerial positions)</label>
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>30.</div> <div style={{display: 'inline', marginLeft: '3.3em'}}>TITLE OF LEARNING AND DEVELOPMENT</div> <div style={{display: 'inline', marginLeft: '5em'}}>INTERVENTIONS/TRAINING PROGRAMS</div>
                          <div style={{marginLeft: '11em'}}>(Write in full)</div>
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>INCLUSIVE DATES OF ATTENDANCE <br /> (mm/dd/yyyy)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>NUMBER OF HOURS</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>Type of LD</div>
                        </div>
                      </td>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>CONDUCTED/ SPONSORED BY <br /> (Write in full)</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          From
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          To
                        </div>
                      </td>
                    </tr>
                  </tbody><tbody id="print-pds-learning-and-development-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                  <tbody><tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#eaeaea', color: 'red'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', padding: 1}}>
                          (Continue on separate sheet if necessary)
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={3} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>31. <span style={{marginLeft: '2.5em'}}>SPECIAL SKILLS and HOBBIES</span></div>
                        </div>
                      </td>
                      <td colSpan={5} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>32.</div> <div style={{display: 'inline', marginLeft: '5em'}}>NON-ACADEMIC DISTINCTIONS/ RECOGNITION</div>
                          <div style={{marginLeft: '14em'}}>(Write in full)</div>
                        </div>
                      </td>
                      <td colSpan={4} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>33.</div> <div style={{display: 'inline', marginLeft: '2em'}}>MEMBERSHIP IN ASSOCIATION/ ORGANIZATION</div>
                          <div style={{marginLeft: '11em'}}>(Write in full)</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-special_skill_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={5} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={4} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-membership_0" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-special_skill_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={5} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={4} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-membership_1" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-special_skill_2" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={5} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_2" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={4} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-membership_2" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-special_skill_3" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={5} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_3" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={4} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-membership_3" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-special_skill_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={5} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={4} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-membership_4" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-special_skill_5" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={5} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_5" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={4} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-membership_5" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-special_skill_6" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={5} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-non_academic_6" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                      <td colSpan={4} style={{height: 38, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="other-information-membership_6" style={{textTransform: 'uppercase', fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#eaeaea', color: 'red'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', padding: 1}}>
                          (Continue on separate sheet if necessary)
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', height: 40}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 10}}>SIGNATURE</div>
                      </td>
                      <td colSpan={4} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}} />
                      </td>
                      <td colSpan={1} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}>DATE</div>
                      </td>
                      <td colSpan={5} style={{border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center', padding: 1}}> 11/21/2021 </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'normal', textAlign: 'right'}}>CS FORM 212 (Revised 2017), Page 3 of 4</div>
                      </td>
                    </tr>
                  </tbody></table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-page-4" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage4();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Page 4</button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac4" className="printareac4">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <tbody><tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          34. Are you related by consanguinity or affinity to the appointing or recommending <br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>authority, or to the chief of bureau or office or to the person who has immediate </span><br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>supervision over you in the Office, Bureau or Department where you will be</span><br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>apppointed,</span>
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black'}} />
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', marginLeft: '1.7em'}}>
                          a. within the third degree?
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="34_a_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="34_a_2" disabled> NO </label> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="34_a_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="34_a_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', marginLeft: '1.7em'}}>
                          b. within the fourth degree (for Local Government Unit - Career Employees)
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="34_b_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="34_b_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="34_b_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="34_b_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details:</span><br />
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '10em', color: 'black', textDecoration: 'underline'}} id="34_b_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          35. a. Have you ever been found guilty of any administrative offense?
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="35_a_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="35_a_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="35_a_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="35_a_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details:</span><br />
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '10em', color: 'black', textDecoration: 'underline'}} id="35_a_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', marginLeft: '1.7em'}}>
                          b. Have you been criminally charged before any court?
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="35_b_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="35_b_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="35_b_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="35_b_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details:</span><br />
                            <label style={{padding: 1, marginLeft: '13em'}}>Date Filed:</label>          <label style={{padding: 1, color: 'black', textDecoration: 'underline'}} id="35_b_date_filed">__________</label>
                            <label style={{padding: 1, marginLeft: '10em'}}>Status of Case/s:</label>    <label style={{textTransform: 'uppercase', padding: 1, color: 'black', textDecoration: 'underline'}} id="35_b_status_cases">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          36. Have you ever been convicted of any crime or violation of any law, decree, <br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>ordinance or regulation by any court or tribunal?</span>
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="36_a_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="36_a_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="36_a_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="36_a_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details:</span><br />
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '10em', color: 'black', textDecoration: 'underline'}} id="36_a_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          37. Have you ever been separated from the service in any of the following modes: <br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>resignation, retirement, dropped from the rolls, dismissal, termination, end of term, </span><br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>finished contract or phased out (abolition) in the public or private sector?</span>
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="37_a_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="37_a_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="37_a_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="37_a_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details:</span><br />
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '10em', color: 'black', textDecoration: 'underline'}} id="37_a_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          38. a. Have you ever been a candidate in a national or local election held within the last <br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>year (except Barangay election)?</span>
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="38_a_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="38_a_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="38_a_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="38_a_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details:</span><br />
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '10em', color: 'black', textDecoration: 'underline'}} id="38_a_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', marginLeft: '1.7em'}}>
                          b. Have you resigned from the government service during the three (3)-month
                          period before the last election to promote/actively campaign
                          for a national or local candidate?
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="38_b_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="38_b_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="38_b_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="38_b_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details:</span><br />
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '10em', color: 'black', textDecoration: 'underline'}} id="38_b_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          39. Have you acquired the status of an immigrant or permanent resident of another <br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>country?</span>
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="39_a_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="39_a_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="39_a_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="39_a_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, give details (country):</span><br />
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '15em', color: 'black', textDecoration: 'underline'}} id="39_a_country_code">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          40. Pursuant to: (a) Indigenous People's Act (RA 8371); (b) Magna Carta for disabled <br />
                          <span style={{padding: 1, marginLeft: '1.7em'}}>Persons (RA 7277); and (c) Solo Parents Welfare Act of 2000 (RA 8972),<br /></span>
                          <span style={{padding: 1, marginLeft: '1.7em'}}>please answer the following items:</span>
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black'}} />
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', marginLeft: '1.7em'}}>
                          a. Are you a member of any indigenous group?
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="40_a_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="40_a_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="40_a_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="40_a_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, please specify:</span>
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '4em', color: 'black', textDecoration: 'underline'}} id="40_a_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', marginLeft: '1.7em'}}>
                          b. Are you a person with disability?
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: 'none', borderRight: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="40_b_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="40_b_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="40_b_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="40_b_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, please specify ID No:</span>
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '1em', color: 'black', textDecoration: 'underline'}} id="40_b_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderBottom: '2px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', marginLeft: '1.7em'}}>
                          c. Are you a solo parent?
                        </div>
                      </td>
                      <td colSpan={5} style={{padding: 1, border: '1px solid black', borderTop: 'none', borderRight: '2px solid black', borderBottom: '2px solid black', backgroundColor: '#fff', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'left'}}>
                          {/* <label style="padding: 1px; color: black;"><input type="checkbox" id="40_c_1" disabled> YES </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label style="padding: 1px; color: black;"><input type="checkbox" id="40_c_2" disabled> NO </label><br> */}
                          <div style={{width: '100%'}}>
                            <div style={{width: '50%', float: 'left'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="40_c_1" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-16mm'}}>
                                  YES
                                </div>
                              </div>
                            </div>
                            <div style={{width: '50%', float: 'left', marginLeft: '-30mm'}}>
                              <div style={{width: '100%'}}>
                                <div style={{width: '50%', float: 'left'}}>
                                  <div id="40_c_2" />
                                </div>
                                <div style={{width: '50%', float: 'left', marginTop: '1mm', marginLeft: '-6mm'}}>
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div style={{width: '100%', float: 'left'}}>
                            <span style={{marginLeft: '0.5em'}}>If YES, please specify ID No:</span>
                            <label style={{textTransform: 'uppercase', padding: 1, marginLeft: '1em', color: 'black', textDecoration: 'underline'}} id="40_c_details">__________</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9} style={{padding: 1, border: '2px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'middle'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          41. REFERENCES <span style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'bold', color: 'red'}}>(Person not related by consanguinity or affinity to applicant/appointee)
                          </span></div>
                      </td>
                      <td colSpan={3} rowSpan={7}>
                        <table width="100%" style={{padding: 1, border: 'none', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                          <tbody><tr>
                              <td colSpan={2} style={{padding: 1, border: 'none'}} />
                              <td colSpan={8} style={{padding: 1, width: '3.8cm', height: '5cm', border: '2px solid black', backgroundColor: '#fff', textAlign: 'center', fontSize: '7pt'}}>
                                {/* <img 
                                      src="https://portal.depedcabadbarancity.org/images/users/615fd96aa8bbb.jpg" 
                                      alt="https://portal.depedcabadbarancity.org/images/users/615fd96aa8bbb.jpg"
                                      style="padding: 1px; width:3.4cm; height:4.5cm;"
                                  > */}
                                ID picture taken within<br />
                                the last  6 months<br />
                                3.5 cm. X 4.5 cm<br />
                                (passport size)<br /><br />
                                With full and handwritten<br />
                                name tag and signature over<br />
                                printed name<br /><br />
                                Computer generated <br />
                                or photocopied picture <br />
                                is not acceptable<br />
                              </td>
                              <td colSpan={2} style={{padding: 1, border: 'none'}} />
                            </tr>
                            <tr>
                              <td colSpan={2} style={{padding: 1, border: 'none'}} />
                              <td colSpan={8} style={{padding: 5, border: 'none', textAlign: 'center', fontSize: '8pt'}}>
                                PHOTO
                              </td>
                              <td colSpan={2} style={{padding: 1, border: 'none'}} />
                            </tr>
                            <tr>
                              <td colSpan={12} style={{padding: 1, border: 'none', height: '0.4cm'}} />
                            </tr>
                            <tr>
                              <td colSpan={1} style={{padding: 1, border: 'none'}} />
                              <td colSpan={10} style={{padding: 1, height: '4cm', border: '2px solid black', backgroundColor: '#fff', textAlign: 'center'}}>
                                &nbsp;
                              </td>
                              <td colSpan={1} style={{padding: 1, border: 'none'}} />
                            </tr>
                            <tr>
                              <td colSpan={1} style={{padding: 1, border: 'none'}} />
                              <td colSpan={10} style={{padding: 1, border: '2px solid black', backgroundColor: '#eaeaea', color: 'black', textAlign: 'center', fontSize: '8pt'}}>
                                Right Thumbmark
                              </td>
                              <td colSpan={1} style={{padding: 1, border: 'none'}} />
                            </tr>
                          </tbody></table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{padding: 1, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center'}}>
                          NAME
                        </div>
                      </td>
                      <td colSpan={3} style={{padding: 1, border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center'}}>
                          ADDRESS
                        </div>
                      </td>
                      <td colSpan={3} style={{padding: 1, border: '1px solid black', borderRight: '2px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{padding: 1, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center'}}>
                          TEL. NO.
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_name_0" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_address_0" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_tel_no_0" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_name_1" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_address_1" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_tel_no_1" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_name_2" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_address_2" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                      <td colSpan={3} style={{padding: 1, height: 40, border: '1px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div id="reference_tel_no_2" style={{textTransform: 'uppercase', padding: 1, fontFamily: 'Arial', fontSize: '8.5pt', fontStyle: 'normal', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9} style={{padding: 1, border: '2px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'middle'}}>
                        <div style={{padding: 2, fontFamily: 'Arial', fontSize: '9pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left'}}>
                          <div style={{float: 'left', width: '4%', textAlign: 'left'}}>
                            42.
                          </div>
                          <div style={{float: 'left', width: '96%', textAlign: 'justify', textJustify: 'inter-word'}}>
                            I declare under oath that I have personally accomplished this Personal Data Sheet which is a true, correct and complete
                            statement pursuant to the provisions of pertinent laws, rules and regulations of the Republic of the Philippines. 
                            I authorize the agency head/ authorized representative to verify/validate the contents stated herein. 
                            I agree that any misrepresentation made in this document and its attachments shall cause the filing of administrative/criminal case/s against me.
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style={{padding: 10, border: '1px solid black', borderRight: 'none', verticalAlign: 'top'}}>
                        <t style={{lineHeight: '0.30cm'}} r>
                        </t><table width="100%;" style={{padding: 1, border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                          <tbody><tr style={{lineHeight: '0.30cm'}}>
                              <td style={{fontSize: '7.5pt', padding: 1, height: 40, backgroundColor: '#eaeaea', color: 'black', border: '1px solid black', borderRight: 'none', verticalAlign: 'top'}}>
                                <label>
                                  Government Issued ID <br /> (i.e.Passport, GSIS, SSS, PRC, Driver's License, etc.) <br />
                                  PLEASE INDICATE ID Number and Date of Issuance
                                </label>
                              </td>
                            </tr>
                            <tr style={{lineHeight: '0.30cm'}}>
                              <td style={{padding: 1, height: 40, border: '1px solid black', borderRight: 'none', verticalAlign: 'middle'}}>
                                <div style={{float: 'left', width: '50%', textAlign: 'left'}}>
                                  <label style={{fontSize: '8pt'}}>Government Issued ID:</label>
                                </div>
                                <div style={{float: 'right', width: '50%', textAlign: 'left'}}>
                                  <label style={{textTransform: 'uppercase', fontSize: '8pt'}} id="p_gov_issued_id">Voters ID</label>
                                </div>
                              </td>
                            </tr>
                            <tr><td style={{padding: 1, height: 40, border: '1px solid black', borderRight: 'none', verticalAlign: 'middle'}}>
                                <div style={{float: 'left', width: '50%', textAlign: 'left'}}>
                                  <label style={{fontSize: '8pt'}}>ID/License/Passport No.:
                                  </label></div>
                                <div style={{float: 'right', width: '50%', textAlign: 'left'}}>
                                  <label style={{textTransform: 'uppercase', fontSize: '8pt'}} id="p_gov_issued_id_no">17816323696689</label>
                                </div>
                              </td>
                            </tr>
                            <tr style={{lineHeight: '0.30cm'}}>
                              <td style={{padding: 1, height: 40, border: '1px solid black', borderRight: 'none', verticalAlign: 'middle'}}>
                                <div style={{float: 'left', width: '50%', textAlign: 'left'}}>
                                  <label style={{fontSize: '8pt'}}>Date/Place of Issuance:
                                  </label></div>
                                <div style={{float: 'right', width: '50%', textAlign: 'left'}}>
                                  <label style={{textTransform: 'uppercase', fontSize: '8pt'}} id="p_date_place_issuance">17816323696689</label>
                                </div>
                              </td>
                            </tr>
                          </tbody></table>
                      </td>
                      <td colSpan={5} style={{padding: 10, border: '1px solid black', borderLeft: 'none', verticalAlign: 'top'}}>
                        <table width="100%;" style={{padding: 1, border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                          <tbody><tr>
                              <td style={{padding: 1, height: 100, border: '1px solid black', borderRight: 'none', verticalAlign: 'top'}} />
                            </tr>
                            <tr>
                              <td style={{fontSize: '8pt', padding: 1, height: 20, backgroundColor: '#eaeaea', color: 'black', border: '1px solid black', borderRight: 'none', verticalAlign: 'middle', textAlign: 'center'}}>
                                Signature (Sign inside the box)
                              </td>
                            </tr>
                            <tr>
                              <td style={{fontSize: '8pt', padding: 1, height: 20, border: '1px solid black', borderRight: 'none', verticalAlign: 'middle', textAlign: 'center'}}>
                                11/21/2021
                              </td>
                            </tr>
                            <tr>
                              <td style={{fontSize: '8pt', padding: 1, height: 20, backgroundColor: '#eaeaea', color: 'black', border: '1px solid black', borderRight: 'none', verticalAlign: 'middle', textAlign: 'center'}}>
                                Date Accomplished    
                              </td>
                            </tr>
                          </tbody></table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} style={{padding: 10, height: 40, border: '2px solid black', borderBottom: 'none', fontSize: '9pt', textAlign: 'center'}}>
                        SUBSCRIBED AND SWORN to before me this _______________, affiant exhibiting his/her validly issued government ID as indicated above.
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{padding: 1, borderRight: 'none', borderTop: 'none'}} />
                      <td colSpan={6} style={{padding: '5px 0 15px 0', borderLeft: 'none', borderRight: 'none', borderTop: 'none'}}>
                        <table width="100%" style={{padding: 1, border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                          <tbody><tr>
                              <td colSpan={12} style={{padding: 1, height: 100, border: '2px solid black'}}>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={12} style={{fontSize: '8pt', padding: 1, border: '2px solid black', backgroundColor: '#eaeaea', color: 'black', textAlign: 'center'}}>
                                Person Administering Oath
                              </td>
                            </tr>
                          </tbody></table>
                      </td>
                      <td colSpan={3} style={{padding: 1, borderLeft: 'none', borderTop: 'none'}} />
                    </tr>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#fff', color: 'black'}}>
                        <div style={{fontFamily: 'Arial', fontSize: '8pt', fontStyle: 'italic', fontWeight: 'normal', textAlign: 'right'}}>CS FORM 212 (Revised 2017), Page 4 of 4</div>
                      </td>
                    </tr>
                  </tbody></table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-educational-background" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage5();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Educational Background <i>(Continuation)</i> </button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac5" className="text-center">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', padding: 1, border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <thead> 
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>III.  EDUCATIONAL BACKGROUND</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>26.</div> <div style={{display: 'inline', marginLeft: '2em'}}>LEVEL</div>
                        </div>
                      </td>
                      <td colSpan={2} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>NAME OF SCHOOL <br /> (Write in full)</div>
                        </div>
                      </td>
                      <td colSpan={3} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>BASIC EDUCATION/DEGREE/COURSE <br /> (Write in full)</div>
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>PERIOD OF ATTENDANCE</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>HIGHEST LEVEL/ UNITS EARNED (if not graduated)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>YEAR GRADUATED</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>SCHOLARSHIP/ ACADEMIC HONORS RECEIVED</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>From</div>
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          <div style={{textAlign: 'center'}}>To</div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody id="print-pds-vocational-2-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                  <tbody id="print-pds-college-2-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                  <tbody id="print-pds-graduate-studies-2-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                </table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-eligibility" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage6();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Civil Service Eligibility <i>(Continuation)</i> </button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac6" className="text-center">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <thead>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>IV.  CIVIL SERVICE ELIGIBILITY</div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>27.</div> 
                          <div style={{display: 'inline', marginLeft: '1em'}}>CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER </div>
                          <div style={{display: 'inline', marginLeft: '7em'}}>SPECIAL LAWS/ CES/ CSEE<div>
                              <div style={{display: 'inline', marginLeft: '3.4em'}}>BARANGAY ELIGIBILITY/ DRIVER'S LICENSE</div>
                            </div>
                          </div></div></td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>RATING <br /> (If Applicable)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>DATE OF EXAMINATION/ CONFERMENT</div>
                        </div>
                      </td>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>PLACE OF EXAMINATION/ CONFERMENT</div>
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>LICENSE (if applicable)</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          NUMBER
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          Date of Validity
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody id="print-pds-eligibility-2-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                </table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-work-experience" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage7();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Work Experience <i>(Continuation)</i> </button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac7" className="text-center">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <thead>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          V. WORK EXPERIENCE <br />
                          <label style={{fontSize: '8pt'}}>(Include private employment.  Start from your recent work) Description of duties should be indicated in the attached Work Experience sheet.</label>
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          28. <span style={{marginLeft: '1em'}}>INCLUSIVE DATES
                            <div style={{textAlign: 'center'}}>(mm/dd/yyyy)<div>
                              </div>
                            </div></span></div></td>
                      <td colSpan={3} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>POSITION TITLE <br /> (Write in full/Do not abbreviate)</div>
                        </div>
                      </td>
                      <td colSpan={3} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>DEPARTMENT/ AGENCY/ OFFICE/ COMPANY  <br /> (Write in full/Do not abbreviate)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>MONTHLY SALARY</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>SALARY/ JOB/ PAY GRADE (if applicable)&amp; STEP  (Format "00-0")/ INCREMENT</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>STATUS OF <span style={{fontSize: '7.5pt'}}>APPOINTMENT</span></div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>GOV'T SERVICE <br /> (Y/ N)</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          From
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          To
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody id="print-pds-work-experience-2-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                </table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-voluntary-work" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage8();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Voluntary Work <i>(Continuation)</i> </button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac8" className="text-center">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <thead>
                    <tr>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          VI. VOLUNTARY WORK OR INVOLVEMENT IN CIVIC/ NON-GOVERNMENT/ PEOPLE/ VOLUNTARY ORGANIZATION/S
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>29.</div> <div style={{display: 'inline', marginLeft: '3em'}}>NAME &amp; ADDRESS OF ORGANIZATION </div>
                          <div style={{marginLeft: '10em'}}>(Write in full)</div>
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>INCLUSIVE DATES <br /> (mm/dd/yyyy)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>NUMBER OF HOURS</div>
                        </div>
                      </td>
                      <td colSpan={5} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>POSITION/ NATURE OF WORK</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          From
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          To
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody id="print-pds-voluntary-work-2-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                </table>
              </center>
            </div></div>
          <div className="table-responsive" id="print-pds-learning-development" style={{display: 'none'}}><div className="form-group">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <button type="button" onclick="printDivPage9();" className="btn btn-primary btn-sm"><i className="fa fa-print" /> Print Learning &amp; Development <i>(Continuation)</i> </button>
              </div>
            </div>
            <br />
            <br />
            <div id="printareac9" className="text-center">
              <center>
                <table width="816px" style={{fontFamily: 'Arial', border: '2px solid black', tableLayout: 'fixed', wordWrap: 'break-word', borderSpacing: 0, borderCollapse: 'collapse'}}>
                  <thead>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={12} style={{border: '2px solid black', backgroundColor: '#969696', color: 'white'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '11pt', fontStyle: 'italic', fontWeight: 'bold', marginLeft: '0.3em', textAlign: 'left', padding: 1}}>
                          VII. LEARNING AND DEVELOPMENT (L&amp;D) INTERVENTIONS/TRAINING PROGRAMS ATTENDED <br />
                          <label style={{fontSize: '8pt'}}>(Start from the most recent L&amp;D/training program and include only the relevant L&amp;D/training taken for the last five (5) years for Division Chief/Executive/Managerial positions)</label>
                        </div>
                      </td>
                    </tr>
                    <tr style={{lineHeight: '0.30cm'}}>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black', verticalAlign: 'top'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'left', padding: 1}}>
                          <div style={{display: 'inline'}}>30.</div> <div style={{display: 'inline', marginLeft: '3.3em'}}>TITLE OF LEARNING AND DEVELOPMENT</div> <div style={{display: 'inline', marginLeft: '5em'}}>INTERVENTIONS/TRAINING PROGRAMS</div>
                          <div style={{marginLeft: '11em'}}>(Write in full)</div>
                        </div>
                      </td>
                      <td colSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>INCLUSIVE DATES OF ATTENDANCE <br /> (mm/dd/yyyy)</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>NUMBER OF HOURS</div>
                        </div>
                      </td>
                      <td rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>Type of LD</div>
                        </div>
                      </td>
                      <td colSpan={4} rowSpan={2} style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          <div style={{textAlign: 'center'}}>CONDUCTED/ SPONSORED BY <br /> (Write in full)</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          From
                        </div>
                      </td>
                      <td style={{border: '1px solid black', backgroundColor: '#eaeaea', color: 'black'}}>
                        <div style={{fontFamily: 'Arial Narrow', fontSize: '8pt', fontStyle: 'normal', fontWeight: 'normal', textAlign: 'center', padding: 1}}>
                          To
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody id="print-pds-learning-development-2-body" style={{textTransform: 'uppercase', lineHeight: '0.30cm'}} />
                </table>
              </center>
            </div></div>
        </article>
    </div>
  )
}


export function printPage1() {
  const printWindow = window.open('', '_blank');

  // Write the content to the new window
  printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
      </head>
      <body>
        <!-- Content of page 1 to be printed -->
        <div id='print-report'>
          ${ReactDOMServer.renderToString(<PrintFloods />)}
        </div>
      </body>
    </html>
  `);

  // Close the document to ensure proper rendering
  printWindow.document.close();

  // Trigger print dialog
  printWindow.print();
}