import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CanvasJSReact from '@canvasjs/react-charts';
import { getFlood } from '../actions/floodActions';
import { getTotalReport } from '../actions/reportActions';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function OverAllFloodGraph() {
  const dispatch = useDispatch();

  const totalrep = useSelector((state) => state.totalReportReducer);
  const { totalReport, error: totalReportError, loading: totalReportLoading } = totalrep;

  const OverAllFlood = useSelector((state) => state.floodReducer);
  const { flood, success: floodSuccess, error: floodError, loading: floodLoading } = OverAllFlood;

  const [personFlood, setPersonFlood] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getFlood());
        await dispatch(getTotalReport());
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

 
  const totalpersonflood = Array.isArray(flood) ? flood.reduce((sum, entry) => sum + (entry.person || 0), 0) : 0;

  // Check for loading state
  if (totalReportLoading || floodLoading) {
    return <div>Loading...</div>;
  }

  // Check for errors
  if (totalReportError || floodError) {
    return <div>Error fetching data. Please try again later.</div>;
  }
  
  console.log(getFlood)

  // Check if data exists before accessing properties
  const total = totalReport && totalReport.length > 0 ? totalReport[0].person : 0;
  const highFloodPercentage = flood[0].person && flood[0].person ? Number((flood[0].person / total) * 100).toFixed(2) : 0;
  const lowFloodPercentage = flood[1].person && flood[1].person ? Number((flood[1].person / total) * 100).toFixed(2) : 0;
  const moderateFloodPercentage = flood[2].person && flood[2].person ? Number((flood[2].person / total) * 100).toFixed(2) : 0;
  const veryHighFloodPercentage = flood[3].person && flood[3].person ? Number((flood[3].person / total) * 100).toFixed(2) : 0;
  const naFloodPercentage = totalpersonflood ? Number((totalpersonflood / total) * 100).toFixed(2) : 0;
  console.log('totalreport',flood[0].person / total)
  const OverAllFloodPie = {
    animationEnabled: true,
    title: {
      text: 'There are ' + naFloodPercentage + '% An individual who is not flooded',
    },
    subtitles: [
      {
        text: 'Population ' + Number(total).toLocaleString(),
        verticalAlign: 'center',
        fontSize: 18,
        dockInsidePlotArea: true,
      },
    ],
    colorSet: 'customColorSet',
    data: [
      {
        type: 'doughnut',
        showInLegend: true,
        indexLabel: '{name}: {y}% or {person} individual',
        dataPoints: [
          { name: 'High', y: highFloodPercentage , person:Number(flood[0].person).toLocaleString()},
          { name: 'Low', y: lowFloodPercentage , person:Number(flood[1].person).toLocaleString()},
          { name: 'Moderate', y: moderateFloodPercentage, person:Number(flood[2].person).toLocaleString() },
          { name: 'Very High', y: veryHighFloodPercentage , person:Number(flood[3].person).toLocaleString()},
          // { name: 'N/A', y: naFloodPercentage },
        ],
      },
    ],
    // height: 250,
    // width: 250,
  };

  OverAllFloodPie.colorSet = 'customColorSet';
  CanvasJS.addColorSet('customColorSet', ['#3c46c8', '#f5dcf5', '#783cc8', '#28286e', 'green']);

  return (<div><CanvasJSChart options={OverAllFloodPie} /></div>)
}

export default OverAllFloodGraph;