import {
    ROOF_FAIL,
    ROOF_REQUEST,
    ROOF_RESET,
    ROOF_SUCCESS,

    WALL_FAIL,
    WALL_REQUEST,
    WALL_RESET,
    WALL_SUCCESS
} from '../constant/libraryConstants'

export const roofReducers = (state = {roof:[]},action) => {
    switch(action.type){
        case ROOF_REQUEST:
            return {loading:true, roof:[]}
        case ROOF_SUCCESS:
            return {loading:false, roof:action.payload}
        case ROOF_FAIL:
            return {loading:false, error:action.payload}
        case ROOF_RESET:
            return {roof:[]}
        default: 
            return state
    }
}

export const wallReducers = (state = {wall:[]},action) => {
    switch(action.type){
        case WALL_REQUEST:
            return {loading:true, wall:[]}
        case WALL_SUCCESS:
            return {loading:false, wall:action.payload}
        case WALL_FAIL:
            return {loading:false, error:action.payload}
        case WALL_RESET:
            return {wall:[]}
        default: 
            return state
    }
}