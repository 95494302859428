import React , {useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CanvasJSReact from '@canvasjs/react-charts';
import { familydPerMun } from '../actions/reportActions'

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function PopulationPerMunCombineGraph() {

  const dispatch = useDispatch()

  const familyNum = useSelector(state => state.familyPerMunReducers)
  const {familymun} = familyNum

  const [chart, setChart] = useState(null);

  useEffect(() => {
    dispatch(familydPerMun())
  },[dispatch])  

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    chart.render();
  };
            const options = {
                    animationEnabled: true,
                    colorSet: "colorSet5",
                    title: {
                      text: "Population Per Municipal",
                    },
                    axisX: {
                            indexLabel:"{x}",
                            labelFontSize: 8
                    },
                    axisY: {
                            indexLabel:"{y}",
                            labelFontSize: 8
                    },
                    toolTip: {
                      shared: true,
                    },
                    legend: {
                      cursor: "pointer",
                      itemclick: toggleDataSeries,
                      verticalAlign: "top",
                    },
                    data: [
                      {
                        type: "column",
                        name: "Person",
                        showInLegend: true,
                        dataPoints:familymun.map((family) => (
                            { label: family.munname,y: family.person}))
                      },
                      {
                        type: "area",
                        name: "Male",
                        markerBorderColor: "blue",
                        markerBorderThickness: 5,
                        showInLegend: true,
                        dataPoints: familymun.map((family) => (
                            { label: family.munname,y: family.male}))
                      },
                      {
                        type: "line",
                        name: "Female",
                        showInLegend: true,
                        dataPoints: familymun.map((family) => (
                            { label: family.munname,y: family.female}))
                      },
                    ],
                  };


  return (
    <div>
      <CanvasJSChart options={options} onRef={(ref) => setChart(ref)} />
    </div>
  )
}

export default PopulationPerMunCombineGraph
