import React, {useState,useEffect ,useRef } from 'react'
import { useDispatch,useSelector} from 'react-redux'

import {getFloodReport} from '../actions/reportActions'
import {puroklistBarangay} from '../actions/barangayActions'

import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function GenderFloodBrgyGraph(props) {
    const dispatch = useDispatch()
    const [floodInfo,setFloodInfo] = useState(null)

    const barangayinfo = useSelector(state => state.brgyInfoAllReducer)
    const {success:successbrgyinfo,brgyinfoall} = barangayinfo

    const purokinfo = useSelector(state => state.barangayPurokListReducers)
    const {success:successpurokinfo,purok} = purokinfo

    const floodreports = useSelector(state => state.floodReportReducer)
    const {floodReport,success:successfloodreports} = floodreports

    useEffect(() => {
      if (props.brgyId && successbrgyinfo) { 
        dispatch(getFloodReport(props.brgyId));
        dispatch(puroklistBarangay(props.brgyId))
      }
    }, [dispatch, props.brgyId, successbrgyinfo]); 
  
    useEffect(() => {
      if (successfloodreports) {
        setFloodInfo(floodReport);
      }
    }, [successfloodreports, floodReport]); 
                
    const barangay_info = Array.isArray(brgyinfoall) && brgyinfoall.find(barangay => barangay.psgccode === props.brgyId)
    
    const [chart, setChart] = useState(null);

    const toggleDataSeries = (e) => {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        chart.render();
      };
      const options = {
        animationEnabled: true,
        colorSet: "colorSet5",
        exportFileName: "gender",
        exportEnabled: true,
        title: {
          text: "Sex Disaggregated",
        },
        axisX: {
                indexLabel:"{x}",
                labelFontSize: 8
        },
        axisY: {
                indexLabel:"{y}",
                labelFontSize: 8
        },
        legend: {
            verticalAlign: "center",
            horizontalAlign: "right",
            reversed: true,
            cursor: "pointer",
                fontSize: 16,
                itemclick:  (e) => toggleDataSeries(e)
        },
        toolTip: {
            shared: true
        },
        data: [
          {
            type: "area",
            name: "Male",
            showInLegend: true,
            dataPoints: (floodInfo || []).map((feature, index) => (
                {
                label:feature.flood_id, 
                y: feature.male,

                
                }))

          },
          {
            type: "area",
            name: "Female",
            showInLegend: true,
            dataPoints: (floodInfo || []).map((feature, index) => (
               
                {
                label:feature.flood_id,   
                y: feature.female,

                }))

          }
          
        ],
      };

    return (
        <>
             <CanvasJSChart options={options} onRef={(ref) => setChart(ref)} />
        </>
    )
}
